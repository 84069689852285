const heco = {
  // uniswap
  uniFactory: '0xbcfccbde45ce874adcb698cc183debcf17952812',
  uniRouter: '0x05ff2b0db69458a0750badebc4f9e13add608c7f',
  // tokens
  weth: '0x304c027b37d16bdd6bc447922bbae5d91792756b',
  eth: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  zero: '0x0000000000000000000000000000000000000000',
  // spaceSwap tokens
  SSI: '0xb56c1842e51ADf8015781452851FeCD755a4B25c',
  milk: '0xf857f61491c3c8013186d7a6aebeb63a5cc52020',
  interstellar: '0x5795e4339C95dF989998b7202BAF7FF58e1c06a0',
  milk2: '0xf857f61491c3c8013186d7a6aebeb63a5cc52020',
  blackHole: '0x41600f641bc7c71ba87fec1653782766aa213c8e',
  gravity: '0xa0d9c7e43542048Ed4B03C0A99255069Ec73CaC8',
  exchange: '0x58a9CeFfE63c5Ce63b2cf3AF2c11C07037cF8C96',
  galaxy: '0xa0d9c7e43542048Ed4B03C0A99255069Ec73CaC8',
  shake: '0xbbfc0e8126c85540ae4c0669287a6f88ad7ebb14',
  blender: '0x6bdd0abe9f065ae538645009de7aefde34e5738c',
  shadow: '0x7ae8d26d8e08ac8c8546b186cad0d68659fb4252',
  nft: '0x42c250631296f552b35a86575c8ea86bf7584101',
  nftprovider: '0x5f7b3424e78858bbf4c402d83c356c96e3962c90',
  babyMilk: '0x34a5eec56da76493cb44800f5903953fa3d004e5',
  // spaceSwap LP
  milk2Lp: '0x1bf2609946b0583eac8c9a3136a24f7ea06c91bc',
  shakelp: '0xa6846394f9765a9ab6ff1f516777d615e8556003',
  // contracts
  SSIBasicIssuanceModule: '0x6853545fe0c1372098C0BB0C0b0b15550a66fcB6',
  // index token pairs
  SSIPairs: [
    // eth - index
    ['0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee', '0xb56c1842e51adf8015781452851fecd755a4b25c'],
    // weth - index
    ['0xc778417E063141139Fce010982780140Aa0cD5Ab', '0xb56c1842e51adf8015781452851fecd755a4b25c'],
    // dai - index
    ['0x5592EC0cfb4dbc12D3aB100b257153436a1f0FEa', '0xb56c1842e51adf8015781452851fecd755a4b25c'],
    // usdt - index
    ['0xD9BA894E0097f8cC2BBc9D24D308b98e36dc6D02', '0xb56c1842e51adf8015781452851fecd755a4b25c'],
  ],
  // compound
  comptroller: '0xccf0657c4f0fee437464bfabec32e21a8b024809',
  priceOracle: '0x0504210c132da5d4cdfb115bf04bfa13642497eb',
  booster: {
    1: '0xa4ac76254409ae9316322d9a17c462a5de01116d',
    2: '0x598d7601f75aafd82aa1644522fa9225aa156ecb',
    3: '0xd2521edd6213581216bdb1c29d5f0a4d02213bb1',
    4: '0x8d3aea74f659d2c618aabc244af4424d377addce',
    5: '0xbbf94242ab1213d0be1a11a4ccbf81878c09158f',
    6: '0xa0c73bd9f49c2adf2604fe5d22a65485e9af3974',
  },
};

export default heco;
