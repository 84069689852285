<script>
import { mapState } from 'vuex';
import ClickOutside from 'vue-click-outside';
import Button from '@/components/Button';
import Logo from '@/components/Logo';
import Back from '@/components/Back';
import Papa from 'papaparse';

import MenuModal from '@/components/Modals/Menu';
import MyWallet from '@/components/MyWallet';
import PoolsSelect from '@/components/Modals/PoolsSelect';
import HeaderTokenStatistic from '@/components/Header/HeaderTokenStatistic';
import axios from 'axios';

import { getDateByBlock } from '../../utils/dateByBlock';

export default {
  name: 'Header',
  data: () => ({
    menuActive: false,
    poolsSelect: false,
    scrolled: false,
    timers: {},
    now: Date.now(),
  }),
  components: {
    HeaderTokenStatistic,
    Logo,
    Button,
    MyWallet,
    MenuModal,
    PoolsSelect,
    Back,
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.User.ethAddress,
      chainId: (state) => state.User.chainId,
      chainName: (state) => state.User.chainName,
      pools: (state) => state.Shadow.pools,
      prices: (state) => state.Price.list,
    }),
    isBinance() {
      return this.chainId === 56 || this.chainId === 97;
    },
    milkDiff() {
      return Math.sign(this.prices.MILK2_24h);
    },
    shakeDiff() {
      return Math.sign(this.prices.SHAKE_24h);
    },
    poolsRow() {
      if (this.pools) {
        return {
          ...this.pools.interstellar,
          ...this.pools.gravity,
          ...this.pools.galaxy,
        };
      }
      return {};
    },
    getPoolSlug() {
      const routes = [
        'blender',
        'pools-interstellar',
        'pools-gravity',
        'pools-galaxy',
        'index-token-index',
        'bridge',
        'booster',
        'pools-nft',
        'pools-nft-pid',
      ];
      const bridgeRoutes = ['bridgeClaim', 'bridgeSwap', 'bridgeExplorer'];

      if (routes.includes(this.$route.name)) {
        return this.$route.name;
      }
      if (bridgeRoutes.includes(this.$route.name)) {
        return 'bridge';
      }
      return 'pools-interstellar';
    },
    totalLocked() {
      const lockedAmountWei = Object.keys(this.poolsRow).reduce((s, i) => {
        if (this.poolsRow[i].poolLockedPrice && !this.poolsRow[i].poolLockedPrice.isNaN()) {
          return s.plus(this.poolsRow[i].poolLockedPrice);
        }
        return s;
      }, this.$BN(0));
      const lockedAmountWeth = this.$contracts.fromWei(lockedAmountWei);
      let mainAssetPrice;
      switch (this.chainId) {
        case 56:
          mainAssetPrice = this.prices.BNB;
          break;
        case 97:
          mainAssetPrice = this.prices.BNB;
          break;
        case 43114:
          mainAssetPrice = this.prices.AVAX;
          break;
        default:
          mainAssetPrice = this.prices.ETH;
      }
      return lockedAmountWeth.times(mainAssetPrice);
    },
    countdown() {
      const { now } = this;
      let airDropDate = new Date(this.timers.eth);
      if (this.isBinance) {
        airDropDate = new Date(this.timers.bsc);
      }
      const diff = airDropDate - now > 0 ? airDropDate - now : 0;
      const days = Math.floor(diff / 1000 / 3600 / 24);
      const hours = Math.floor((diff / 1000 / 3600) % 24);
      const minutes = Math.floor((diff / 1000 / 60) % 60);
      return {
        d: days,
        h: hours,
        m: minutes,
      };
    },
  },
  methods: {
    async getData() {
      const sheetid = '1ay7-W78xOtLI_L0wLMIYMWzBGzv3Yf8DMDdx0itlBMY';
      const url = `https://docs.google.com/spreadsheets/d/${sheetid}/export?format=csv`;
      const response = await axios.get(url);
      const { data } = Papa.parse(response.data);

      data.shift();
      return data;
    },
    closeMenu() {
      this.menuActive = false;
    },
    toggleMenu() {
      if (!this.menuActive) {
        this.poolsSelectClose();
      }
      this.menuActive = !this.menuActive;
    },
    poolsSelectToggle() {
      if (!this.poolsSelect) {
        this.closeMenu();
      }
      this.poolsSelect = !this.poolsSelect;
    },
    poolsSelectClose() {
      this.poolsSelect = false;
    },
  },
  watch: {
    $route() {
      this.closeMenu();
      this.poolsSelectClose();
    },
  },
  async mounted() {
    const timers = await this.getData();
    if (this.$web3) {
      this.timers = {
        eth: await getDateByBlock(Number(timers[0][0])),
        bsc: await getDateByBlock(Number(timers[0][1])),
      };
    }

    window.setInterval(() => {
      this.now = new Date();
    }, 1000);
    this.scrolled = window.pageYOffset > window.innerHeight;
    window.document.addEventListener('scroll', () => {
      this.scrolled = window.pageYOffset > window.innerHeight / 20;
    });
    this.popupItem = this.$el;
  },
  directives: {
    ClickOutside,
  },
};
</script>

<template>
  <v-row class="main-wrap" v-if="$route.name !== 'landing'">
    <div v-if="$chain.testnetWarning().isTest" :class="$chain.testnetWarning().message">
      {{ $t(`header.${$chain.testnetWarning().message}`) }}
    </div>
    <v-col class="header">
      <Back/>
      <Logo/>
      <div class="header__tvl-container" v-if="$route.name === 'home' || $route.name === 'defi'">
        <div class="header__menu-item header__menu-item--tvl">
          <span class="header__tvl">TVL</span>
          <span
          >{{
              isNaN(totalLocked) || !totalLocked
                ? '...'
                : `$${$options.filters.amount(totalLocked, 2)}`
            }}
          </span>
        </div>
        <div
          class="header__menu-item header__menu-item--airdrop"
          v-if="
          (countdown.d !== 0 || countdown.h !== 0 || countdown.m !== 0) &&
            $chain.getName() !== 'avalanche'
          "
        >
          <span class="header__airdrop">{{ $t('header.airdrop') }} </span>
          <span class="header__airdrop_timer">
            {{ countdown.d }}d {{ countdown.h }}h {{ countdown.m }}m
          </span>
        </div>
        <router-link to="/distribution" class="header__menu-item to-distribution">
          <i class="mdi mdi-help-circle-outline"></i>
        </router-link>
      </div>
      <div v-else class="header__menu-item header__menu-item--pools" @click="poolsSelectToggle">
        <span>{{ $t('header.pool-title.' + getPoolSlug) }}</span>
        &nbsp;
        <span :class="['header__arrow', { 'header__arrow--opened': poolsSelect }]">
          <img :src="require(`@/assets/img/${chainName}/arrow.svg`)" alt="arrow"/>
        </span>
      </div>
      <div class="header__tokens-statistic-container">
        <HeaderTokenStatistic
          v-if="$web3"
          class="header__menu-item"
          :name="$t('main.milk2')"
          :diff="milkDiff"
          :price="prices.MILK2 | amount(3)"
          :priceDiffPercent="prices.MILK2_24h | amount(2)"
        />
        <HeaderTokenStatistic
          v-if="$web3"
          class="header__menu-item"
          :name="$t('main.shake')"
          :diff="shakeDiff"
          :price="prices.SHAKE | amount(3)"
          :priceDiffPercent="prices.SHAKE_24h | amount(2)"
        />
      </div>
      <MyWallet v-if="authenticated"/>
      <Button @click="$root.$emit('connect')" class="btn--header header__menu-item" v-else>
        {{ $t('header.connect-wallet') }}
      </Button>
      <div @click="toggleMenu" class="header__menu-item header__menu-item--toggle">
        <img
          :src="require(`@/assets/img/${chainName}/menu.svg`)"
          :alt="`@/assets/img/${chainName}/menu.svg`"
        />
      </div>
      <MenuModal v-if="menuActive" v-click-outside="closeMenu"/>
      <PoolsSelect v-if="poolsSelect" v-click-outside="poolsSelectClose"/>
    </v-col>
  </v-row>
</template>

<style lang="scss">
.header-token-statistic--milk {
  margin-left: auto;
}

.rinkeby-notice {
  width: 100%;
  height: 5rem;
  background: rgba(255, 255, 255, 0.45);
  text-align: center;
  line-height: 5rem;
  color: $main-pink;
  font-size: 1.5rem;
}

.bsc-testnet-notice {
  width: 100%;
  height: 5rem;
  background: $b-main-item-bg;
  text-align: center;
  line-height: 5rem;
  color: $b-main-font-color;
  font-size: 1.5rem;
}

.header {
  display: flex;
  align-items: center;
  align-self: baseline;
  justify-content: space-between;
  padding: 1.4em;
  margin: 0;
  position: relative;
  flex-wrap: wrap;

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 1.2rem;
  }

  @media (max-width: $breakpoint-sm) {
    padding: 0 1rem;
  }

  &__tokens-statistic-container {
    display: flex;
    align-items: center;
    margin-left: auto;

    @media screen and (max-width: $breakpoint-md) and (min-width: $breakpoint-sm) {
      display: none;
    }

    @media screen and (max-width: $breakpoint-sm) {
      margin-left: 0;
      width: 100%;
      margin-bottom: 0;
    }
  }

  &__tvl-container {
    display: flex;
    justify-content: flex-start;

    @media (max-width: $breakpoint-sm) {
      order: 2;
      width: 100%;

      > div {
        width: 50%;
        display: flex;
        justify-content: center;

        &:first-child {
          margin-right: 1rem;
        }
      }
    }
  }

  &__menu-item {
    box-shadow: $main-item-shadow;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    height: 4.7em;
    padding: 0 1.8em;
    border-radius: 4.7em;
    margin-left: 2em;
    font-weight: 600;

    &:hover {
      cursor: pointer;
    }

    body.binance & {
      background: $b-menu-item-bg;
      box-shadow: $b-main-item-shadow;

      &:hover {
        background: $b-menu-item-bg;
        box-shadow: $b-main-item-shadow;
      }
    }

    /*&.btn--header {*/
    /*  margin-left: auto;*/
    /*}*/

    @media screen and (max-width: $breakpoint-lg) and (min-width: $breakpoint-md) {
      margin-left: 1.5rem;
    }

    @media screen and (max-width: $breakpoint-sm) {
      margin-left: 0;
      margin-bottom: 1.5em;
      height: 5.8em;
      display: flex;
      flex: 1;

      &.btn--header {
        width: 100%;
        justify-content: center;
      }

      &.header-token-stat:nth-child(2) {
        padding: 0 1rem;
      }
    }

    .header__tvl,
    .header__airdrop {
      margin-right: 0.5rem;
      font-weight: 700;
    }

    .header__airdrop,
    .header__airdrop_timer {
      text-transform: none;
    }

    span {
      font-size: 1.4em;
    }

    &--milk {
      margin-left: auto;

      @media screen and (max-width: $breakpoint-sm) {
        margin-left: 0;
      }
    }

    &--wallet {
      padding-right: 0;

      @media screen and (max-width: $breakpoint-md) and (min-width: $breakpoint-sm) {
        margin-left: auto;
      }

      @media screen and (max-width: $breakpoint-sm) {
        padding: 0;
      }
    }

    &--toggle {
      box-shadow: none;
      background: transparent;
      padding: 0 1rem;
      justify-content: flex-end;

      img {
        height: 10px;
      }

      body.ethereum &,
      body.binance &,
      body.avalanche & {
        img {
          height: 6rem;
          width: 9rem;
          margin-left: -2rem;
          margin-right: -2rem;
        }
      }

      body.binance &,
      body.huobi & {
        box-shadow: none;
        background: transparent;

        &:hover {
          box-shadow: none;
          background: transparent;
        }
      }

      @media screen and (max-width: $breakpoint-lg) {
        padding: 0;
      }

      @media screen and (max-width: $breakpoint-sm) {
        order: -1;
        height: auto;
      }

      img {
        height: 6rem;
        width: auto;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    &--pools {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      margin-right: auto;
      padding-left: 3em;
      margin-right: 0;

      @media screen and (max-width: $breakpoint-sm) {
        width: 100%;
        flex: auto;
      }

      img {
        height: 3rem;
        width: auto;
        margin-right: 2rem;

        @media screen and (max-width: $breakpoint-lg) {
          margin-right: 1rem;
        }
      }

      span {
        body.huobi & {
          color: $h-main-font-color;
        }

        img {
          height: 1rem;
          margin-left: 2rem;

          @media screen and (max-width: $breakpoint-lg) {
            margin-left: 1rem;
          }
        }
      }
    }

    .header__amount {
      font-weight: normal;
    }

    .header__arrow {
      transition: 300ms all ease-in-out;
      transform: rotate(180deg);

      &--opened {
        transform: scaleX(-1);
      }
    }
  }

  .launchpad-item {
    box-shadow: none;
  }
}

.to-distribution {
  padding-left: 1.57rem;
  padding-right: 1.57rem;
  max-width: 4.7rem;
  min-width: 4.7rem;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 2.5rem;
  }

  @media screen and (max-width: 1300px) {
    max-width: 5.7rem;
    min-width: 5.7rem;
  }
  @media screen and (max-width: 600px) {
    max-width: 6.9rem;
    min-width: 6.9rem;
    margin-left: 1rem;
  }
}
</style>
