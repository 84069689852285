export default {
  milk2: '0x721C299E6BF7D6a430d9bEA3364Ea197314bcE09',
  shake: '0xC1d02E488a9Ce2481BFdcd797d5373Dd2E70a9C2',
  shadow: '0xDA909dF184AB8d0BB16B986edA947Fc09ae19d44',
  weth: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
  eth: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  zero: '0x0000000000000000000000000000000000000000',

  milk2Lp: '0x180cac095abfd232b428094b746379654ec54235',
  shakelp: '0xcacc388e7c0d8a020c229782a96668b3c24a2716',
  sidusPresale: '0xD0E1fBe6112B4DCA5f95e3Ea8A76EC54DE97Df5B',
  shadowBridge: '0x80bbaeEE801b22a0BC5aF5B90b3848619bE3B347',
};
