import Vue from 'vue';
import dayjs from 'dayjs';

export const getTimestamp = async (blockNumber) => (
  await Vue.$web3.eth.getBlock(blockNumber)
).timestamp;

export const getDateByBlock = async (blockNumber) => {
  const blocksHistory = 10000;
  const latest = await Vue.$web3.eth.getBlockNumber();
  const prevLast = latest - blocksHistory;
  const latestTimestamp = await getTimestamp(latest);
  const prevLastTimestamp = await getTimestamp(prevLast);

  const historyTime = latestTimestamp - prevLastTimestamp;
  const timePerBlock = historyTime / blocksHistory;
  const diffLastBlock = blockNumber - latest;
  const blockTime = Math.floor(diffLastBlock * timePerBlock + latestTimestamp);

  return dayjs(blockTime * 1000).format();
};

export const getDateByTimestamp = (stamp) => dayjs.unix(stamp).format();
