import httpClient from './httpClient';

export const getList = () => httpClient.get('/products');
export const get = (address) => httpClient.get(`/products/${address}`)
  .then(() => true)
  .catch((err) => {
    if (err.response.status === 404) {
      return false;
    }
    return err;
  });
export const send = (data) => httpClient.post('/products', data);

export default {
  getList,
  get,
  send,
};
