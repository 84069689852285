export default {
  nft: [
    {
      id: 0,
      nft: 'nft',
      nft_provider: 'nftprovider',
      pid: 0,
      nftid: 0,
      name: 'Space Monkey',
      symbol: 'Space Monkey Card',
      icon: 'space-monkey',
    },
    {
      id: 1,
      nft: 'nft',
      nft_provider: 'nftprovider',
      pid: 1,
      nftid: 1,
      name: 'Royal Baby',
      symbol: 'Royal Baby Card',
      icon: 'royal-baby',
    },
    {
      id: 2,
      nft: 'nft',
      nft_provider: 'nftprovider',
      pid: 2,
      nftid: 2,
      name: 'Holy Cow',
      symbol: 'Holy Cow Card',
      icon: 'holy-cow',
    },
    {
      id: 3,
      nft: 'new_nft',
      nft_provider: 'new_nftprovider',
      pid: 3,
      nftid: 9,
      name: 'Galaxy Gears',
      symbol: 'Galaxy Gears Card',
      icon: 'galaxy-gears',
      active: false,
    },
    {
      id: 4,
      nft: 'new_nft',
      nft_provider: 'new_nftprovider',
      pid: 1,
      nftid: 7,
      name: 'Ice Cold',
      symbol: 'Ice Cold Card',
      icon: 'ice-cold',
      active: false,
    },
    {
      id: 5,
      nft: 'new_nft',
      nft_provider: 'new_nftprovider',
      pid: 2,
      nftid: 8,
      name: 'Morpheus Box',
      symbol: 'Morpheus Box Card',
      icon: 'morpheus-box',
      active: false,
    },
    {
      id: 6,
      nft: 'new_nft',
      nft_provider: 'new_nftprovider',
      pid: 0,
      nftid: 1,
      name: 'Celestial Partnership',
      symbol: 'Celestial Partnership Card',
      icon: 'celestial',
      active: false,
    },
  ],
};
