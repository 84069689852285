<template>
  <footer class="solana-footer">
    <ul class="solana-footer-nav">
      <li v-for="(link, i) of navs" :key="i">
        <router-link :to="link.path"> {{ $t(link.label) }} </router-link>
      </li>
    </ul>
  </footer>
</template>

<script>
export default {
  name: 'SFooter',

  data() {
    return {
      navs: [
        {
          label: 'solana.footer.help',
          path: '#',
        },
        {
          label: 'solana.footer.privacy_policy',
          path: '#',
        },
        {
          label: 'solana.footer.about',
          path: '#',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">

</style>
