<script>
import LangSwitch from '../LangSwitch';
import Address from '../Address';

export default {
  name: 'Menu',
  components: {
    LangSwitch,
    Address,
  },
};
</script>

<template>
  <div class="dropdown-menu">
    <div class="menu-columns">
      <router-link :to="{ name: 'pools-galaxy' }" class="menu-li">
        {{ $t('header.links.galaxy') }}
      </router-link>
<!--      <a href="https://snapshot.page/#/spaceswap" target="_blank" class="menu-li">-->
<!--        {{ $t('header.links.gov') }}-->
<!--      </a>-->
      <router-link :to="{ name: 'partnership' }" class="menu-li">
        {{ $t('header.links.partnership') }}
      </router-link>
<!--       <router-link :to="{ name: 'team' }" class="menu-li">
        {{ $t('header.links.team') }}
      </router-link> -->
      <router-link :to="{ name: 'leaderboard' }" class="menu-li">
        {{ $t('header.links.leaderboard') }}
      </router-link>
      <a :href="$t('header.links.about.href')" target="_blank" class="menu-li">
        {{ $t('header.links.about.text') }}
      </a>
      <a href="https://docs.spaceswap.app/" target="_blank" class="menu-li">
        {{ $t('header.links.docs') }}
      </a>
<!--      <router-link :to="{ name: 'bridge' }" class="menu-li">-->
<!--        {{ $t('header.links.bridge') }}-->
<!--      </router-link>-->
<!--      <router-link :to="{ name: 'landing' }" class="menu-li">-->
<!--        {{ $t('header.links.about_us') }}-->
<!--      </router-link>-->
    </div>
    <div class="dropdown-menu__address-lang">
      <Address class="dropdown-menu__address"/>
      <LangSwitch />
    </div>
  </div>
</template>

<style lang="scss">
.dropdown-menu {
  background: $modal-bg;
  padding: 4rem;
  border-radius: 2rem;
  position: absolute;
  top: 10rem;
  min-width: 30rem;
  right: 1rem;
  margin: 0!important;
  box-shadow: $modal-shadow;
  z-index: 100;
  display: block;

  body.binance & {
    background: $b-modal-bg;
    box-shadow: $b-modal-shadow;
  }

  body.huobi & {
    background: $h-modal-bg;
    box-shadow: $h-modal-shadow;
  }
  @media(max-width: $breakpoint-sm) {
    width: 100%;
    top: 7rem;
    left: 0;
  }
  &__address-lang {
    @media screen and (max-width: $breakpoint-sm) {
      display: flex;
    }
  }

  &__address {
    display: none;

    @media(max-width: $breakpoint-sm) {
      display: block;
    }
  }

  .menu-columns {
    column-count: 2;
    column-gap: 2rem;
    margin-bottom: 4rem;
    justify-items: end;
  }

  .menu-li {
    text-transform: uppercase;
    color:  $main-font-color;
    font-weight: bold;
    font-size: 1.7rem;
    cursor: pointer;
    text-decoration: none;
    display: block;
    text-align: right;

    body.binance & {
      color: $b-main-font-color;
    }

    body.huobi & {
      color: $h-main-font-color;
    }

    &:not(:last-child) {
      margin-bottom: 2.3rem;
    }
  }
}
</style>
