import cTokenABI from '@/abi/compound/cToken.json';
import comptrollerABI from '@/abi/compound/compcontroller.json';
import cPriceOracleABI from '@/abi/compound/dimmuOracle.json';

const Compound = {
  install(Vue, { store }) {
    const web3 = Vue.prototype.$web3;
    const BN = Vue.prototype.$BN;
    function getComptrollerContract() {
      const comptrollerAddress = Vue.prototype.$contracts.getAddress('comptroller');
      return new web3.eth.Contract(comptrollerABI, comptrollerAddress);
    }
    function getCPriceOracleContract() {
      const priceOracleAddress = Vue.prototype.$contracts.getAddress('priceOracle');
      return new web3.eth.Contract(cPriceOracleABI, priceOracleAddress);
    }
    /**
     * Create new contract
     * @param address {String} underlying token address
     * @returns {Contract}
     */
    const createContract = (address) => new web3.eth.Contract(cTokenABI, address);
    /**
     * Create new cToken contract by token symbol
     * @param cTokenAddress {String} compound token address
     * @param cTokenSymbol {String} token symbol
     * @returns {Contract}
     */
    const createCTokenContract = (cTokenAddress, cTokenSymbol) => new web3.eth
      // eslint-disable-next-line
      .Contract(require(`@/abi/compound/${cTokenSymbol}.json`), cTokenAddress);
    /**
     * Get token borrow rate
     * @param cTokenAddress {String} cToken address
     * @param cTokenSymbol {String} cToken symbol
     * @param type {String} Rate for 'supply' or 'borrow'
     * @returns {Promise}
     */
    const getTokenBorrowRate = (cTokenAddress, cTokenSymbol, type) => {
      const contract = createCTokenContract(cTokenAddress, cTokenSymbol);

      switch (type) {
        case 'borrow':
          return contract.methods.borrowRatePerBlock().call();
        case 'supply':
          return contract.methods.supplyRatePerBlock().call();
        default:
          return 0;
      }
    };
    /**
     * Get token borrow balance
     * @param cTokenAddress {String} cToken address
     * @param cTokenSymbol {String} cToken symbol
     * @returns {Promise}
     */
    // eslint-disable-next-line max-len
    const getBorrowBalance = (cTokenAddress, cTokenSymbol) => {
      const contract = createCTokenContract(cTokenAddress, cTokenSymbol);
      return contract.methods.borrowBalanceStored(store.state.User.ethAddress).call();
    };
    /**
     * Get user liquidity
     * @returns {Promise}
     */
    // eslint-disable-next-line max-len
    const getLiquidity = () => getComptrollerContract().methods.getAccountLiquidity(store.state.User.ethAddress).call();
    /**
     * @param cTokenAddress {String} mint cToken address
     * @param cTokenSymbol {String} token symbol
     * @returns {Promise}
     */
    const getCTokenTotalSupply = (cTokenAddress, cTokenSymbol) => {
      const contract = createCTokenContract(cTokenAddress, cTokenSymbol);
      return contract.methods.totalSupply().call();
    };
    /**
     * @param cTokenAddress {String} mint cToken address
     * @param cTokenSymbol {String} token symbol
     * @returns {Promise}
     */
    const getCTokenBalanceOf = (cTokenAddress, cTokenSymbol) => {
      const contract = createCTokenContract(cTokenAddress, cTokenSymbol);
      return contract.methods.balanceOf(store.state.User.ethAddress).call();
    };
    /**
     * Get token price from compound
     * @param address {String}
     * @returns {Promise}
     */
    // eslint-disable-next-line max-len
    const getTokenPrice = (address) => getCPriceOracleContract().methods.getUnderlyingPrice(address).call();
    /**
     * Get token lend apr
     * @param contract {Object} token contract
     * @returns {BigNumber}
     */
    const getCTokenLendAPR = async (contract) => {
      // get token lend rate
      const rate = await contract.methods.supplyRatePerBlock().call();
      // calculate token lend apr
      return Vue.prototype.$BN(rate)
        .multipliedBy(5760)
        .div(1e18)
        .plus(1)
        .exponentiatedBy(365)
        .minus(1)
        .multipliedBy(100)
        .toFixed(2);
    };
    /**
     * @param cTokenAddress cToken address
     * @returns {Promise<BigNumber>}
     */
    const getCTokenLtvValue = async (cTokenAddress) => {
      // eslint-disable-next-line max-len
      const { 1: collateralFactor } = await getComptrollerContract().methods.markets(cTokenAddress).call();
      return BN(collateralFactor).multipliedBy(100).div(BN(10).pow(18));
    };
    /**
     * Get current deposit balance for cToken
     * @param cTokenContract {Object} current cToken contract
     * @returns {String} current deposit balance
     */
    // eslint-disable-next-line max-len
    const getBalanceOfUnderlying = (cTokenContract) => cTokenContract.methods.balanceOfUnderlying(store.state.User.ethAddress).call();
    // eslint-disable-next-line max-len
    const getTokenBalanceOf = (cTokenContract) => cTokenContract.methods.balanceOf(store.state.User.ethAddress).call();
    // eslint-disable-next-line max-len
    const getTokenTotalReserves = (cTokenContract) => cTokenContract.methods.totalReserves().call();
    // eslint-disable-next-line max-len
    const getExchangeRate = (cTokenContract) => cTokenContract.methods.exchangeRateStored().call();
    // eslint-disable-next-line max-len
    const getCash = (cTokenContract) => cTokenContract.methods.getCash().call();
    /**
     * Get current account liquidity value for withdraw
     * @returns {String} current account available for withdraw value
     */
    // eslint-disable-next-line max-len
    const getAccountLiquidity = () => getComptrollerContract().methods.getAccountLiquidity(store.state.User.ethAddress).call();
    /**
     * Get cToken collateral factor.
     * @param cTokenAddress {String} The address of the cToken to check if listed and get
     * the collateral factor for.
     * @returns {Promise} Tuple of values (isListed, collateralFactorMantissa);
     * isListed represents whether the comptroller recognizes this cToken;
     * collateralFactorMantissa, scaled by 1e18, is multiplied by a supply balance to
     * determine how much value can be borrowed.
     */
    // eslint-disable-next-line max-len
    const getCTokenMarket = (cTokenAddress) => getComptrollerContract().methods.markets(cTokenAddress).call();
    /**
     * Get cToken amount and borrowed total for user
     * @param address
     * @param symbol
     * @return {*}
     */
    const getAccountSnapshot = (address, symbol) => {
      const cTokenContract = createCTokenContract(address, symbol);
      return cTokenContract.methods.getAccountSnapshot(store.state.User.ethAddress).call();
    };
    /**
     * get all in market assets
     * @returns {Promise}
     * Get the list of markets an account is currently entered into. In order to supply collateral
     * or borrow in a market, it must be entered first. Entered markets count towards account
     * liquidity calculations.
     */
    const getAssetsIn = () => (
      getComptrollerContract().methods.getAssetsIn(store.state.User.ethAddress).call()
    );

    const markets = (cToken) => (
      getComptrollerContract().methods.markets(cToken).call()
    );
    // install
    Vue.prototype.$compound = (() => ({
      createContract,
      getTokenBorrowRate,
      getBorrowBalance,
      getLiquidity,
      getTokenPrice,
      createCTokenContract,
      getCTokenLendAPR,
      getCTokenLtvValue,
      getBalanceOfUnderlying,
      getAccountLiquidity,
      getCTokenMarket,
      getAccountSnapshot,
      getCTokenTotalSupply,
      getAssetsIn,
      getComptrollerContract,
      getTokenBalanceOf,
      getTokenTotalReserves,
      getExchangeRate,
      markets,
      getCash,
      getCTokenBalanceOf,
    }))();
  },
};

export default Compound;
