import Vue from 'vue';
import { getPoolsHistory, getPoolsInfo } from '@/helpers/api/shadow.api';
import { getIdsByType, shadowPools } from '@/constants/shadowPools';
import { getPriceInWETH } from '@/utils/getPrice';
import getToken from '@/utils/getToken';
import store from '@/store';
/**
 * Pools state
 * @type {{pools: { 'poolType': { 'poolAddress': { ...poolParams } } }, loading: boolean}}
 */
const state = {
  loading: true,
  pools: {},
  totalWeight: 0,
  tokensPerYear: 0,
  private: {
    poolsMap: null,
    poolsHistoryMap: null,
  },
};

const actions = {
  async fetchPoolsParams(ctx) {
    const poolsMap = {};
    const poolsHistoryMap = {};
    // fill pools with init info if empty
    if (!Object.keys(ctx?.state?.pools || {})?.length) {
      ctx.commit('SET_POOLS', shadowPools[ctx.rootState.User.chainId]);
    }
    // fetch initial params
    const promises = [
      getPoolsInfo(),
      getPoolsHistory(),
      Vue.$contracts.getTokensPerYear('shadow'),
    ];
    const [poolsInfo, poolsHistory, tokensPerYear] = await Promise.all(promises);
    const { pools, totalWeight } = poolsInfo;
    pools.forEach((pool) => {
      poolsMap[pool.id] = pool;
    });
    poolsHistory.forEach((pool) => {
      poolsHistoryMap[pool.poolAddress] = pool;
    });
    ctx.commit('SET_TOKENS_PER_YEAR', tokensPerYear);
    ctx.commit('PRIVATE_SET_POOLS', poolsMap);
    ctx.commit('PRIVATE_SET_POOLS_HISTORY', poolsHistoryMap);
    ctx.commit('SET_TOTAL_WEIGHT', totalWeight);
  },
  async fetchAllPools(ctx) {
    ctx.commit('SET_LOADING_STATUS', true);
    await ctx.dispatch('fetchPoolsParams');

    Object.keys(ctx.state.private.poolsMap)
      .forEach((id) => ctx.dispatch('calculatePoolParams', { id }));
    ctx.commit('SET_LOADING_STATUS', false);
  },
  async calculatePoolParams(ctx, { id, type }) {
    let poolType = type;
    if (!poolType) {
      if (getIdsByType('interstellar').includes(id)) {
        poolType = 'interstellar';
      } else if (getIdsByType('gravity').includes(id)) {
        poolType = 'gravity';
      } else if (getIdsByType('galaxy').includes(id)) {
        poolType = 'galaxy';
      } else return;
    }
    const currentPool = shadowPools[ctx.rootState.User.chainId][poolType][id];
    const milkPriceInWETH = ctx.rootState.Milk.price;
    const { totalLpSupply } = ctx.state.private.poolsHistoryMap[currentPool.lpContract];
    const { totalWeight, tokensPerYear } = ctx.state;
    const token = await getToken(currentPool.lpContract, store.state.User.ethAddress);
    const price = await getPriceInWETH(
      currentPool.uniContract ? currentPool.uniContract : currentPool.lpContract,
      currentPool.tokenContract,
    );
    const tokenContract = Vue.$contracts.getInstance(currentPool.tokenContract, 'token');
    const totalTokensInLP = await tokenContract.methods.balanceOf(currentPool.lpContract).call();
    const poolLockedPrice = Vue.$BN(totalLpSupply)
      .div(token.totalSupply)
      .times(totalTokensInLP)
      .times(2)
      .times(price);
    ctx.commit(
      'SET_POOL',
      {
        poolType,
        poolParams: {
          milkPriceInWETH,
          totalLpSupply,
          totalWeight,
          tokensPerYear,
          poolLockedPrice,
          type: poolType,
          balance: token.balance,
          ...ctx.state.private.poolsMap[id],
          ...shadowPools[ctx.rootState.User.chainId][poolType][id],
        },
      },
    );
  },
};

const mutations = {
  /**
   * Store pool by poolName
   * @param state
   * @param {Object} poolParams - poolParams
   * @param {String} poolType - name of pool for set (shadow, galaxy, etc)
   * @constructor
   */
  SET_POOL(state, { poolType, poolParams }) {
    if (!state.pools[poolType]) Vue.set(state.pools, poolType, {});
    Vue.set(state.pools[poolType], poolParams.id, poolParams);
  },
  SET_POOLS(state, pools) {
    state.pools = pools;
  },
  RESET_POOLS(state) {
    state.pools = {};
  },
  /**
   * Set current pools history map object, where key - pool address, value - pool params
   * @param state
   * @param history
   * @constructor
   */
  PRIVATE_SET_POOLS_HISTORY(state, history) {
    state.private.poolsHistoryMap = Object.freeze(history);
  },
  PRIVATE_SET_POOLS(state, pools) {
    state.private.poolsMap = Object.freeze(pools);
  },
  SET_TOTAL_WEIGHT(state, val) {
    state.totalWeight = val;
  },
  SET_TOKENS_PER_YEAR(state, tokensPerYear) {
    state.tokensPerYear = tokensPerYear;
  },
  SET_LOADING_STATUS(state, status) {
    state.loading = status;
  },
};

export default {
  state,
  actions,
  mutations,
};
