const rinkeby = {
  // uniswap
  uniFactory: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
  uniRouter: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  // tokens
  weth: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
  eth: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  zero: '0x0000000000000000000000000000000000000000',
  // spaceSwap tokens
  SSI: '0xb56c1842e51ADf8015781452851FeCD755a4B25c',
  milk: '0x190f2F3040fA6A1854DcF6A92a45b0aA52a1d47e',
  interstellar: '0x5795e4339C95dF989998b7202BAF7FF58e1c06a0',
  milk2: '0x34a5eec56da76493cb44800f5903953fa3d004e5',
  blackHole: '0x41600f641bc7c71ba87fec1653782766aa213c8e',
  gravity: '0xa0d9c7e43542048Ed4B03C0A99255069Ec73CaC8',
  exchange: '0x58a9CeFfE63c5Ce63b2cf3AF2c11C07037cF8C96',
  galaxy: '0xa0d9c7e43542048Ed4B03C0A99255069Ec73CaC8',
  shake: '0xf7de53e08d8919463b964ad591f1fc6d4f472829',
  shadow: '0xefe889d080125a72c181800777b993d620daaccb',
  blender: '0x6bdd0abe9f065ae538645009de7aefde34e5738c',
  nft: '0x42c250631296f552b35a86575c8ea86bf7584101',
  nftprovider: '0x5f7b3424e78858bbf4c402d83c356c96e3962c90',
  new_nft: '0xbc0db41e58b0F364c47BBA6d761f531d32E0B77D',
  new_nftprovider: '0x58bbfdd07f2f6288320041d243704542ea6af2f2',

  babyMilk: '0x34a5eec56da76493cb44800f5903953fa3d004e5',
  // spaceSwap LP
  milkLp: '0x6e0f9F805C98De9F74753D8173629d64A6928896',
  milk2Lp: '0x6e0f9F805C98De9F74753D8173629d64A6928896',
  shakelp: '0x9733313db647642b578bd3a0302f72a0c58ad23c',
  // contracts
  SSIBasicIssuanceModule: '0x6853545fe0c1372098C0BB0C0b0b15550a66fcB6',
  // index token pairs
  SSIPairs: [
    // eth - index
    ['0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee', '0xb56c1842e51adf8015781452851fecd755a4b25c'],
    // weth - index
    ['0xc778417E063141139Fce010982780140Aa0cD5Ab', '0xb56c1842e51adf8015781452851fecd755a4b25c'],
    // dai - index
    ['0x5592EC0cfb4dbc12D3aB100b257153436a1f0FEa', '0xb56c1842e51adf8015781452851fecd755a4b25c'],
    // usdt - index
    ['0xD9BA894E0097f8cC2BBc9D24D308b98e36dc6D02', '0xb56c1842e51adf8015781452851fecd755a4b25c'],
  ],
  // compound
  comptroller: '0xccf0657c4f0fee437464bfabec32e21a8b024809',
  priceOracle: '0x0504210c132da5d4cdfb115bf04bfa13642497eb',
  booster: {
    1: '0x6903660321b8b6a47fac49e333fef0525392ffbb',
    2: '0xae1842140829b58b5e5244802772fe86838270a7',
  },
  // launchpad
  launchpadStaking: '0x321D670EbA75937BE08A2b0D5d0C11FbF829a62C',
  launchpadSale: '0xB5989aeA25912665858dfb0Fad9bEf36a94d38D8',
  launchpadVesting: '0x54d457CDfe0B77a1f891f2282592c5f9eE62901D',
  air: '0xf6595c0e7a559271f907b53894529d15e3f6c6fd',
  DAI: '0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735',
  LCH: '0x190a53B1D685f9A2CFA0007143B64447B64F8e9D',
  clickGame: '0xFB006da0248C7Cd810350213F07Cb7BBa402E8a0',
  sidusPresale: '0xCa2078492f83bcD37942b160B15159638364c067',
  shadowBridge: '0xd9e1d00433e82407A18698EAC9E591fdE92e63B0',
};

export default rinkeby;
