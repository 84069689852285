<template>
  <div class="main-wrap main-wrap--narrow">
    <Divider/>
    <div class="landing-title">ROADMAP</div>
    <div class="roadmap">
      <div class="roadmap__text left">
        <span
          v-for="(item, i) of roadmap.left"
          :key="i"
          :style="`margin-top: ${item.offset}rem`"
          :class="{ inactive: item.inactive }"
          v-html="item.text "
        ></span>
      </div>

      <img src="@/assets/img/roadmap-line-1.svg" class="roadmap__line">

      <div class="roadmap__text right">
        <span
          v-for="(item, i) of roadmap.right"
          :key="i"
          :style="`margin-top: ${item.offset}rem`"
          :class="{ inactive: item.inactive }"
          v-html="item.text "
        ></span>
      </div>
    </div>

<!--    <img src="@/assets/img/new-year/footer-cow.svg" class="footer-cow"/>-->
    <img src="@/assets/img/landing/footer-cow.svg" class="footer-cow"/>
  </div>
</template>

<script>
import Divider from './Divider';

export default {
  name: 'Roadmap',
  components: { Divider },

  data() {
    return {
      roadmap: {
        left: [
          {
            text: 'The launch of SpaceSwap 2.0<br/> - 09/25/2020',
            offset: '0',
          },
          {
            text: 'The implementation of SHAKE coin - 09/25/2020',
            offset: '15.5',
          },
          {
            text: 'The launch of Shadow Staking - a unique development that is going to revolutionize the whole DeFi farming sphere - 02/02/2021',
            offset: '15',
          },
          {
            text: 'NFT Mania quest - a unique event combining NFT / Gamification / Building a Community 03/05/2021',
            offset: '14',
          },
          {
            text: 'Spaceswap Starter - Decentralised Crowd-funding platform for projects on ETH and BSC - Summer/2021',
            offset: '13',
          },
          {
            text: 'NFT <br/> 1.1 Generative NFT collection - Q4 2021 to Q1 2022. 1.2 NFT collections (breeding concept) - Q1 2022. 1.3 NFT-based mini-games (game currency: MILK2 and SHAKE) - TBA',
            offset: '8',
            inactive: true,
          },
          {
            text: 'SpaceCrew (MultiChain DEX) - a decentralized multi-chain exchange - TBA',
            offset: '10',
            inactive: true,
          },
        ],
        right: [
          {
            text: 'The launch of SpaceSwap <br/> Interstellar - 09/25/2020',
            offset: '10',
          },
          {
            text: 'The launch of Index Token Platform - a place for issuing the top-hit token indexes - 11/15/2020',
            offset: '15',
          },
          {
            text: 'SpaceSwap Binance Smart Chain version - March/2021',
            offset: '15',
          },
          {
            text: 'New tokenomics model chosen by the community - Spring/2021',
            offset: '15',
          },
          {
            text: 'Launch of  Avalanche version December, 2021 - Q1 2022',
            offset: '15',
          },
          {
            text: 'Shadow 2.0 based Farming Pools marketplace  - TBA',
            offset: '15',
            inactive: true,
          },
          {
            text: 'Key partnerships - new pools, new exchanges, IDOs,  white labels solutions and partnerships - from 2021 ongoing. ',
            offset: '15',
            inactive: true,
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>

.roadmap-mobile {
  display: none !important;
}

.roadmap {
  display: flex;
  justify-content: center;
  &__line-2{
    height: 58rem !important;
  }
}

.roadmap-coming-soon{
  font-weight: 800;
  font-size: 3.6rem;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: #FF8B8B;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.11));
  text-shadow: 0px 1px 0 white, 0px -1px 0 white,
    1px 0px 0 white, -1px 0px 0 white, 1px 1px 0 white,
    1px -1px 0 white, -1px 1px 0 white, -1px -1px 0 white,
    0px 2px 0 white, 0px -2px 0 white, 2px 0px 0 white,
    -2px 0px 0 white, 2px 2px 0 white, 2px -2px 0 white,
}

.roadmap__text {
  display: flex;
  flex-direction: column;
  width: calc(50% - 1.4rem);

  &.left {
    text-align: right;
  }

  &.right {
    text-align: left;
  }

  span {
    font-weight: bold;
    cursor: pointer;
    font-size: 2.2rem;
    text-shadow: 0px 1px 0 rgb(255, 255, 255), 0px -1px 0 rgb(255, 255, 255),
    1px 0px 0 rgb(255, 255, 255), -1px 0px 0 rgb(255, 255, 255),
    1px 1px 0 rgb(255, 255, 255), 1px -1px 0 rgb(255, 255, 255),
    -1px 1px 0 rgb(255, 255, 255), -1px -1px 0 rgb(255, 255, 255),
    0px 2px 0 rgb(255, 255, 255), 0px -2px 0 rgb(255, 255, 255),
    2px 0px 0 rgb(255, 255, 255), -2px 0px 0 rgb(255, 255, 255),
    2px 2px 0 rgb(255, 255, 255), 2px -2px 0 rgb(255, 255, 255),
    -2px 2px 0 rgb(255, 255, 255), -2px -2px 0 rgb(255, 255, 255);
    filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.11));
    transition: 0.5s;

    &:hover {
      filter: drop-shadow(0 0px 0px rgba(0, 0, 0, 0));
      text-shadow: none;
    }

    &.inactive {
      opacity: 0.5;
    }
  }
}

.roadmap img {
  height: 150rem;
}

.landing-title {
  margin: 4rem 0;
}
.footer-cow {
  position: absolute;
  left: 0;
  height: 60rem;
  margin-top: -30rem;
}
@media screen and (max-width: $breakpoint-sm) {
  .roadmap-mobile {
    //display: flex !important;
  }

  .roadmap {
    position: relative;
    &__text {
      width: auto !important;
      span {
        font-size: 2rem;
        text-align: left;
        margin-top: 0rem;
      }
    }

    img {
      height: 120rem;
    }
    &__line{
      order: 1;
    }
    .left{
      order: 2;
      margin-left: 1rem;
      span{
        &:nth-child(2){
          margin-top: 10rem !important;
        }
        &:nth-child(3){
          margin-top: 9rem !important;
        }
        &:nth-child(4){
          margin-top: 6rem !important;
        }
        &:nth-child(5){
          margin-top: 6rem !important;
        }
        &:nth-child(6){
          margin-top: 6rem !important;
        }
        &:nth-child(7){
          margin-top: 6rem !important;
        }
       }
    }
    .right{
      order: 3;
      position: absolute;
      height: 100%;
      left: 4rem;
      span{
        &:nth-child(1){
          margin-top: 7rem !important;
        }
        &:nth-child(2){
          margin-top: 9rem !important;
        }
        &:nth-child(3){
          margin-top: 12rem !important;
        }
        &:nth-child(4){
          margin-top: 9rem !important;
        }
        &:nth-child(5){
          margin-top: 9rem !important;
        }
        &:nth-child(6){
          margin-top: 18rem !important;
        }
        &:nth-child(7){
          margin-top: 7rem !important;
        }
      }
    }

    &__soon{
      .right{
        span{
          &:nth-child(1){
            margin-top: 0 !important;
          }
          &:nth-child(2){
            margin-top: 5rem !important;
          }
        }
      }
      .left{
        span{
          &:nth-child(1){
            margin-top: -4rem !important;
          }
          &:nth-child(2){
            margin-top: -11rem !important;
          }
        }
      }
    }
  }

  .footer-cow {
    height: 28rem;
    display: none;
  }
}
</style>
