import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';

const FORMAT = {
  prefix: '',
  decimalSeparator: '.',
  groupSeparator: ',',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: ' ',
  fractionGroupSize: 0,
  suffix: '',
  ROUNDING_MODE: BigNumber.ROUND_HALF_DOWN,
};

BigNumber.config({ FORMAT });

export const amountFilter = (amount, digits = 3, pretty = false, decimals = null) => {
  if (BigNumber.isBigNumber(amount)) {
    if (
      pretty
      && amount.comparedTo(new BigNumber(0.001)) < 0
      && amount.comparedTo(new BigNumber(0)) > 0
    ) {
      return '< 0.001';
    }
    return amount.decimalPlaces(digits, BigNumber.ROUND_HALF_DOWN).toFormat(decimals);
  }
  return amount;
};

export const shrinkString = (string, maxLength = 20) => {
  if (string.length > 8) {
    const start = Math.floor(maxLength / 2);
    const end = -1 * Math.floor(maxLength / 2);
    return `${string.slice(0, start)} ... ${string.slice(end)}`;
  }
  return string;
};

export const datetimeFilter = (d) => dayjs(d).format('YYYY-MM-DD HH:mm:ss');

export const splitActivePools = (pools) => {
  const result = {
    active: [],
    notActive: [],
  };

  pools.forEach((product) => {
    const isActive = !(product.weight && !product.weight.comparedTo(0));
    result[isActive ? 'active' : 'notActive'].push(product);
  });

  return result;
};
/*
import numeral from 'numeral';

const amountFilter = (value, format = '0,0.000[000000]') => {
  return value;
  if (!value) {
    return value;
  }
  const val = value / 10 ** 18;
  const res = numeral(val).format(format);
  if (res === 'NaN') {
    return val;
  }
  return res;
};
*/
