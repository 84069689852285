<template>
  <router-link :to="$route.meta.backTo" class="back" v-if="$route.meta.backTo">
    <img :src="require(`@/assets/img/back.svg`)" v-if="!isBinance"/>
    <img :src="require(`@/assets/img/back-black.svg`)" v-else/>
    <img
      alt="bells"
      class="new-year"
      :src="require(`@/assets/img/new-year/bells.svg`)"
    >
    <span>Back</span>
  </router-link>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Back',
  computed: {
    ...mapState({
      isBinance() {
        return this.chainId === 56 || this.chainId === 97;
      },
      chainId: (state) => state.User.chainId,
    }),
  },
};
</script>

<style lang="scss">

.back {
  width: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  background: linear-gradient(270deg, #FF7979 0%, #FF9F9F 101.76%);
  box-shadow:
    10px 10px 20px rgba(222, 222, 222, 0.25),
    2px 2px 20px rgba(212, 202, 205, 0.2),
    -10px -10px 15px #FFFFFF,
    10px 10px 20px rgba(181, 155, 155, 0.25),
    inset 2px 2px 6px #FFFFFF,
    inset -1px -1px 2px rgba(181, 155, 155, 0.2);

  body.binance &{
    background: linear-gradient(180deg, #F4D353 0%, #FFC117 100%);
    /* dark/btn_big_shadow_view */
    box-shadow: 2px 2px 20px #090808,
    2px 2px 20px rgba(10, 9, 8, 0.2),
    -4px -4px 10px #5C5A52,
    6px 6px 14px rgba(44, 41, 30, 0.64),
    inset 2px 2px 6px #F4EAB4,
    inset -1px -1px 3px rgba(255, 242, 128, 0.73);
    span {
      color: #0F1929;
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    order: -3;
    margin-bottom: 1.5rem;
  }
  border-radius: 46px;
  cursor: pointer;
  text-decoration: none;
  margin-right: 1rem;

  img {
    height: 1.7rem;
    margin-left: 1rem;
  }

  span {
    font-style: normal;
    font-weight: bold;
    font-size: 1.3rem;
    color: #F8FCFD;
    text-transform: uppercase;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
</style>
