export default {
  nft: [
    {
      id: 1,
      pid: 0,
      nft: 'nft',
      nft_provider: 'nftprovider',
      nftid: 1,
      name: 'SPACE MONKEY',
      symbol: 'Space Monkey Card',
      icon: 'space-monkey',
    },
    {
      id: 2,
      pid: 1,
      nft: 'nft',
      nft_provider: 'nftprovider',
      nftid: 2,
      name: 'HOLY COW',
      symbol: 'Holy Cow Card',
      icon: 'holy-cow',
    },
    {
      id: 3,
      pid: 2,
      nft: 'nft',
      nft_provider: 'nftprovider',
      nftid: 3,
      name: 'ROYAL BABY',
      symbol: 'Royal Baby Card',
      icon: 'royal-baby',
    },
    {
      id: 4,
      pid: 4,
      nft: 'nft',
      nft_provider: 'nftprovider',
      nftid: 4,
      name: 'BOO',
      symbol: 'Boo Card',
      icon: 'boo',
    },
    {
      id: 5,
      pid: 5,
      nft: 'nft',
      nft_provider: 'nftprovider',
      nftid: 5,
      name: 'OBSESSED',
      symbol: 'Obsessed Card',
      icon: 'obsessed',
    },
    {
      id: 6,
      pid: 6,
      nft: 'nft',
      nft_provider: 'nftprovider',
      nftid: 6,
      name: 'FULL OF',
      symbol: 'Full Of Card',
      icon: 'full-of',
    },
    {
      id: 7,
      pid: 8,
      nft: 'nft',
      nft_provider: 'nftprovider',
      nftid: 7,
      name: 'BITSLAYER',
      symbol: 'Bitslayer Card',
      icon: 'bitslayer',
    },
    {
      id: 8,
      pid: 9,
      nft: 'nft',
      nft_provider: 'nftprovider',
      nftid: 8,
      name: 'IMMORTAL SPIRIT',
      symbol: 'Immortal Spirit Card',
      icon: 'immortal-spirit',
    },
    {
      id: 9,
      pid: 10,
      nft: 'nft',
      nft_provider: 'nftprovider',
      nftid: 9,
      name: 'LONG-TAILED',
      symbol: 'Long Tailed Card',
      icon: 'long-tailed',
    },
    {
      id: 10,
      pid: 11,
      nft: 'nft',
      nft_provider: 'nftprovider',
      nftid: 10,
      name: 'MAGIC MOMENT',
      symbol: 'Magic Moment Card',
      icon: 'magic-moment',
    },
    {
      id: 11,
      pid: 12,
      nft: 'nft',
      nft_provider: 'nftprovider',
      nftid: 11,
      name: 'ON THE WAY',
      symbol: 'On The Way Card',
      icon: 'on-the-way',
    },
    {
      id: 12,
      pid: 13,
      nft: 'nft',
      nft_provider: 'nftprovider',
      nftid: 12,
      name: 'SPACE-CREAM',
      symbol: 'Space-Cream Card',
      icon: 'space-cream',
    },
    {
      id: 13,
      pid: 14,
      nft: 'nft',
      nft_provider: 'nftprovider',
      nftid: 13,
      name: 'THE EMPYREAN',
      symbol: 'The Empyrean Card',
      icon: 'the-empyrean',
    },
    {
      id: 14,
      pid: 17,
      nft: 'nft',
      nft_provider: 'nftprovider',
      nftid: 19,
      name: 'SPACE GOODS',
      symbol: 'Space goods Card',
      icon: 'space-goods',
    },
    {
      id: 15,
      pid: 16,
      nft: 'nft',
      nft_provider: 'nftprovider',
      nftid: 18,
      name: 'BLOCKCHAIN ICE',
      symbol: 'Blockchain ice Card',
      icon: 'block-ice',
    },
    {
      id: 16,
      pid: 15,
      nft: 'nft',
      nft_provider: 'nftprovider',
      nftid: 17,
      name: 'CRAFTY THINGS',
      symbol: 'Crafty things Card',
      icon: 'crafty-things',
    },

    {
      id: 17,
      nft: 'new_nft',
      nft_provider: 'nftprovider',
      pid: 3,
      nftid: 959197,
      name: 'Galaxy gear chest',
      symbol: 'Galaxy Gears Card',
      icon: 'galaxy-gears',
      active: true,
    },
    {
      id: 18,
      nft: 'new_nft',
      nft_provider: 'nftprovider',
      pid: 2,
      nftid: 959199,
      name: 'Ice-cold cowberry',
      symbol: 'Ice Cold Card',
      icon: 'ice-cold',
      active: true,
    },
    {
      id: 19,
      nft: 'new_nft',
      nft_provider: 'nftprovider',
      pid: 1,
      nftid: 959206,
      name: 'Morpheus Box',
      symbol: 'Morpheus Box Card',
      icon: 'morpheus-box',
      active: true,
    },
    {
      id: 20,
      nft: 'new_nft',
      nft_provider: 'nftprovider',
      pid: 0,
      nftid: 23,
      isMain: true,
      name: 'Celestial Partnership',
      symbol: 'Celestial Partnership Card',
      icon: 'celestial',
      active: true,
    },
  ],
};
