<script>
export default {
  name: 'SimpleModal',
  data() {
    return {
      isMounted: false,
    };
  },
  methods: {
    destroyModal() {
      this.isMounted = false;

      setTimeout(() => this.$emit('destroy'), 300);
    },
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<template>
  <div :class="{'simple-modal': true, 'simple-modal_active': isMounted}" @click.self="destroyModal">
    <div class="modal modal--light">
      <slot />
    </div>
  </div>
</template>

<style lang="scss">
.simple-modal {
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  background: rgba(0,0,0,0.2) !important;

  opacity: 0;
  visibility: hidden;
  transition: .3s ease;

  > .modal {
    margin: auto;
  }

  &_active {
    opacity: 1;
    visibility: visible;
  }
}
</style>
