import { render, staticRenderFns } from "./MilkContainer.vue?vue&type=template&id=23a43148&scoped=true"
import script from "./MilkContainer.vue?vue&type=script&lang=js"
export * from "./MilkContainer.vue?vue&type=script&lang=js"
import style0 from "./MilkContainer.vue?vue&type=style&index=0&id=23a43148&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23a43148",
  null
  
)

export default component.exports