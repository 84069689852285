<template>
  <div class="active-chains">
    <div class="landing-title">
      ACTIVE CHAINS
    </div>
    <div class="active-chains__container">
      <a class="active-chains__block"
         href="https://coinmarketcap.com/currencies/ethereum/"
         target="_blank">
        <div class="active-chains__coin">
          <div class="active-chains__coin--eth"></div>
        </div>
        <span>ETH</span>
      </a>
      <a
        href="https://coinmarketcap.com/currencies/binance-coin/"
        class="active-chains__block">
        <div class="active-chains__coin">
          <div class="active-chains__coin--bsc"></div>
        </div>
        <span>BSC</span>

      </a>
      <div class="active-chains__block">
        <div class="active-chains__coin">
          <div class="active-chains__coin--question"></div>
        </div>
        <span>Avalanche</span>

      </div>
    </div>
    <div class="landing-h2 landing-title">
      ... and more to come
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActiveChains',
};
</script>

<style scoped lang="scss">
.active-chains {
  display: flex;
  flex-direction: column;
  align-items: center;

  .landing-title {
    margin-top: 9rem;
    margin-bottom: 8rem;
  }

  .landing-h2 {
    font-size: 3.8rem;
    font-weight: bold;
    margin-bottom: 7rem;
    margin-top: 3.2rem;
    text-shadow: 0px 1px 0 rgb(255,255,255),
    0px -1px 0 rgb(255,255,255),
    1px 0px 0 rgb(255,255,255),
    -1px 0px 0 rgb(255,255,255),
    1px 1px 0 rgb(255,255,255),
    1px -1px 0 rgb(255,255,255),
    -1px 1px 0 rgb(255,255,255),
    -1px -1px 0 rgb(255,255,255),
    0px 2px 0 rgb(255,255,255),
    0px -2px 0 rgb(255,255,255),
    2px 0px 0 rgb(255,255,255),
    -2px 0px 0 rgb(255,255,255),
    2px 2px 0 rgb(255,255,255),
    2px -2px 0 rgb(255,255,255),
    -2px 2px 0 rgb(255,255,255),
    -2px -2px 0 rgb(255,255,255);
  }

  &--more {
    background: url("../../assets/img/landing/and_more.png") no-repeat;
    height: 4rem;
    margin-top: 4rem;
    width: 100%;
    background-position: center center;
    background-size: contain;
    margin-bottom: 7.5rem;
  }

  &__container {
    display: flex;
    justify-content: space-between;
  }

  &__block {
    margin: 0 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;

    span {
      margin-top: 1rem;
      font-size: 2rem;
      text-shadow:0px 1px 0 rgb(255,255,255),0px -1px 0 rgb(255,255,255),
      1px 0px 0 rgb(255,255,255),-1px 0px 0 rgb(255,255,255),
      1px 1px 0 rgb(255,255,255),1px -1px 0 rgb(255,255,255),
      -1px 1px 0 rgb(255,255,255),-1px -1px 0 rgb(255,255,255);
      filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.11));
      transition: 0.5s;
      color: #313EB0;
      font-weight: bold;
      text-decoration: none !important;
    }
  }

  &__coin {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12rem;
    height: 12rem;
    border-radius: 50%;
    background: #FFFFFF;
    box-shadow: 10px 10px 20px rgba(222, 222, 222, 0.25),
    2px 2px 20px rgba(212, 202, 205, 0.2),
    -10px -10px 15px #FFFFFF,
    10px 10px 20px rgba(181, 155, 155, 0.25),
    inset 2px 2px 6px #FFFFFF,
    inset -1px -1px 2px rgba(181, 155, 155, 0.2);

    div {
      width: 10.5rem;
      height: 10.5rem;
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--eth {
      background: url("../../assets/img/landing/eth.png") no-repeat;
      background-size: contain;
    }

    &--bsc {
      background: url("../../assets/img/landing/bsc.png") no-repeat;
      background-size: contain;
    }

    &--question {
      background: url("../../assets/img/landing/avax.svg") no-repeat;
      background-size: contain;
      width: 9.5rem !important;
      margin-top: 0 !important;
      height: 9.5rem !important;
    }
  }
}
</style>
