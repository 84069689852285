<script>
import { locales } from '@/helpers/locales';
import i18n from '@/i18n';
import VueCookie from 'vue-cookie';

export default {
  name: 'LangSwitch',
  data: () => ({
    locales,
    i18n,
  }),
  methods: {
    changeLocale(locale) {
      VueCookie.set('locale', locale);
      this.i18n.locale = locale;
    },
  },
};
</script>

<template>
  <div class="menu-lang">
    <div
      :key="locale"
      :class="[
        locale == i18n.locale ? 'active' : 'inactive',
        'lang-select']"
      v-for="locale in locales"
      >
      <div
        :class="[ locale == i18n.locale ? 'active' : 'inactive', 'lang-item']"
        @click="changeLocale(locale)">{{ locale }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .menu-lang {
    display: flex;
    flex-direction: row;
    background: $lang-switch-bg;
    height: 4.4rem;
    width: fit-content;
    border-radius: 4.4rem;
    box-shadow: $lang-switch-shadow;
    margin-left: auto;

    body.binance & {
      background: $b-lang-switch-bg;
      box-shadow: $b-lang-switch-shadow;
    }

    body.huobi & {
      background: $h-lang-switch-bg;
      box-shadow: $h-lang-switch-shadow;
    }

    .lang-item {
      color: #FF8380;
      cursor: pointer;
      font-size: 1.1rem;
      width: 4.4rem;
      height: 4.4rem;
      text-align: center;
      font-weight: bold;
      line-height: 4.4rem;
      text-transform: uppercase;

      &.active {
        background: $lang-switch-active-bg;
        box-shadow: $lang-switch-active-shadow;
        border-radius: 100%;
        color: #FFF;
      }

      body.binance & {
        color: $b-main-font-color;

        &.active {
          color: $b-dark-font-color;
          background: $b-lang-switch-active-bg;
          box-shadow: $b-lang-switch-active-shadow;
        }
      }

      body.huobi & {
        color: $h-main-font-color;

        &.active {
          color: #FFF;
          background: $h-lang-switch-active-bg;
          box-shadow: $h-lang-switch-active-shadow;
        }
      }
    }
  }
</style>
