import VueCookie from 'vue-cookie';
import i18n from '../i18n';
import { locales } from '../helpers/locales';

const DeFi = () => import('@/views/defi');
const NotFound = () => import('@/views/not-found');
const InterstellarIndex = () => import('@/views/products/interstellar/index');
const GalaxyIndex = () => import('@/views/products/galaxy/index');
const Products = () => import('@/views/products/index');
const Nft = () => import('@/views/products/nft');
const NtfIndex = () => import('@/views/products/nft/index');
const Disclaimer = () => import('@/views/disclaimer');
const Blender = () => import('@/views/blender');
const IndexToken = () => import('@/views/index-token');
const IndexTokenIndex = () => import('@/views/index-token/index');
const Partnership = () => import('@/views/partnership');
const Spacelend = () => import('@/views/spacelend');
const ProductPid = () => import('@/views/products/_pid');
const Bridge = () => import('@/views/bridge');
// const Team = () => import('@/views/team');
const Booster = () => import('@/views/booster');
const Landing = () => import('@/views/landing');
const Airdrop = () => import('@/views/airdrop/index');
const Solana = () => import('@/views/solana/index');
const Pool = () => import('@/views/solana/pool');
const Reward = () => import('@/views/solana/reward');
const SolanaStake = () => import('@/views/solana/stake');
const Cardano = () => import('@/views/cardano/index');
const CardanoPool = () => import('@/views/cardano/pool');
const Distribution = () => import('@/views/distribution');
const Game = () => import('@/views/game');
const NftSale = () => import('@/views/nft-sale');
const BridgeClaim = () => import('@/views/new-bridge/claim/');
const BridgeSwap = () => import('@/views/new-bridge/swap');
const BridgeExplorer = () => import('@/views/new-bridge/explorer');
const Leaderboard = () => import('@/views/leaderboard');

export default [
  {
    path: '/release',
    redirect: '/',
  },
  {
    path: '/defi',
    meta: {
      withoutAuth: true,
      backTo: '/',
    },
    name: 'defi',
    component: DeFi,
  },
  {
    path: '/',
    name: 'home',
    component: DeFi,
    meta: {
      withoutAuth: true,
    },
  },
  {
    path: '/nft-sale',
    name: 'nft-sale',
    component: NftSale,
  },
  {
    path: '/spacelend',
    name: 'spacelend',
    component: Spacelend,
    meta: {
      backTo: '/',
    },
  },
  {
    path: '/bridge',
    name: 'bridge',
    component: Bridge,
    meta: {
      backTo: '/',
    },
  },
  {
    path: '/new-bridge/claim',
    name: 'bridgeClaim',
    component: BridgeClaim,
    meta: {
      backTo: '/new-bridge',
    },
  },
  {
    path: '/new-bridge',
    name: 'bridgeSwap',
    component: BridgeSwap,
    meta: {
      backTo: '/',
    },
  },
  {
    path: '/new-bridge/explorer',
    name: 'bridgeExplorer',
    component: BridgeExplorer,
    meta: {
      backTo: '/new-bridge',
    },
  },
  // {
  //   path: '/team',
  //   name: 'team',
  //   component: Team,
  //   meta: {
  //     withoutAuth: true,
  //     backTo: '/',
  //   },
  // },
  {
    path: '/index-token',
    component: IndexToken,
    children: [
      {
        path: '',
        name: 'index-token-index',
        component: IndexTokenIndex,
        meta: {
          backTo: '/',
        },
      },
    ],
    meta: {
      backTo: '/',
    },
  },
  {
    path: '/pools',
    redirect: { name: 'pools-interstellar' },
    component: Products,
    meta: {
      backTo: '/',
    },
    children: [
      {
        path: 'interstellar',
        name: 'pools-interstellar',
        component: InterstellarIndex,
        meta: {
          backTo: '/',
        },
      },
      {
        path: 'galaxy',
        name: 'pools-galaxy',
        component: GalaxyIndex,
        meta: {
          backTo: '/',
        },
      },
      {
        path: 'nft',
        component: Nft,
        children: [
          {
            path: '',
            name: 'pools-nft',
            component: NtfIndex,
            meta: {
              backTo: '/',
            },
          },
          {
            path: ':id',
            name: 'pools-nft-pid',
            component: ProductPid,
            meta: {
              backTo: '/',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/disclaimer',
    name: 'disclaimer',
    component: Disclaimer,
    meta: {
      backTo: '/',
      withoutAuth: true,
    },
  },
  {
    path: '/blender',
    name: 'blender',
    component: Blender,
    meta: {
      backTo: '/',
    },
  },
  {
    path: '/booster',
    name: 'booster',
    component: Booster,
    meta: {
      backTo: '/',
    },
  },
  {
    path: '/launchpad',
    name: 'launchpad',
    beforeEnter() {
      window.location.href = 'https://launchpad.spaceswap.app/';
    },
  },
  {
    path: '/launchpad/agreement',
    name: 'agreement',
    beforeEnter() {
      window.location.href = 'https://launchpad.spaceswap.app/launchpad/agreement';
    },
  },
  {
    path: '/airdrop/:type?',
    name: 'airdrop',
    component: Airdrop,
    meta: {
      backTo: '/',
    },
  },
  {
    path: '/partnership',
    name: 'partnership',
    component: Partnership,
    meta: {
      backTo: '/',
    },
  },
  {
    path: '/staking/:pid',
    name: 'staking',
    beforeEnter(to) {
      window.location.href = `https://launchpad.spaceswap.app${to.fullPath}`;
    },
  },
  {
    path: '/staking',
    name: 'stakeselect',
    beforeEnter() {
      window.location.href = 'https://launchpad.spaceswap.app/staking';
    },
  },
  {
    path: '/solana',
    name: 'solana',
    component: Solana,
    meta: {
      withoutAuth: true,
      layout: 'SolanaLayout',
    },
  },
  {
    path: '/solana/pool',
    name: 'solana_pool',
    component: Pool,
    meta: {
      withoutAuth: true,
      layout: 'SolanaLayout',
    },
  },
  {
    path: '/solana/pool/reward',
    name: 'solana_reward',
    component: Reward,
    meta: {
      withoutAuth: true,
      layout: 'SolanaLayout',
    },
  },
  {
    path: '/solana/stake',
    name: 'solana_stake',
    component: SolanaStake,
    meta: {
      withoutAuth: true,
      layout: 'SolanaLayout',
    },
  },
  {
    path: '/cardano',
    name: 'cardano',
    component: Cardano,
    meta: {
      withoutAuth: true,
      layout: 'CortanaLayout',
    },
  },
  {
    path: '/cardano/pool',
    name: 'cardano_pool',
    component: CardanoPool,
    meta: {
      withoutAuth: true,
      layout: 'CortanaLayout',
    },
  },
  {
    path: '/distribution',
    name: 'distribution',
    component: Distribution,
    meta: {
      withoutAuth: true,
      backTo: '/',
    },
  },
  {
    path: '/leaderboard',
    name: 'leaderboard',
    component: Leaderboard,
    meta: {
      withoutAuth: true,
    },
  },
  {
    path: '/game',
    name: 'game',
    component: Game,
    meta: {
      withoutAuth: true,
    },
  },
  {
    path: '/landing',
    name: 'landing',
    component: Landing,
    meta: {
      withoutAuth: true,
    },
  },
  {
    path: '/tokensale/1',
    redirect: '/tokensale/sidus',
  },
  {
    path: '/tokensale/2',
    redirect: '/tokensale/senate',
  },
  {
    path: '/tokensale/cybertrade',
    redirect: '/tokensale/cryptocitizen',
  },
  {
    path: '/tokensale/:pid',
    alias: '/upcoming/:pid',
    name: 'tokensale',
    beforeEnter(to) {
      window.location.href = `https://launchpad.spaceswap.app${to.fullPath}`;
    },
  },
  {
    path: '/claim/:pid',
    name: 'claim',
    beforeEnter(to) {
      window.location.href = `https://launchpad.spaceswap.app${to.fullPath}`;
    },
  },
  {
    path: '/calendar',
    name: 'calendar',
    beforeEnter() {
      window.location.href = 'https://launchpad.spaceswap.app/calendar';
    },
  },
  {
    path: '/license-confirmation',
    name: 'api',
  },
  {
    path: '/not-found',
    alias: '*',
    name: '404',
    component: NotFound,
  },
  {
    path: '/:lang',
    component: NotFound,
    beforeEnter(to, from, next) {
      const {
        params: { lang },
      } = to;
      if (!locales.includes(lang)) {
        return next();
      }
      if (i18n.locale !== lang) {
        i18n.locale = lang;
        VueCookie.set('locale', lang);
      }
      return next('/');
    },
  },
];
