import firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyBUwp3LLrsRoX1bDkKh4AV1r1U-7jp86W8',
  authDomain: 'spaceswap-admin.firebaseapp.com',
  databaseURL: 'https://spaceswap-admin.firebaseio.com',
  projectId: 'spaceswap-admin',
  storageBucket: 'spaceswap-admin.appspot.com',
  messagingSenderId: '653997627043',
  appId: '1:653997627043:web:5bcf1ac3844687983b5116',
  measurementId: 'G-QSD66BVPKL',
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;
