<script>
import { mapState } from 'vuex';

export default {
  name: 'PoolsSelect',
  computed: {
    ...mapState({
      chainId: (state) => state.User.chainId,
      chainName: (state) => state.User.chainName,
    }),
  },
};
</script>

<template>
  <div class="pools-select">
    <v-row>
      <v-col cols="6" md="4">
        <router-link :to="{ name: 'pools-interstellar' }" class="menu-card">
          <div class="menu-card__wrapper">
            <div>
              <div class="menu-card__title">
                {{ $t('header.pool-title.pools-interstellar') }}
              </div>
              <p>
                {{ $t('header.pool-description.pools-interstellar') }}
              </p>
            </div>
          </div>
        </router-link>
      </v-col>
      <v-col cols="6" md="4">
        <router-link :to="{ name: 'booster' }" class="menu-card">
          <div class="menu-card__wrapper">
            <div>
              <div class="menu-card__title">
                {{ $t('header.pool-title.booster') }}
              </div>
              <p>
                {{ $t('header.pool-description.booster') }}
              </p>
            </div>
          </div>
        </router-link>
      </v-col>
      <v-col cols="6" md="4">
        <div class="menu-card disabled" v-if="$chain.is('avalanche')">
          <div class="menu-card__wrapper">
            <div>
              <div class="menu-card__title">
                {{ $t('header.pool-title.bridge') }}
              </div>
              <p>
                {{ $t('header.pool-description.bridge') }}
              </p>
            </div>
          </div>
        </div>
        <router-link
          :to="{ name: 'bridgeSwap' }"
          class="menu-card"
          v-else
        >
          <div class="menu-card__wrapper">
            <div>
              <div class="menu-card__title">
                {{ $t('header.pool-title.bridge') }}
              </div>
              <p>
                {{ $t('header.pool-description.bridge') }}
              </p>
            </div>
          </div>
        </router-link>
      </v-col>
      <v-col cols="6" md="4">
        <a href="https://docs.spaceswap.app/spaceswap/spaceswap-intro/shadow-staking" class="menu-card">
          <div class="menu-card__wrapper">
            <div>
              <div class="menu-card__title">
                {{ $t('header.pool-title.shadow') }}
              </div>
              <p>
                {{ $t('header.pool-description.shadow') }}
              </p>
            </div>
          </div>
        </a>
      </v-col>
      <v-col cols="6" md="4">
        <div
          v-if="chainName === 'binance'"
          class="menu-card disabled"
          >
          <div class="menu-card__wrapper">
            <div>
              <div class="menu-card__title">
                {{ $t('header.pool-title.pools-nft') }}
              </div>
              <p>
                {{ $t('header.pool-description.pools-nft') }}
              </p>
            </div>
          </div>
        </div>
        <router-link
          v-else
          :to="{ name: 'pools-nft' }"
          class="menu-card"
          >
          <div class="menu-card__wrapper">
            <div>
              <div class="menu-card__title">
                {{ $t('header.pool-title.pools-nft') }}
              </div>
              <p>
                {{ $t('header.pool-description.pools-nft') }}
              </p>
            </div>
          </div>
        </router-link>
      </v-col>
      <v-col cols="6" md="4">
        <div class="menu-card disabled" v-if="$chain.is('binance') || $chain.is('avalanche')">
          <div class="menu-card__wrapper">
            <div>
              <div class="menu-card__title">
                {{ $t('header.pool-title.blender') }}
              </div>
              <p>
                {{ $t('header.pool-description.blender') }}
              </p>
            </div>
          </div>
        </div>
        <router-link v-else
          :to="{ name: 'blender' }"
          class="menu-card"
          >
          <div class="menu-card__wrapper">
            <div>
              <div class="menu-card__title">
                {{ $t('header.pool-title.blender') }}
              </div>
              <p>
                {{ $t('header.pool-description.blender') }}
              </p>
            </div>
          </div>
        </router-link>
      </v-col>
      <v-col cols="6" md="4" v-if="$chain.is('binance')">
        <div class="menu-card disabled">
          <div class="menu-card__wrapper">
            <div>
              <div class="menu-card__title">
                {{ $t('header.pool-title.pools-gravity') }}
              </div>
              <p>
                {{ $t('header.pool-description.pools-gravity') }}
              </p>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="6" md="4">
        <div class="menu-card disabled" v-if="$chain.is('binance') || $chain.is('avalanche')">
          <div class="menu-card__wrapper">
            <div>
              <div class="menu-card__title">
                {{ $t('header.pool-title.index-token-index') }}
              </div>
              <p>
                {{ $t('header.pool-description.index-token-index') }}
              </p>
            </div>
          </div>
        </div>
        <router-link :to="{ name: 'index-token-index' }" class="menu-card" v-else>
          <div class="menu-card__wrapper">
            <div>
              <div class="menu-card__title">
                {{ $t('header.pool-title.index-token-index') }}
              </div>
              <p>
                {{ $t('header.pool-description.index-token-index') }}
              </p>
            </div>
          </div>
        </router-link>
      </v-col>
      <v-col cols="6" md="4">
        <a href="https://launchpad.spaceswap.app/" class="menu-card">
          <div class="menu-card__wrapper">
            <div>
              <div class="menu-card__title">
                {{ $t('header.pool-title.starter') }}
              </div>
              <p>
                {{ $t('header.pool-description.starter') }}
              </p>
            </div>
          </div>
        </a>
      </v-col>
      <v-col cols="6" md="4">
        <div class="menu-card disabled">
          <div class="menu-card__wrapper">
            <div>
              <div class="menu-card__title">
                {{ $t('header.pool-title.synthetic') }}
              </div>
              <p>
                {{ $t('header.pool-description.synthetic') }}
              </p>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="6" md="4">
        <div class="menu-card disabled">
          <div class="menu-card__wrapper">
            <div>
              <div class="menu-card__title">
                {{ $t('header.pool-title.spacecrew') }}
              </div>
              <p>
                {{ $t('header.pool-description.spacecrew') }}
              </p>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="6" md="4">
        <router-link to="/spacelend" class="menu-card disabled">
          <div class="menu-card__wrapper">
            <div>
              <div class="menu-card__title">
                {{ $t("header.pool-title.spacelend") }}
              </div>
              <p>
                {{ $t("header.pool-description.spacelend") }}
              </p>
            </div>
          </div>
        </router-link>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss">
  .pools-select {
    background: $modal-bg;
    padding: 4rem;
    border-radius: 2rem;
    position: absolute;
    top: 10rem;
    width: calc(100% - 60rem);
    left: 0;
    margin: 0!important;
    box-shadow: $modal-shadow;
    z-index: 999;

    body.binance & {
      background: $b-modal-bg;
      box-shadow: $b-modal-shadow;
    }

    body.huobi & {
      background: $h-modal-bg;
      box-shadow: $h-modal-shadow;
    }

    @media(max-width: $breakpoint-md) {
      width: 100%;
    }

    @media(max-width: $breakpoint-sm) {
      padding: 1rem;
      top: 15rem;
    }

    a {
      text-decoration: none;
    }

    p {
      line-height: 1.5;
      font-size: 1.2em;
      text-align: left;

      @media(max-width: $breakpoint-sm) {
        display: none;
      }
    }

    .menu-card {
      display: block;
      padding: 2rem;
      border-radius: 1rem;
      height: 100%;

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed !important;
      }

      @media(max-width: $breakpoint-sm) {
        padding: 1rem;
      }

      &__wrapper {
        display: flex;

        p {
          body.huobi & {
            color: $h-secondatry-font-color;
          }
        }

        @media(max-width: $breakpoint-sm) {
          flex-direction: column;
          text-align: center;
        }

        div {
          &:first-child {
            margin-right: 1rem;

            @media(max-width: $breakpoint-sm) {
              margin-right: 1rem;
              margin-bottom: 1rem;
            }
          }
        }
      }

      &__title {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.5em;
        margin-bottom: 1.4em;
      }

      &__img {
        width: 12rem;
        height: auto;

        @media(max-width: $breakpoint-sm) {
          width: auto;
          height: 8rem;
        }
      }

      &.router-link-active {
        box-shadow: $pools-select-shadow;

        body.binance & {
          background: $b-pools-select-bg;
          box-shadow: $b-pools-select-shadow;
        }

        body.huobi & {
          background: $h-pools-select-bg;
          box-shadow: $h-pools-select-shadow;
        }
      }
    }
  }
</style>
