import BigNumber from 'bignumber.js';
import { get } from '@/helpers/api/price.api';

const tokens = {
  dai: {
    name: 'DAI',
  },
  nusd: {
    name: 'SUSD',
  },
  'usd-coin': {
    name: 'USDC',
  },
  tether: {
    name: 'USDT',
  },
  usdq: {
    name: 'USDQ',
  },
  'spaceswap-shake': {
    name: 'SHAKE',
  },
  'spaceswap-milk2': {
    name: 'MILK2',
  },
  ethereum: {
    name: 'ETH',
  },
  binancecoin: {
    name: 'BNB',
  },
  'avalanche-2': {
    name: 'AVAX',
  },
};

const state = {
  list: {},
};

const mutations = {
  setPrices: (state, prices) => {
    state.list = prices;
  },
};

const actions = {
  update: async ({ commit }) => {
    const IDs = Object.entries(tokens).map((token) => token[0]).join('%2C');
    try {
      const { data } = await get(IDs);
      const prices = {};
      Object.entries(data).map((item) => {
        prices[tokens[item[0]].name] = new BigNumber(item[1].usd);
        prices[`${tokens[item[0]].name}_24h`] = new BigNumber(item[1].usd_24h_change);
        return true;
      });
      commit('setPrices', prices);
    } catch (error) {
      console.error(error);
    }
  },
};

export default {
  state,
  mutations,
  actions,
};
