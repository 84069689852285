<script>
import { mapState } from 'vuex';

export default {
  name: 'Logo',
  computed: {
    ...mapState({
      chainId: (state) => state.User.chainId,
      chainName: (state) => state.User.chainName,
    }),
  },
};
</script>

<template>
  <router-link to="/" class="header-logo">
    <img
      alt="SpaceSwap"
      :src="require(`@/assets/img/${chainName}/small-logo.svg`)"
      >
    <img
      v-if="$route.name === 'home'"
      alt="bells"
      class="new-year"
      :src="require(`@/assets/img/new-year/_logo.svg`)"
    >
  </router-link>
</template>

<style lang="scss">
.header-logo {
  height: 5rem;

  @media screen and (max-width: $breakpoint-sm) {
    order: -1;
    margin-bottom: 1.5rem;
  }
  img {
    height: 100%;
  }
}
</style>
