<template>
  <div class="solana-wallet">
    <Button v-if="true" class="btn-solana solana-wallet-btn no-shadow" @click="modal = true">
      {{ $t('solana.connect_wallet') }}
    </Button>
    <div v-else class="solana-wallet-info" @click="modal = true">
      <div class="solana-wallet-icon">
        <img src="@/assets/img/solana/wallet-icon.png" alt="wallet">
      </div>
      <div>
        <div class="solana-wallet-address">2sds...2dd2</div>
        <div class="solana-wallet-network">Solana</div>
      </div>
    </div>
    <v-dialog
      v-model="modal"
      content-class="solana-modal solana-wallet-modal"
      @click:outside="modal = false"
      eager
      overlay-opacity="1"
    >
      <div class="solana-modal-close" @click="modal = false"></div>
      <div class="solana-wallet-title solana-title"> {{ $t(`solana.choose_${type}`) }} </div>
      <div class="solana-wallet-scroll">
        <vuescroll
          class="solana-wallet-body"
          v-if="type === 'network'"
          :ops="scroll_settings"
        >
          <div
            class="solana-wallet-item"
            v-for="(network, i) of networks"
            :key="i"
            @click="active_network = network.name"
            :class="{active: network.name === active_network}"
          >
            <img
              :src="
              require(`@/assets/img/solana/networks/${network.image}${light ? '-light' : ''}.png`)
              "
              :alt="network.name"
            >
            <span>{{ network.name }}</span>
          </div>
        </vuescroll>
        <vuescroll :ops="scroll_settings" class="solana-wallet-body" v-else>
          <div class="solana-wallet-item" v-for="(wallet, i) of wallets" :key="i">
            <img
              :src="
              require(`@/assets/img/solana/wallets/${wallet.image}${light ? '-light' : ''}.png`)"
              :alt="wallet.name"
            >
            <span>{{ wallet.name }}</span>
          </div>
        </vuescroll>
      </div>
      <div class="solana-wallet-footer">
        <Button
          :disabled="Boolean(!active_network)"
          class="btn-solana no-shadow w-100"
          v-if="type === 'network'"
          @click="type = 'wallet'"
        >
          {{ $t('solana.buttons.continue') }}
        </Button>
        <Button
          :disabled="Boolean(!active_network)"
          class="btn-solana no-shadow w-100"
          v-else
        >
          {{ $t('solana.buttons.continue') }}
        </Button>

        <div class="solana-wallet-back" @click="type = 'network'" v-if="type === 'wallet'">
          <img v-if="light" src="@/assets/img/solana/arrow-left-pink.png" alt="arrow-left">
          <img v-else src="@/assets/img/solana/arrow-left2.png" alt="arrow-left">
          {{ $t('solana.buttons.go_back') }}
        </div>
        <div v-else class="solana-wallet-note">
          {{ $t('solana.choose_network_terms.part1') }}
          <a href="#">{{ $t('solana.choose_network_terms.part2') }}</a>
          {{ $t('solana.choose_network_terms.part3') }}
          <a href="#">{{ $t('solana.choose_network_terms.part4') }}</a>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import Button from '@/components/Button';
import vuescroll from 'vuescroll';

export default {
  name: 'SWallet',
  components: { Button, vuescroll },
  data() {
    return {
      modal: false,
      type: 'network',
      active_network: null,
      networks: [
        {
          name: 'Ethereum',
          image: 'ethereum',
        },
        {
          name: 'BInance Smart Chain',
          image: 'binance',
        },
        {
          name: 'HECO',
          image: 'heco',
        },
        {
          name: 'Solana',
          image: 'solana',
        },
        {
          name: 'Ethereum',
          image: 'ethereum',
        },
        {
          name: 'Ethereum',
          image: 'ethereum',
        },
      ],
      wallets: [
        {
          name: 'Metamask',
          image: 'metamask',
        },
        {
          name: 'TrustWallet',
          image: 'trust',
        },
        {
          name: 'Ledger',
          image: 'ladger',
        },
        {
          name: 'WalletConnect',
          image: 'wconnect',
        },
      ],
    };
  },
  computed: {
    light() {
      return this.$route.path.includes('cardano');
    },
    scroll_settings() {
      return {
        scrollPanel: {
          maxHeight: 400,
        },
        rail: {
          background: this.light ? '#FFFFFF' : '#2D2B68',
          opacity: 1,
          size: '4px',
        },
        bar: {
          showDelay: 500,
          keepShow: true,
          background: this.light ? '#335CBE' : '#F6E9E1',
          opacity: 1,
          size: '8px',
        },
      };
    },
  },

};
</script>

<style scoped lang="scss">
@import "@/assets/style/functions.scss";

</style>
