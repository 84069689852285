import Vue from 'vue';
import BigNumber from 'bignumber.js';

export const state = {
  tokenId: 1,
  allowance: 0,
  price: null,
  supply: null,
  limit: 3,
  userTokensCount: 0,
  closedAfter: null,
  activeAfter: null,
};

export const getters = {
  allowance: (s) => s.allowance,
  price: (s) => s.price,
  supply: (s) => s.supply,
  tokenId: (s) => s.tokenId,
  limit: (s) => s.limit,
  userTokensCount: (s) => s.userTokensCount,
  closedAfter: (s) => s.closedAfter,
  activeAfter: (s) => s.activeAfter,
};

export const mutations = {
  setAllowance: (state, payload) => { state.allowance = payload; },
  setPrice: (state, payload) => { state.price = payload; },
  setSupply: (state, payload) => { state.supply = payload; },
  setLimit: (state, payload) => { state.limit = payload; },
  setClosedAfter: (state, payload) => { state.closedAfter = payload; },
  setActiveAfter: (state, payload) => { state.activeAfter = payload; },
  setUserTokensCount: (state, payload) => { state.userTokensCount = payload; },
};

export const actions = {
  async allowanceMilk2({ rootState, commit }) {
    try {
      const milkAllowance = Vue.$contracts.getContract('milk2').methods.allowance;
      const sidusPresaleAddress = Vue.$contracts.getAddress('sidusPresale');

      const allowance = await milkAllowance(rootState.User.ethAddress, sidusPresaleAddress).call();
      commit('setAllowance', Number(allowance));
    } catch (e) {
      console.log(e);
    }
  },

  async approveMilk2({ rootState }, amount) {
    try {
      const sidusPresaleAddress = Vue.$contracts.getAddress('sidusPresale');
      const milkApprove = Vue.$contracts.getContract('milk2').methods.approve;
      console.log(Vue.$BN(amount).toString());
      await milkApprove(sidusPresaleAddress, Vue.$BN(amount).toString()).send({
        from: rootState.User.ethAddress,
      });
    } catch (e) {
      console.log(e);
    }
  },

  async registerForPreSale({ rootState }, quantity) {
    const register = Vue.$contracts.getContract('sidusPresale').methods.registerForPreSale;
    const milkAddress = Vue.$contracts.getAddress('milk2');
    await register(milkAddress, [[1, quantity]]).send({
      from: rootState.User.ethAddress,
    });
  },

  async presaleDate({ commit }) {
    try {
      const presale = Vue.$contracts.getContract('sidusPresale').methods;

      const [active, closed] = await Promise.all([
        presale.activeAfter().call(),
        presale.closedAfter().call(),
      ]);
      commit('setClosedAfter', Number(closed));
      commit('setActiveAfter', Number(active));
    } catch (e) {
      console.log(e);
    }
  },

  async maxTotalSupply({ commit, state }) {
    try {
      const presale = Vue.$contracts.getContract('sidusPresale').methods;
      const res = await presale.maxTotalSupply(state.tokenId).call();

      commit('setSupply', {
        maxTotalSupply: Number(res.maxTotalSupply),
        currentSupply: Number(res.currentSupply),
      });
    } catch (e) {
      console.log(e);
    }
  },

  async getPresaleInfo({ state, rootState, commit }) {
    const milkAddress = Vue.$contracts.getAddress('milk2');
    const presale = Vue.$contracts.getContract('sidusPresale').methods;

    const [price, limit, userTokensCount] = await Promise.all([
      presale.getCardPrice(milkAddress, state.tokenId).call(),
      presale.CARDS_PER_USER().call(),
      presale.getUserLimitForCard(rootState.User.ethAddress, state.tokenId).call(),
      this.dispatch('Sidus/maxTotalSupply'),
      this.dispatch('Sidus/allowanceMilk2'),
      this.dispatch('Sidus/presaleDate'),
    ]);

    commit('setUserTokensCount', Number(userTokensCount));
    commit('setLimit', Number(limit));
    commit('setPrice', new BigNumber(price.value));
  },
};
