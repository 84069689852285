import Vue from 'vue';
import axios from 'axios';

let baseUrl = 'https://spaceswap.app/bridge';
if (process.env?.NODE_ENV === 'local') {
  baseUrl = 'https://dev.spaceswap.app/bridge';
}

export const state = {
  allowance: {
    milk: 0,
    shake: 0,
  },
  activeOrders: [],
  chains: {
    1: 'MAINNET',
    3: 'ROPSTEN',
    4: 'RINKEBY',
    5: 'GOERLI',
    42: 'KOVAN',
    56: 'BINANCE',
    97: 'BINANCE_TESTNET',
    43113: 'AVALANCHE_TESTNET',
    43114: 'AVALANCHE',
  },
  testnets: ['RINKEBY', 'BINANCE_TESTNET', 'AVALANCHE_TESTNET'],
  networks: {
    testnet: {
      eth: 'RINKEBY',
      bsc: 'BINANCE_TESTNET',
      avax: 'AVALANCHE_TESTNET',
    },
    mainnet: {
      eth: 'MAINNET',
      bsc: 'BINANCE',
      avax: 'AVALANCHE',
    },
  },
  contractChainId: null,
  swapData: null,
};

export const getters = {
  allowance: (s) => s.allowance,
  chains: (s) => s.chains,
  testnets: (s) => s.testnets,
  networks: (s) => s.networks,
  activeOrders: (s) => s.activeOrders.filter((el) => el.status === 'BURNED'),
  processingOrders: (s) => s.activeOrders.filter((el) => el.status === 'PENDING'),
  contractChainId: (s) => s.contractChainId,
  swapData: (s) => s.swapData,
};

export const mutations = {
  setAllowance: (state, payload) => { state.allowance = payload; },
  setActiveOrders: (state, payload) => { state.activeOrders = payload; },
  setContractChainId: (state, payload) => { state.contractChainId = payload; },
  setSwapData: (state, payload) => { state.swapData = payload; },
};

export const actions = {
  async getGasPrice() {
    try {
      const links = {
        1: 'https://api.etherscan.io/api?module=proxy&action=eth_gasPrice&apikey=MQ1CE5QUMPR4R5EUU1RK42AKQ883JCM1DR',
        4: 'https://api-rinkeby.etherscan.io/api?module=proxy&action=eth_gasPrice&apikey=MQ1CE5QUMPR4R5EUU1RK42AKQ883JCM1DR',
        56: 'https://api.bscscan.com/api?module=proxy&action=eth_gasPrice&apikey=NT3TA281XU1Y5FV9ZAXJINT3I8CDK6HPS1',
        97: 'https://api-testnet.bscscan.com/api?module=proxy&action=eth_gasPrice&apikey=NT3TA281XU1Y5FV9ZAXJINT3I8CDK6HPS1',
        43113: 'https://api-testnet.snowtrace.io/api?module=proxy&action=eth_gasPrice&apikey=3W24GQNXNA3P2Z8HG6B6MRNEW5E9I7TXCQ',
        43114: 'https://api.snowtrace.io/api?module=proxy&action=eth_gasPrice&apikey=3W24GQNXNA3P2Z8HG6B6MRNEW5E9I7TXCQ',
      };
      const chainId = await this.$web3.eth.getChainId();
      const link = links[chainId];
      const { data } = await axios.get(link);
      const gasPrice = this.$web3.utils.hexToNumber(data.result);
      return Math.ceil(gasPrice + gasPrice * 0.5);
    } catch (e) {
      const gasPrice = await this.$web3.eth.getGasPrice();
      return Math.ceil(Number(gasPrice) + Number(gasPrice) * 0.5);
    }
  },

  async getContractChainId({ commit }) {
    const shadowBridge = Vue.$contracts.getContract('shadowBridge').methods;
    const chainId = await shadowBridge.chainId().call();
    commit('setContractChainId', chainId);
    return chainId;
  },

  async swapAllowance({ commit }) {
    const [milk, shake] = await Promise.all([
      this.dispatch('Actions/allowance', {
        contract: 'milk2',
        spender: 'shadowBridge',
      }),
      this.dispatch('Actions/allowance', {
        contract: 'shake',
        spender: 'shadowBridge',
      }),
    ]);

    commit('setAllowance', { milk, shake });
  },

  async getSwapData({ rootState, dispatch, commit }, {
    fromChain, toChain, amount, ticker, tokenAddress, tokenAddressTo,
  }) {
    const chainId = await dispatch('getContractChainId');
    const obj = {
      fromChain,
      toChain,
      amount,
      ticker,
      tokenAddressTo,
      tokenAddressFrom: tokenAddress,
      chainId: Number(chainId),
    };
    const { data } = await axios.post(`${baseUrl}/transfer/${rootState.User.ethAddress}`, obj);
    commit('setSwapData', data.data);
    return data;
  },

  async payIn({ rootState, dispatch }, {
    orderId, amount, tokenAddress, msgForSign, signature,
  }) {
    const shadowBridge = Vue.$contracts.getContract('shadowBridge').methods;
    const gasPrice = await dispatch('getGasPrice');
    await shadowBridge.payIn(
      orderId, amount, tokenAddress, msgForSign, signature,
    ).send({ from: rootState.User.ethAddress, gasPrice });
  },

  async getClaimData({ rootState, dispatch }, { orderId, unstakedTokenAddress }) {
    const chainIdTo = await dispatch('getContractChainId');
    const { data } = await axios.post(`${baseUrl}/claim/${rootState.User.ethAddress}`, {
      orderId, chainIdTo: Number(chainIdTo), unstakedTokenAddress,
    });
    return data;
  },

  async orderCancel({ state }) {
    try {
      if (state.swapData?.orderId) {
        await axios.put(`${baseUrl}/orders/${state.swapData.orderId}/cancel`);
      }
    } catch (e) {
      console.log(e);
    }
  },

  async payOut({ rootState, dispatch }, {
    orderId, amount, tokenAddress, msgForSign, signature,
  }) {
    const shadowBridge = Vue.$contracts.getContract('shadowBridge').methods;
    const gasPrice = await dispatch('getGasPrice');
    await shadowBridge.payOut(
      orderId, amount, tokenAddress, msgForSign, signature,
    ).send({ from: rootState.User.ethAddress, gasPrice });
    await dispatch('getActiveOrders');
  },

  async getActiveOrders({ rootState, commit }) {
    const { data } = await axios.get(`${baseUrl}/orders/${rootState.User.ethAddress}?status=pending,burned`);
    if (data.status === 'success') {
      commit('setActiveOrders', data.data);
    }
  },

  async ordersForExplorer({ rootState }) {
    const { data } = await axios.get(`${baseUrl}/orders/${rootState.User.ethAddress}?status=pending,burned,completed`);
    return data.data;
  },
};
