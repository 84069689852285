<script>
import { mapState } from 'vuex';
import Button from '../Button';
import metamask from '../../assets/img/metamask.svg';
import cat from '../../assets/img/modal-cat.svg';
import milk from '../../assets/img/modal-milk.svg';

export default {
  name: 'MyAccount',
  data: () => ({
    metamask,
    cat,
    milk,
  }),
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  components: { Button },
  computed: {
    ...mapState({
      ethAddress: (state) => state.User.ethAddress,
      chainId: (state) => state.User.chainId,
      totalSupply: (state) => state.Milk.totalSupply,
      milkBalance: (state) => state.Milk.milkBalance,
    }),
  },
};
</script>

<template>
  <v-dialog
    @input="$emit('update:show', $event)"
    :value="show"
  >
    <div class="modal main-block modal--light">
      <div class="modal__logo-circle">
        <img class="icon" :src="metamask"/>
      </div>

      <h4 class="modal__ttl">{{ $t('modals.account.title') }}</h4>
      <div class="account-modal__balance">
        <span>{{ milkBalance | amount }}&nbsp;</span>
        <span>{{ $t('modals.account.total-blalance',  { token: 'MILK2'}) }}</span>
      </div>
      <a
        v-if="chainId == 1"
        :href="`https://etherscan.io/address/${ethAddress}`"
        target="_blank"
        class="account-modal__link"> View Etherscan
        <div class="icon-btn">
        </div>
      </a>
      <a
        v-else-if="chainId == 56"
        :href="`https://bscscan.com/address/${ethAddress}`"
        target="_blank"
        class="account-modal__link"> View BscScan
        <div class="icon-btn">
        </div>
      </a>
      <a
        v-else-if="chainId == 43114"
        :href="`https://snowtrace.io/address/${ethAddress}`"
        target="_blank"
        class="account-modal__link"> View Snowtrace
        <div class="icon-btn">
        </div>
      </a>
      <div class="modal__btns">
        <Button @click="$emit('update:show', false)">
          {{ $t('modals.account.buttons.back') }}
        </Button>
      </div>
    </div>
  </v-dialog>
</template>

<style lang="scss">
.account-modal {
  &__balance {
    text-align: center;
    margin: 4rem 0;
    font-size: 1.6rem;
    span {
      &:first-child {
        font-size: 1.8em;
        font-weight: 700;
      }
    }
  }
  &__link {
    display: block;
    margin: 2rem 0;
    text-align: center;
    font-size: 1.4rem;
  }
}
</style>
