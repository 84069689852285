import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: '#343E9A',
        secondary: '#343E9A',
        accent: '#0ED7A6',
        error: '#b71c1c',
        background: '#f7f7f7',
      },
    },
  },
};

export default new Vuetify(opts);
