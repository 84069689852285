<script>
import Button from '@/components/Button';
import { mapState } from 'vuex';

export default {
  name: 'WithdrawButton',
  components: { Button },
  data: () => ({
    loading: false,
    registerDoneServerPending: false,
  }),
  computed: {
    ...mapState({
      sign: (state) => state.User.sign,
      ethAddress: (state) => state.User.ethAddress,
      lastBlock: (state) => state.User.lastBlock,
    }),
    pending() {
      if (this.sign) return this.$contracts.fromWei(this.sign.amount);
      return 0;
    },
  },
  async created() {
    this.loading = Number(this.lastBlock) > 0 && !this.sign;
  },
  watch: {
    lastBlock(val) {
      this.registerDoneServerPending = Number(val) > 0 && !this.sign;
    },
    sign(val) {
      this.registerDoneServerPending = Number(this.lastBlock) > 0 && !val;
    },
  },
  methods: {
    async withdraw() {
      if (this.sign) {
        try {
          this.loading = true;
          const shadowContract = this.$contracts.getInstance(this.$contracts.getAddress('shadow'), 'shadow');
          const amount = this.$BN(this.sign.amount).toString(10);
          await shadowContract.methods.harvest(
            amount,
            this.$store.state.User.sign.lastBlockNumber,
            this.$store.state.User.sign.currentBlockNumber,
            this.$store.state.User.sign.msgForSign,
            this.$store.state.User.sign.signature,
          ).send({ from: this.ethAddress });
        } catch (e) {
          console.error(e);
        }
        this.loading = false;
      }
    },
  },
};
</script>

<template>
  <Button
    :disabled="!pending || !pending.comparedTo(0) || loading || registerDoneServerPending"
    @click="withdraw"
  >
    {{ $t('shadow.harvest') }}
  </Button>
</template>

<style lang="scss">
</style>
