import Vue from 'vue';
import poolsMainnet from '@/pools.mainnet';
import poolsRinkeby from '@/pools.rinkeby';
import calculatePool from '@/utils/calculatePools';
import BigNumber from 'bignumber.js';

// TODO: add binance?
const pools = {
  1: poolsMainnet,
  4: poolsRinkeby,
};

const state = {
  list: {},
  pending: {},
};

const actions = {
  changePools: async (context) => {
    // TODO: set chainId in proper way
    context.commit('setPools', pools[context.rootState.User.chainId || '1']);
  },
  update: async (context, type) => {
    context.commit('loading', true);

    const res = await Promise.all(context.state.list[type].map(async (_pool) => {
      const pool = await calculatePool(_pool, type);
      context.commit('setPool', { pool, type });
      return pool;
    }));

    // ToDo: move this to specific component
    const pending = res.reduce((s, pool) => s.plus(pool.pendingMilk), new BigNumber(0));

    context.commit('setPendigMilk', { pending, type });

    context.commit('loading', false);
  },
};

const mutations = {
  loading: (state, status) => {
    state.isLoading = !!status;
  },
  setPools: (state, _pools) => {
    state.list = _pools;
  },
  setPool: (state, { pool, type }) => {
    let index;
    try {
      index = state.list[type].findIndex((x) => x.id === pool.id);
    } catch (e) {
      console.error(e);
    }
    Vue.set(state.list[type], index, pool);
  },
  setPendigMilk: (state, { pending, type }) => {
    Vue.set(state.pending, type, pending);
  },
};

export default {
  state,
  actions,
  mutations,
};
