<script>
import Button from '@/components/Button';
import { mapState } from 'vuex';

export default {
  name: 'Footer',
  components: { Button },
  props: {
    isLanding: Boolean,
  },
  computed: {
    ...mapState({
      chainId: (state) => state.User.chainId,
      chainName: (state) => state.User.chainName,
    }),
    socials() {
      return [
        {
          url: 'https://etherscan.io/token/0x80c8c3dcfb854f9542567c8dac3f44d709ebc1de',
          image: 'ic-etherscan.svg',
          name: 'etherscan',
        },
        {
          url: 'https://github.com/spaceswap',
          image: 'ic-github.svg',
          name: 'github',
        },
        {
          url: 'https://twitter.com/spaceswapdefi',
          image: 'ic-twitter.svg',
          name: 'twitter',
        },
        {
          url: 'https://discord.com/invite/4hvxZNWGHP',
          image: 'ic-discord.svg',
          name: 'discord',
        },
        {
          url: 'https://h5.huobichat.com/sp/#/group?g=vjc7htoiqe',
          image: 'ic-huobi.svg',
          name: 'huobi',
        },
        {
          url: 'https://t.me/spaceswap',
          image: 'ic-telegram.svg',
          name: 'telegram',
        },
        {
          url: 'https://www.youtube.com/c/SpaceSwap',
          image: 'ic-youtube.svg',
          name: 'youtube',
        },
      ];
    },
  },
};
</script>

<template>
  <!-- TODO: reqrite to v-for loop -->
  <v-row :class="['main-wrap',
   {'footer-landing': isLanding}]">
    <v-col class="footer">
      <v-row>
        <v-col class="footer__tokens first-footer" cols="12" sm="12" md="5">
          <a
            :href="
              $chain.getLinkByNetwork(
                'https://etherscan.io/token/0x80c8c3dcfb854f9542567c8dac3f44d709ebc1de',
                'https://bscscan.com/token/0x4a5a34212404f30c5ab7eb61b078fa4a55adc5a5',
                null,
                'https://snowtrace.io/address/0x721c299e6bf7d6a430d9bea3364ea197314bce09'
              )
            "
            target="_blank"
          >
            <img
              :alt="`@/assets/img/${chainName}/footer/ic-milk-token.svg`"
              :src="require(`@/assets/img/${chainName}/footer/ic-milk-token.svg`)"
            />
          </a>
          <a
            :href="
              $chain.getLinkByNetwork(
                'https://etherscan.io/token/0x6006FC2a849fEdABa8330ce36F5133DE01F96189',
                'https://bscscan.com/token/0xba8a6ef5f15ed18e7184f44a775060a6bf91d8d0',
                null,
                'https://snowtrace.io/address/0xc1d02e488a9ce2481bfdcd797d5373dd2e70a9c2',
              )
            "
            target="_blank"
          >
            <img
              alt="ic-shake"
              :src="require(`@/assets/img/${$chain.getName()}/footer/ic-shake.svg`)"
            />
          </a>
        </v-col>
        <v-col cols="12" sm="12" md="2" style="text-align: center" class="justify-center flex">
          <Button href="https://classic.spaceswap.app/" class="cancel new-year-btn">
            <img
              src="@/assets/img/new-year/switch.svg"
              alt="new-year"
              class="new-year"
            >
            Switch to v1
          </Button>
        </v-col>
        <v-col class="footer__social last-footer"
               align-self="center" cols="12" sm="12" md="5">
          <a
            v-for="(item, i) of socials"
            :key="i"
            :href="item.url"
            target="_blank"
          >
            <img
              :src="require(`@/assets/img/${chainName}/footer/social/${item.image}`)"
              :alt="chainName"
            />
          </a>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="footer__divider">
          <div></div>
          <span>Media & Partners</span>
          <div></div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="footer__media">
          <a
            href="https://www.newsbtc.com/press-releases/time-to-milk-some-liquidity-pools-how-spaceswap-makes-it-possible/"
            target="_blank"
          >
            <img
              :src="require(`@/assets/img/${$chain.getName()}/footer/ic-newsbtc.svg`)"
              alt="newsbtc"
            />
          </a>
          <a
            href="https://blockonomi.com/hungry-for-crypto-profits-spaceswap-is-coming-to-bring-yield-farmers-tons-of-milk/"
            target="_blank"
          >
            <img
              :src="require(`@/assets/img/${$chain.getName()}/footer/ic-blockonomi.svg`)"
              alt="blockonomi"
            />
          </a>
          <a href="https://www.coinspeaker.com/spaceswap-new-standard-defi/" target="_blank">
            <img
              :src="require(`@/assets/img/${$chain.getName()}/footer/ic-coinspeaker.svg`)"
              alt="coinspeaker"
            />
          </a>
          <a href="https://coinidol.com/spaceswap-defi-alternative/" target="_blank">
            <img
              :src="require(`@/assets/img/${$chain.getName()}/footer/ic-coinidol.svg`)"
              alt="coinidol"
            />
          </a>
          <a href="https://cointelegraphcn.com/news/spaceswap-20" target="_blank">
            <img
              :src="require(`@/assets/img/${$chain.getName()}/footer/ic-cointelegraph.svg`)"
              alt="cointelegraph"
            />
          </a>
          <a
            href="https://bitcoinist.com/comparing-the-different-types-of-defi-farmers/"
            target="_blank"
          >
            <img
              :src="require(`@/assets/img/${$chain.getName()}/footer/ic-bitcoinist.svg`)"
              alt="bitcoinist"
            />
          </a>
          <a
            href="https://www.binance.org/en/smartChain"
            target="_blank"
            v-if="!$chain.is('avalanche')"
          >
            <img :src="require(`@/assets/img/${$chain.getName()}/footer/ic-bsс.svg`)" alt="bsс"/>
          </a>
          <a href="https://www.coingecko.com/en/coins/milk2" target="_blank">
            <img
              :src="require(`@/assets/img/${$chain.getName()}/footer/ic-coingecko.svg`)"
              alt="coingecko"
            />
          </a>
          <a
            href="https://sidusheroes.com/"
            target="_blank"
          >
            <img
              class="sidus"
              :src="require(`@/assets/img/${$chain.getName()}/footer/sidus.png`)"
              alt="sidus"
            />
          </a>
          <a href="https://nftstars.app" target="_blank">
            <img
              class="nft-stars"
              :src="require(`@/assets/img/${$chain.getName()}/footer/nft-stars.png`)"
              alt="nft-stars"
            />
          </a>
          <a href="https://coinmarketcap.com/currencies/spaceswap/" target="_blank">
            <img
              :src="require(`@/assets/img/${$chain.getName()}/footer/ic-coinmarketcap.svg`)"
              alt="coinmarketcap"
            />
          </a>
          <a href="https://www.novaglobal.io/newsinfo/445408.html" target="_blank">
            <img :src="require(`@/assets/img/${$chain.getName()}/footer/ic-nova.svg`)" alt="nova"/>
          </a>
          <a
            href="https://info.uniswap.org/pair/0xee802b312a957e00dd7bbc08eec8bb93d40e981d"
            target="_blank"
            v-if="!$chain.is('avalanche')"
          >
            <img
              :src="require(`@/assets/img/${$chain.getName()}/footer/ic-uniswap.svg`)"
              alt="uniswap"
            />
          </a>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="footer__disclaimer">
          {{ $t("disclaimer.description-1") }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="footer__read-more">
          <router-link :to="{ name: 'disclaimer' }" class="link">
            {{ $t("disclaimer.read-more") }}
          </router-link>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<style lang="scss">
.footer-landing {
  background: #F5F5F7 !important;
  margin-top: 0rem !important;
}

.footer {
  font-size: 1rem;
  align-self: flex-end;

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 1.6rem;
  }
  @media screen and (max-width: $breakpoint-sm) {
    margin-top: 6rem;
  }

  &__tokens {
    display: flex;
    align-items: center;

    a {
      display: block;
      width: 9.5em;
      @media screen and (max-width: $breakpoint-sm) {
        margin: 0 auto;
      }

      &:not(:last-child) {
        margin-right: 6em;
        @media screen and (max-width: $breakpoint-sm) {
          margin-right: auto;
        }
      }

      img {
        width: 100%;
      }
    }
  }

  &__social {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media screen and (max-width: $breakpoint-sm) {
      flex-wrap: wrap;
      justify-content: center;
    }

    a {
      display: block;
      position: relative;
      width: 3em;
      height: 3em;
      border-radius: 50%;

      body.binance & {
        box-shadow: $b-pools-select-shadow;
      }

      @media screen and (max-width: $breakpoint-sm) {
        width: 5em;
        height: 5em;
        margin: 2em;
      }

      &:not(:first-child) {
        margin-left: 4em;
        @media screen and (max-width: $breakpoint-sm) {
          margin: 2em;
        }
      }

      box-shadow: -3px 3px 6px rgba(212, 202, 205, 0.25), 3px -3px 6px rgba(212, 202, 205, 0.19),
      -3px -7px 10px #ffffff, 3px 8px 6px rgba(213, 202, 205, 0.36),
      inset 1px 1px 2px rgba(255, 255, 255, 0.35), inset -1px -1px 2px rgba(212, 202, 205, 0.06);

      &:hover {
        box-shadow: inset 2px 2px 8px #fff, inset 2px 2px 6px rgba(181, 155, 155, 0.2),
        inset -1px -1px 6px rgba(181, 155, 155, 0.2);
      }

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 60%;
        height: 100%;
        max-height: 60%;
      }
    }
  }

  &__divider {
    margin: 4em 0;
    display: flex;
    align-items: center;

    body.binance & {
      color: $b-grey-font-color;
    }

    body.huobi & {
      color: $h-main-font-color;
    }

    div {
      flex-grow: 1;
      height: 0.1em;
      background: #d6dafc;

      body.binance & {
        background: $b-grey-font-color;
      }

      body.huobi & {
        background: $h-main-font-color;
      }
    }

    span {
      font-size: 1.7em;
      font-weight: 600;
      padding: 0 2em;
    }
  }

  &__media {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    a {
      display: block;
      width: 16.6%;
      margin-bottom: 4em;
      @media screen and (max-width: $breakpoint-md) {
        width: 30%;
      }
      @media screen and (max-width: $breakpoint-sm) {
        width: 50%;
      }
    }

    img {
      display: block;
      height: 4em;
      max-width: 70%;
      margin: 0 auto;
    }
  }

  &__disclaimer {
    font-size: 1.2em;

    body.binance & {
      color: $b-grey-font-color;
    }
  }

  &__read-more {
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;

    body.binance & a {
      color: $b-grey-font-color;
    }
  }
}

.nft-stars, .sidus {
  max-width: 124px;
  object-fit: contain;
}

.last-footer {
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: $breakpoint-md) {
    justify-content: center !important;
  }
}

.first-footer {
  display: flex;
  justify-content: flex-start;

  @media screen and (max-width: $breakpoint-md) {
    justify-content: center !important;
  }
}
</style>
