<template>
  <v-app class="solana" :class="{light}">
    <cardano-header v-if="light"/>
    <s-header v-else />
    <slot></slot>
    <s-footer/>
  </v-app>
</template>

<script>
import SHeader from '@/components/Solana/SHeader';
import SFooter from '@/components/Solana/SFooter';
import CardanoHeader from '@/components/Cardano/CardanoHeader';

export default {
  name: 'SLayout',
  props: {
    light: {
      type: Boolean,
      default: false,
    },
  },
  components: { CardanoHeader, SHeader, SFooter },
};
</script>

<style lang="scss">
@import '@/assets/style/solana.scss';
</style>
