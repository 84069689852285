<script>
import { mapState } from 'vuex';

export default {
  name: 'Button',
  props: {
    href: {
      default: '',
    },
    to: {
      default: '',
    },
    authOnly: Boolean,
    height: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    loaderColor() {
      return this.$chain.getName() === 'ethereum' ? '#FF7979' : '#FFF';
    },
    ...mapState({
      ethAddress: (state) => state.User.ethAddress,
    }),
  },
  methods: {
    click() {
      if (this.authOnly && !this.ethAddress) {
        return this.$root.$emit('connect');
      }
      if (this.to) {
        return this.$router.push(this.to);
      }
      return this.$emit('click');
    },
  },
};
</script>

<template>
  <router-link
    :to="to"
    class="btn"
    v-if="to && !authOnly"
    :style="`height: ${height}`"
    :class="{disabled}"
  >
    <slot></slot>
  </router-link>
  <a
    :style="`height: ${height}`"
    :href="href"
    :target="/^http/.test(href) ? '_blank' : '_self'"
    class="btn"
    :class="{disabled}"
    v-else-if="href"
  >
    <slot></slot>
  </a>
  <button
    :disabled="disabled"
    :style="`height: ${height}`"
    class="btn"
    :class="{ 'btn--loading': loading }"
    @click="click"
    v-else
  >
    <slot v-if="!loading"></slot>
    <v-progress-circular
      v-if="loading"
      indeterminate
      :size="20"
      :width="2"
      :color="loaderColor"
    ></v-progress-circular>
  </button>
</template>

<style lang="scss">
@import '@/assets/style/_colors.scss';

.btn {
  display: inline-block;
  width: auto;
  height: auto;
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  color: inherit;
  background: #f8fcfd;
  line-height: 1em;
  box-shadow: $btn-shadow;
  padding: 1.35em 4em;
  border-radius: 2em;

  @media screen and (max-width: $breakpoint-md) {
    font-size: 2.6rem;
  }

  @media screen and (max-width: $breakpoint-sm) {
    font-size: 2.3rem;
    padding: 1.1em 4em;
  }

  &:hover {
    background: $btn-bg-hover;
    box-shadow: $btn-shadow-hover;
  }

  &:active {
    background: $btn-bg-active;
    box-shadow: $btn-shadow-active;
  }

  &:disabled {
    opacity: 0.5 !important;
    cursor: not-allowed !important;
  }

  body.binance & {
    background: $b-btn-bg;
    box-shadow: $b-btn-shadow;
    color: $b-dark-font-color;

    &:hover {
      background: $b-btn-bg-hover;
      box-shadow: $b-btn-shadow-hover;
    }

    &:disabled {
      background: $b-btn-bg-disabled;
      box-shadow: $b-btn-shadow-disabled;
      color: $b-btn-color-disabled !important;
    }

    &:active {
      background: $b-btn-bg-active;
      box-shadow: $b-btn-shadow-active;
    }

    &.cancel {
      background: $b-btn-bg-disabled;
      box-shadow: $b-btn-shadow-disabled;
      color: $b-btn-color-disabled;
    }
  }

  body.huobi & {
    background: $h-btn-bg;
    box-shadow: $h-btn-shadow;
    color: $h-btn-color;

    &:hover {
      background: $h-btn-bg-hover;
      box-shadow: $h-btn-shadow-hover;
    }

    &:disabled {
      background: $h-btn-bg-disabled;
      box-shadow: $h-btn-shadow-disabled;
      color: $h-btn-color-disabled;
    }

    &:active {
      background: $h-btn-bg-active;
      box-shadow: $h-btn-shadow-active;
      color: $h-main-font-color;
    }
  }

  &--round {
    padding: 1.35em 1.55em;

    body.huobi & {
      background: $h-btn-round-bg;
      box-shadow: $h-btn-round-shadow;
      color: $h-main-font-color;

      &:hover {
        opacity: 0.7;
        background: $h-btn-round-bg;
        box-shadow: $h-btn-round-shadow;
      }

      &:active {
        background: $h-btn-bg-active;
        box-shadow: $h-btn-shadow-active;
        color: $h-main-font-color;
      }
    }
  }

  &--loading {
  }

  &--reload {
    padding: 1em;
    margin-left: 1em;
  }

  &--header {
    box-shadow: none;
    padding: 0;
    font-size: inherit;
  }

  &.nft-link {
    background: #fb3a90;
    box-shadow: 0 1.5rem 7rem -1.7rem #fb3a90;
    border-radius: 4.6rem;
    color: white;
  }

  &.no-shadow{
    box-shadow: none;
  }
  &-solana{
    display: flex;
    align-items: center !important;
    justify-content: center;
    line-height: normal;
    background: $s-blue;
    font-weight: 500;
    font-size: 1.6rem;
    letter-spacing: 0.01em;
    color: $s-text-color !important;
    padding: 0 3.6rem 0 3.6rem;
    height: 5.2rem;
    text-transform: none;
    &:hover{
      background: $s-blue-hover;
      color: $s-text-color !important;
    }
    &:disabled{
      opacity: 1 !important;
      background: #5A57F7;
    }
    &-pink{
      background: #DC1FFF;
      border-color: #DC1FFF;
      color: #FFFFFF !important;
      &:hover{
        background: #DC1FFF;
        color: #FFFFFF !important;
      }
      &:disabled, &.disabled{
        background: #254546;
        pointer-events: none;
      }
    }
    &-orange{
      background: $s-orange;
      color: $s-text-color !important;
      &:hover{
        background: $s-orange-hover;
        color: $s-text-color !important;
      }
    }
    &-dark{
      background: #131236;
      color: $s-text-color !important;
      &:hover{
        background: #131236;
        color: $s-text-color !important;
      }
    }
  }
}
</style>
