<template>
  <div class="milk-container">
    <div class="main-wrap main-wrap--narrow">
      <div class="milk-container__row">
        <div class="milk-container__col">
          <a href="https://coinmarketcap.com/currencies/spaceswap/" target="_blank">
            <img src="@/assets/img/landing/milk2-token.svg"/>
          </a>
          <span>65,200,000 max supply</span>
          <span>Governance token</span>
          <span>Can be farmed by users</span>
          <span>Ethereum, BSC, Avalanche</span>
          <span>Burned with every SHAKE minted</span>
          <span>Stake to get allocations for SpaceSwap Starter launchpad</span>
        </div>
        <div class="milk-container__col">
          <a href="https://coinmarketcap.com/currencies/shake/" target="_blank">
            <img src="@/assets/img/landing/shake-token.svg"/>
          </a>
          <span>5,000 max supply</span>
          <span>Extra profit tool</span>
          <span>Can be minted by users</span>
          <span>Ethereum, BSC, Avalanche</span>
          <span>Initially rare</span>
          <span>Multiplies profits you are getting with MILK2</span>
        </div>
      </div>

    </div>
    <img
      src="@/assets/img/new-year/milk-container.svg"
      class="new-year"
      alt="new-year"
    >
  </div>
</template>

<script>
export default {
  name: 'MilkContainer',
};
</script>

<style scoped lang="scss">
.milk-container {
  background: url("../../assets/img/landing/milk-block.svg") no-repeat;
  background-size: 100vw;
  background-position: right;
  width: 100%;
  height: 180rem;

  &__row {
    display: flex;
    justify-content: center;

    img {
      margin-top: 60rem;
      height: 9rem;
      margin-bottom: 5.5rem;
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    font-size: 2.2rem;
    align-items: center;
    line-height: 140%;
    text-align: center;
    max-width: 48rem;
    margin: 0 14rem;

    span {
      margin-bottom: 3.2rem;
      margin-top: 2rem;
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .milk-container {
    height: auto;
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .milk-container__row {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      margin-top: 5rem;
    }
  }

  .milk-container__col {
    margin: 0;

  }
}
</style>
