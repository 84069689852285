export default function deepFindInObject(obj, path) {
  const paths = path.split('.');
  let current = obj;

  for (let i = 0; i < paths.length; i += 1) {
    if (current[paths[i]] !== undefined) {
      current = current[paths[i]];
    }
  }
  return current;
}
