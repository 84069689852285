import erc20ABI from '@/abi/erc20/erc20.json';
import { addNotification, clearNotification } from '@/utils/notification';

const Erc20 = {
  install(Vue, { store }) {
    const web3 = Vue.prototype.$web3;
    const BN = Vue.prototype.$BN;
    const createContract = (tokenAddress) => new web3.eth.Contract(erc20ABI, tokenAddress);
    const getTokenBalance = (tokenAddress) => {
      if (tokenAddress !== '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee') {
        const contract = createContract(tokenAddress);
        return contract.methods.balanceOf(store.state.User.ethAddress).call();
      }
      return web3.eth.getBalance(store.state.User.ethAddress);
    };
    const getTokenSymbol = (tokenAddress) => {
      const contract = createContract(tokenAddress);
      return contract.methods.symbol().call();
    };
    const getTokenNameERC20ABI = (tokenAddress) => {
      const contract = createContract(tokenAddress);
      return contract.methods.name().call();
    };
    const getTokenAllowance = (tokenAddress, contractAddress) => {
      const contract = createContract(tokenAddress);
      return contract
        .methods
        .allowance(store.state.User.ethAddress, contractAddress)
        .call();
    };
    const approveToken = (tokenAddress, contractAddress) => {
      const contract = createContract(tokenAddress);
      return contract
        .methods
        .approve(contractAddress, BN(2).pow(256).minus(1).toString())
        .send({ from: store.state.User.ethAddress })
        .once('transactionHash', (hash) => {
          addNotification({
            type: 'info',
            title: 'Pending approve:',
            data: { hash },
          }, { root: true });
        }).once('confirmation', () => {
          addNotification({
            type: 'success',
            title: 'Successful approve!',
            config: { duration: 5000 },
          }, { root: true });
        })
        .once('error', () => {
          addNotification({
            type: 'warn',
            title: 'Approve failed',
            config: { duration: 5000 },
          }, { root: true });
        })
        .finally(() => {
          clearNotification('info');
        });
    };
    const getTotalSupply = (tokenAddress) => {
      const contract = createContract(tokenAddress);
      return contract.methods.totalSupply().call();
    };
    // install
    Vue.prototype.$erc20 = (() => ({
      getTokenBalance,
      getTokenSymbol,
      getTokenNameERC20ABI,
      getTokenAllowance,
      approveToken,
      getTotalSupply,
    }))();
  },
};

export default Erc20;
