import Vue from 'vue';
import store from '@/store';

class CalculatePools {
  constructor({
    _pool, type,
  }) {
    this.pool = {
      type,
      approve: () => {
      },
      ..._pool,
    };
    this.ethAddress = store.state.User.ethAddress;
    this.milkPrice = store.state.Milk.price;
  }

  async addNft(nft_contract = 'nft') {
    try {
      const master = Vue.$contracts.getContract(nft_contract);
      this.pool.pendingMilk = await master.methods.pendingMilk(
        this.pool.pid, this.ethAddress,
      ).call();
      this.pool.pendingMilk = Vue.$contracts.fromWei(this.pool.pendingMilk);
      this.pool.lockedBalance = (
        await master.methods.userInfo(this.pool.pid, this.ethAddress).call()
      ).amount;
      this.pool.lockedBalance = Vue.$BN(this.pool.lockedBalance);
    } catch (e) {
      console.error(e);
    }
  }

  async addNftProvider(nft_contract = 'nft', nft_provider = 'nftprovider') {
    const nft = Vue.$contracts.getContract(nft_provider);
    this.pool.tokenBalance = await nft.methods.balanceOf(
      this.ethAddress, this.pool.nftid,
    ).call();
    this.pool.tokenBalance = Vue.$BN(this.pool.tokenBalance).toFixed(0);
    this.pool.allowance = await nft.methods.isApprovedForAll(
      this.ethAddress, Vue.$contracts.getAddress(nft_contract),
    ).call();
    this.pool.allowance = this.pool.allowance ? Vue.$BN(2).pow(256) : Vue.$BN(0);
    this.pool.approve = () => nft.methods.setApprovalForAll(
      Vue.$contracts.getAddress(nft_contract), true,
    ).send({ from: this.ethAddress });
  }

  async get() {
    if (this.pool.type === 'nft') {
      await this.addNft(this.pool.nft, this.pool.nft_provider);
      await this.addNftProvider(this.pool.nft, this.pool.nft_provider);
    }
    return this.pool;
  }
}

export default async (_pool, type) => {
  const pool = new CalculatePools({
    _pool, type,
  });
  return pool.get();
};
