import Vue from 'vue';
import { getPriceInWETH } from '@/utils/getPrice';

const state = {
  loading: false,
  balance: null,
  totalSupply: null,
  availableTokens: null,
  price: null,
};

const actions = {
  update: async (context) => {
    context.commit('loading', true);
    const shakeToken = Vue.$contracts.getAddress('shake');
    const shakeLP = Vue.$contracts.getAddress('shakelp');
    const shake = Vue.$contracts.getContract('shake');

    const shakeBalance = await shake.methods.balanceOf(context.rootState.User.ethAddress).call();
    const maxSupply = Vue.$contracts.toWei(10000);
    const totalSupply = await shake.methods.totalSupply().call();
    const availableTokens = Vue.$contracts.fromWei(maxSupply)
      .minus(Vue.$contracts.fromWei(totalSupply));
    context.commit('setBalance', Vue.$contracts.fromWei(shakeBalance));
    context.commit('setTotalSupply', Vue.$contracts.fromWei(totalSupply));
    context.commit('setAvailableTokens', availableTokens);

    if (typeof shakeLP === 'string') {
      const price = await getPriceInWETH(shakeLP, shakeToken);
      context.commit('setPrice', price);
    }
    context.commit('loading', false);
  },
};

const mutations = {
  loading: (state, status) => {
    state.isLoading = !!status;
  },
  setBalance: (state, _balance) => {
    state.balance = _balance;
  },
  setTotalSupply: (state, _totalSupply) => {
    state.totalSupply = _totalSupply;
  },
  setAvailableTokens: (state, _availableTokens) => {
    state.availableTokens = _availableTokens;
  },
  setPrice: (state, _price) => {
    state.price = _price;
  },
};

export default {
  state,
  actions,
  mutations,
};
