import { getPriceInWETH } from '@/utils/getPrice';
import Vue from 'vue';

const state = {
  loading: false,
  milkBalance: null,
  totalSupply: null,
  price: null,
};

const actions = {
  update: async (context) => {
    context.commit('loading', true);
    const milk = Vue.$contracts.getContract('milk2');
    const milkBalance = await milk.methods.balanceOf(context.rootState.User.ethAddress).call();
    const totalSupply = await milk.methods.totalSupply().call();
    context.commit('setMilkBalance', Vue.$contracts.fromWei(milkBalance));
    context.commit('setTotalSupply', Vue.$contracts.fromWei(totalSupply));
    const milkLp = Vue.$contracts.getAddress('milk2Lp');
    if (typeof milkLp === 'string') {
      const price = await getPriceInWETH(
        milkLp,
        Vue.$contracts.getAddress('milk2'),
      );
      context.commit('setPrice', price);
    }
    context.commit('loading', false);
  },
};

const mutations = {
  loading: (state, status) => {
    state.isLoading = !!status;
  },
  setMilkBalance: (state, _milkBalance) => {
    state.milkBalance = _milkBalance;
  },
  setTotalSupply: (state, _totalSupply) => {
    state.totalSupply = _totalSupply;
  },
  setPrice: (state, _price) => {
    state.price = _price;
  },
};

export default {
  state,
  actions,
  mutations,
};
