<script>
import Button from '@/components/Button';
import { mapState } from 'vuex';

export default {
  name: 'RegisterButton',
  components: { Button },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapState({
      sign: (state) => state.User.sign,
      ethAddress: (state) => state.User.ethAddress,
    }),
  },
  async created() {
    this.loading = Number(this.lastBlock) > 0 && !this.sign;
  },
  methods: {
    async register() {
      this.loading = true;
      const shadowContract = this.$contracts.getInstance(this.$contracts.getAddress('shadow'), 'shadow');
      try {
        await shadowContract.methods.registration()
          .send({ from: this.ethAddress });
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },
  },
};
</script>

<template>
  <Button :disabled="loading"
          @click="register"
          class="interstellar_start_farming"
  >
    <img
      src="@/assets/img/new-year/farming-left.svg"
      class="new-year new-year__left"
      alt="new-year"
    >
    <img
      src="@/assets/img/new-year/farming-right.svg"
      class="new-year new-year__right"
      alt="new-year"
    >
    {{ $t('shadow.participate') }}
  </Button>
</template>

<style lang="scss">
</style>
