import Vue from 'vue';
import web3 from 'web3';

let gameContract = null;
function gameMethod(methodName, param = null) {
  if (!gameContract) {
    gameContract = Vue.$contracts.getContract('clickGame');
  }
  if (param) {
    return gameContract.methods[methodName](param);
  }

  return gameContract.methods[methodName]();
}

export const state = {
  userBalance: 0,
  contractBalance: 0,
  allowance: false,
  userInfo: null,
};

export const getters = {
  userBalance: (s) => s.userBalance,
  contractBalance: (s) => s.contractBalance,
  allowance: (s) => s.allowance,
  userInfo: (s) => s.userInfo,
};

export const actions = {
  async getContractBalance({ commit }) {
    try {
      const contractBalance = await gameMethod('balanceOfContract').call();
      commit('setContractBalance', contractBalance);
    } catch (e) {
      console.error(e);
    }
  },

  async getUserBalance({ commit }) {
    try {
      if (!this.state.User.ethAddress) return;
      const userBalance = await gameMethod('balanceOf', this.state.User.ethAddress).call();
      commit('setUserBalance', userBalance);
    } catch (e) {
      console.error(e);
    }
  },

  async checkApprove({ commit }) {
    try {
      if (!this.state.User.ethAddress) return;
      const result = await gameMethod('allowance', this.state.User.ethAddress).call();
      commit('setAllowance', result);
    } catch (e) {
      console.error(e);
    }
  },

  async getUserInfo({ commit }) {
    try {
      if (!this.state.User.ethAddress) return;
      const result = await gameMethod('getUser', this.state.User.ethAddress).call();

      commit('setUserInfo', {
        win: result[0],
        approve: result[1],
        prize: result[2],
      });
    } catch (e) {
      console.error(e);
    }
  },

  async approve() {
    try {
      const result = await gameMethod('approve').send({ from: this.state.User.ethAddress });
      return result;
    } catch (e) {
      return e?.message ?? false;
    }
  },

  async play() {
    try {
      const address = this.state.User.ethAddress;
      const hash = web3.utils.keccak256(address);
      const result = await gameMethod('play', hash).send({ from: address });
      return result;
    } catch (e) {
      console.error(e);
      return e?.message ?? false;
    }
  },
};

export const mutations = {
  setUserBalance: (state, payload) => { state.userBalance = payload; },
  setContractBalance: (state, payload) => { state.contractBalance = payload; },
  setAllowance: (state, payload) => { state.allowance = payload; },
  setUserInfo: (state, payload) => { state.userInfo = payload; },
};
