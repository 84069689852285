<script>
export default {
  name: 'NotificationsWraper',
};
</script>

<template>
  <div>
    <notifications
      group="info"
      :duration="-1"
      :speed="300">
      <template slot="body" slot-scope="props">
          <div class="notification">
            <a class="close" @click="props.close"></a>
            <h3 v-if="props.item.title">{{ props.item.title }}</h3>
            <div class="block" v-if="props.item.text">
              <p>{{ props.item.text }}</p>
            </div>
            <a v-if="props.item.data && props.item.data.hash"
              target="_blank"
              :href="$chain.getExplorerLink(props.item.data.hash)"
              class="link">{{ props.item.data.hash }}</a>
          </div>
      </template>
    </notifications>
    <notifications
      group="success"
      :speed="300">
      <template slot="body" slot-scope="props">
          <div class="notification successfull">
            <a class="close" @click="props.close"></a>
            <div class="notification__icon">
              <svg height="15px" viewBox="0 -46 417.81333 417" width="15px" xmlns="http://www.w3.org/2000/svg"><path d="m159.988281 318.582031c-3.988281 4.011719-9.429687 6.25-15.082031 6.25s-11.09375-2.238281-15.082031-6.25l-120.449219-120.46875c-12.5-12.5-12.5-32.769531 0-45.246093l15.082031-15.085938c12.503907-12.5 32.75-12.5 45.25 0l75.199219 75.203125 203.199219-203.203125c12.503906-12.5 32.769531-12.5 45.25 0l15.082031 15.085938c12.5 12.5 12.5 32.765624 0 45.246093zm0 0" fill="#0ed7a7"/></svg>
            </div>
            <h3 v-if="props.item.title">
              {{ props.item.title }}
            </h3>
            <div class="block" v-if="props.item.text">
              <p>{{ props.item.text }}</p>
            </div>
            <a v-if="props.item.data && props.item.data.hash"
              target="_blank"
              :href="`https://etherscan.io/tx/${props.item.data.hash}`"
              class="link">{{ props.item.data.hash }}</a>
          </div>
      </template>
    </notifications>
    <notifications
      group="warn"
      :speed="300">
      <template slot="body" slot-scope="props">
          <div class="notification warning">
            <a class="close" @click="props.close"></a>
            <div class="notification__icon">
              <svg height="15px" viewBox="0 0 365.71733 365" width="15px" xmlns="http://www.w3.org/2000/svg"><g fill="#f44336"><path d="m356.339844 296.347656-286.613282-286.613281c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503906-12.5 32.769532 0 45.25l286.613281 286.613282c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082032c12.523438-12.480468 12.523438-32.75.019532-45.25zm0 0"/><path d="m295.988281 9.734375-286.613281 286.613281c-12.5 12.5-12.5 32.769532 0 45.25l15.082031 15.082032c12.503907 12.5 32.769531 12.5 45.25 0l286.632813-286.59375c12.503906-12.5 12.503906-32.765626 0-45.246094l-15.082032-15.082032c-12.5-12.523437-32.765624-12.523437-45.269531-.023437zm0 0"/></g></svg>
            </div>
            <h3 v-if="props.item.title">
              {{ props.item.title }}
            </h3>
            <div class="block" v-if="props.item.text">
              <p>{{ props.item.text }}</p>
            </div>
            <a v-if="props.item.data && props.item.data.hash"
              target="_blank"
              :href="`https://etherscan.io/tx/${props.item.data.hash}`"
              class="link">{{ props.item.data.hash }}</a>
          </div>
      </template>
    </notifications>
  </div>
</template>

<style lang="scss">
  #app.scrolled .vue-notification-group {
    top: 1rem !important;
  }

  .vue-notification-group {
    top: 7rem !important;
    right: 1rem !important;
    z-index: 100;
    position: fixed;

    @media (max-width: $breakpoint-sm) {
      top: 1rem !important;
    }
  }
  .vue-notification-wrapper {
    margin-top: 15px;
    opacity: 0.8;
    background: $modal-bg;
    border-radius: 2.8rem;
    box-shadow: $main-item-shadow;

    body.binance & {
      background: $b-modal-bg;
      box-shadow: $b-modal-shadow;
    }
  }
  .notification {
    position: relative;
    padding: 2rem 4rem;
    color: $main-font-color;
    border-radius: 2.8rem;

    &.successfull {
      background: $menu-green-bg;

      .close {
        &:after,
        &:before {
          background-color: $main-green;
        }
      }
    }
    &.warning {
      background: $menu-pink-bg;

      .close {
        &:after,
        &:before {
          background-color: $main-pink;
        }
      }
    }

    body.binance & {
      color: $b-main-font-color;

      &.successfull {
        color: $b-main-green;
        background: transparent;

        .close {
          &:after,
          &:before {
            background-color: $b-main-green;;
          }
        }
      }

      &.warning {
        color: $b-main-pink !important;
        background: transparent !important;

        .close {
          &:after,
          &:before {
            background-color: $b-main-pink;
          }
        }
      }
    }

    .notification__icon {
      position: absolute;
      left: 1.4rem;
      top: calc(50% - 10px);
    }

    .close {
      position: absolute;
      right: 1rem;
      top: calc(50% - 3px);
      cursor: pointer;
      text-decoration: none;
      color: $grey-font-color;
      font-size: 2.2rem;

      body.binance & {
        color: $b-secondatry-font-color;
      }

      &:after,
      &:before {
        position: absolute;
        content: '';
        display: block;
        width: 20px;
        height: 2px;
        top: 0;
        right: 0;
        background-color: $main-font-color;

        body.binance & {
          background-color: $b-main-font-color;
        }
      }
      &:after {
        transform: rotate(45deg);
      }
      &:before {
        transform: rotate(-45deg);
      }
    }

    p {
      margin-top: 5px;
    }
    h3 {
      display: flex;
      align-items: center;
      margin: 0;
      font-size: 1.5rem;

      svg {
        margin-right: 5px;
      }
    }
    .block {
      display: flex;
      justify-content: space-between;
    }
    .link {
      display: block;
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 1.2rem;
    }
  }
</style>
