<template>
  <div class="divider">
    <img src="../../assets/img/landing/divider.svg"/>
  </div>
</template>

<script>
export default {
  name: 'Divider',
};
</script>

<style scoped lang="scss">
  .divider {
    display: flex;
    justify-content: center;
    img {
      height: 4rem;
    }
  }
</style>
