<template>
  <div class="main-wrap main-wrap--narrow landing-video">
    <div class="landing-container">
      <div class="landing-video--text">
        <span>
        Starting out as a classic, <strong>Ethereum-based</strong>, yield-farming service
        in <strong>September 2020</strong>,
          SpaceSwap raised itself to a whole new level when it invented
          <strong>
            <a href="https://docs.spaceswap.app/spaceswap/spaceswap-intro/shadow-staking-farming-technology"
               target="_blank">Shadow Staking technology</a>
          </strong>
          - a revolutionary solution that enables users to farm using LPs that stay in their wallet,
        with one time contract interaction.
        </span>
      </div>
    </div>
    <div class="landing-container landing-video__video">
      <div class="landing-video__controls">
        <div class="landing-video__video--preview">
          <div class="landing-video__video--play" v-if="!open" @click="open = true"></div>
          <iframe
            v-else
            width="100%" height="100%" src="https://www.youtube.com/embed/Lm9aRWY29do"
            title="YouTube video player"
            frameborder="0"
            autoplay
            allow="accelerometer; autoplay;
                  clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          >
          </iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandingVideo',
  data() {
    return {
      open: false,
    };
  },
};
</script>

<style scoped lang="scss">
.landing-video {
  &--text {
    font-style: normal;
    font-weight: 600;
    font-size: 2.4rem;
    text-align: center;

    strong {
      color: #FF8B8B;
      font-weight: 600;
    }
  }

  &__video {
    margin-top: 3.4rem;

    &--preview {
      height: 51.5rem;
      width: 100%;
      background: url("../../assets/img/landing/video-preview.png") no-repeat;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      background-position: center center;
    }

    &--play {
      background: url("../../assets/img/landing/play-button.svg") no-repeat;
      background-size: contain;
      width: 14.2rem;
      height: 14.2rem;
      background-position: center center;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.landing-video__controls {
  border-radius: 3rem;
  overflow: hidden;
  box-shadow: inset -2px -2px 2px rgba(182, 169, 173, 0.2),
  inset 1px 1px 4px rgba(182, 169, 173, 0.2), inset -2px -2px 4px rgba(255, 255, 255, 0.9),
  inset 1px 1px 2px rgba(182, 169, 173, 0.2);
  filter: drop-shadow(5px 5px 10px rgba(255, 255, 255, 0.9))
  drop-shadow(-1px -1px 5px rgba(182, 169, 173, 0.3));
}
</style>
