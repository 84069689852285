/* eslint-disable */

export const locales = [
  'en',
  'ch',
  'ko',
  'ja',
  'ru',
];

export const localeCounntryList = {
  cn: locales[1],
};
