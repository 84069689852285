const mainnet = {
  // uniswap
  uniFactory: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
  uniRouter: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  // tokens
  weth: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  eth: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  zero: '0x0000000000000000000000000000000000000000',
  // spaceSwap tokens
  SSI: '0xcf452fa941a965f4bb245e6dbb8f599d40749880',
  milk: '0x66D1B01c0Fd7c2D8718F0997494b53Ff5C485688',
  interstellar: '0xb95ebbF2a9fC64e4dc4d6951a60bC4d3c8F55b9D',
  milk2: '0x80c8c3dcfb854f9542567c8dac3f44d709ebc1de',
  blackHole: '0x4c3f2bcbd7b6dad6095ce8f8a3c23aff691a2b23',
  gravity: '0xe6c87b72269dd9199639a6358a2cefb7caf89a0e',
  exchange: '0x58a9CeFfE63c5Ce63b2cf3AF2c11C07037cF8C96',
  galaxy: '0xb780035440dba56f6cd7d2d522ee9f94f0d9f752',
  shake: '0x6006FC2a849fEdABa8330ce36F5133DE01F96189',
  blender: '0x4bf373Cd174b149E2264aAaC080099c1a33B7B3d',
  nft: '0xc0a70f78e6585677ef6bbd0c9fcfa9a85f4ae81b',
  new_nft: '0x6Db849CfB0423D28D2b615dFeb4Ba8A739Bf1De3',
  nftprovider: '0x77ec5a5ecf2d3942d45cb059fa1c86a262ec855b',
  shadow: '0x20be3e5a0e37136901d91687f6f8faabad698ae1',
  babyMilk: '0xe00edf07bbab7f9e7a93ffbffdd4c16c5dbc6b03',
  air: '0xD1c60e41d0E6041E36AE720c455A50Cb083F5477',
  // spaceSwap LP
  milkLp: '0x3bB34bD8fDE91a4F4919f4d2B828AADf89be31Ec',
  milk2Lp: '0xee802b312a957e00dd7bbc08eec8bb93d40e981d',
  shakelp: '0x8f30ba1F75ec27A014591789d117a1Bb4703bFF8',
  // contracts
  SSIBasicIssuanceModule: '0x7ec2b2568fbfdf942db007c8646688b818a43d95',
  // index token pairs
  SSIPairs: [
    // usdt - index
    ['0xdac17f958d2ee523a2206206994597c13d831ec7', '0xcf452fa941a965f4bb245e6dbb8f599d40749880'],
  ],
  booster: {
    1: '0x81dcf4Acef7AeC9C770e1Ff6EEdAA207cF6B33Bd',
    2: '0xfaf56e5bf593b476dedc689fb057fc4e92478dab',
    3: '0x7fb40e4c123aa5dfcd222e7374f6c5427c001bc8',
    4: '0x6c4762ff9f2fd0e16cdf17cb86fe86ee7c395032',
    5: '0x583d4d39b740e2582febab65a9437e46692f932a',
    6: '0xb1ab3c48f15fa4aa37893bc748e6e837fe079367',
  },
  // launchpad
  launchpadStaking: '0x62E64160B2CA97b6B0F3Fd4C979aae45D291C6fD',
  launchpadSale: '0x1CfC210905c914e66bB5C8c586a6B9a77fEE85a0',
  launchpadVesting: '0x70e423911bDf6eB7a9d6D5fEE584Fb2D58fAfA74',
  DAI: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
  sTAUR: '0x5D49d929173c5c3E2add6E0416C102E4d31CafF5',
  sidusPresale: '0x721C299E6BF7D6a430d9bEA3364Ea197314bcE09',
  shadowBridge: '0x80bbaeEE801b22a0BC5aF5B90b3848619bE3B347',
};

export default mainnet;
