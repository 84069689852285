<script>
import Header from '@/components/Header/Header';
import Footer from '@/components/Footer/Footer';
import NotificationsWraper from '@/components/NotificationsWraper';
import SLayout from '@/components/Solana/SLayout';
import { mapState } from 'vuex';
import SimpleModal from '@/components/Modals/SimpleModal';
import MetaFox from '@/components/MetaFox';
import Landing from './views/landing';
// import Coins from './components/Coins';

export default {
  name: 'App',
  components: {
    // Coins,
    Landing,
    SLayout,
    Header,
    Footer,
    NotificationsWraper,
    SimpleModal,
    MetaFox,
  },
  data() {
    return {
      initPoolsInterval: null,
      clientWidth: 0,
      currentEventBlock: 0,
      scrollPosition: 0,
      loaded: false,
      metamaskDlModal: false,
    };
  },
  computed: {
    ...mapState({
      ethAddress: (state) => state.User.ethAddress,
      pools: (state) => state.Shadow.pools,
      poolsMap: (state) => state.Shadow.private.poolsMap,
      poolsHistoryMap: (state) => state.Shadow.private.poolsHistoryMap,
      totalWeight: (state) => state.Shadow.totalWeight,
    }),
    hasMetamask() {
      return window.ethereum !== undefined;
    },
    layout() {
      return this.$route.meta.layout || 'Default';
    },
  },
  watch: {
    currentEventBlock() {
      this.$root.$emit('newBlock');
    },
  },
  created() {
    if (this.$web3) {
      window.ethereum.autoRefreshOnNetworkChange = false;
      this.$root.$on('connect', this.connect);
      this.init();
      this.subscription = this.$web3.eth
        .subscribe('newBlockHeaders')
        .on('data', this.newBlockHandler);
      window.addEventListener('scroll', this.updateScroll);
    } else {
      this.$root.$on('connect', () => {
        this.metamaskDlModal = !this.hasMetamask;
      });
    }
  },
  beforeDestroy() {
    this.subscription.unsubscribe();
    this.$root.$off('connect', this.connect);
  },
  methods: {
    changeSimpleModal() {
      this.metamaskDlModal = !this.metamaskDlModal;
    },
    newBlockHandler(event, error) {
      if (!error) {
        if (!this.currentEventBlock) {
          this.currentEventBlock = event.number;
        }
        if (this.$BN(event.number).minus(this.currentEventBlock).gt(3)) {
          this.currentEventBlock = event.number;
          this.initPools();
        }
      } else {
        console.error(error);
      }
    },
    async connect() {
      try {
        await window.ethereum.enable();
        await this.init();
      } catch (e) {
        console.error(e);
      }
    },
    async init() {
      try {
        await this.getAccount();
        if (this.ethAddress) {
          await this.getChainId();
          this.loaded = true;
          this.initPools();
          this.subscribeProvider(this.$web3.currentProvider);
        } else {
          await this.$store.dispatch('Price/update');
        }
      } catch (e) {
        setTimeout(this.init, 500);
      }
    },
    initPools() {
      // check if pools loading
      if (!this.$store.state.User.loading && this.$store.state.User.sign !== 'notregistered') {
        this.$store.dispatch('Shadow/fetchAllPools');
        this.$store.dispatch('User/initPools');
      } else {
        // if loading setTimeout for recheck
        setTimeout(this.initPools, 500);
      }
    },
    async getChainId() {
      const chainId = await this.$web3.eth.getChainId();
      const chains = [4, 3, 1, 56, 97, 128, 256, 43113, 43114];
      if (chains.includes(chainId)) {
        await this.$store.commit('User/SET_CHAIN_ID_AND_NAME', chainId);
        document.body.classList = [];
        if (this.$chain.getName()) {
          // const chName = chainId === 1 ? 'avalanche' : this.$chain.getName();
          document.body.classList.add(this.$chain.getName());
        }
      }
    },
    async getAccount() {
      const accounts = await this.$web3.eth.getAccounts();
      if (accounts.length) {
        this.$store.commit('User/SET_AUTH_STATUS', true);
        this.$store.commit('User/SET_ETH_ADDRESS', accounts[0]);
      } else {
        this.$store.commit('User/SET_AUTH_STATUS', false);
        this.$store.commit('User/SET_ETH_ADDRESS', null);
      }
    },
    subscribeProvider(provider) {
      provider.on('accountsChanged', async () => {
        try {
          await this.getAccount();
          if (this.ethAddress) {
            this.initPools();
          }
        } catch (e) {
          console.error(e);
        }
      });
      provider.on('chainChanged', async () => {
        this.$store.commit('Shadow/RESET_POOLS');
        this.$store.commit('Comp/resetVaults');
        await this.getChainId();
        this.initPools();
      });
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
};
</script>

<template>
  <v-app
    id="app"
    style="background: transparent; font-family: inherit; color: inherit"
    :class="scrollPosition > 100 ? 'scrolled' : ''"
    v-if="layout === 'Default'"
  >
    <Header v-if="!(!$store.state.User.ethAddress && !$route.meta.withoutAuth)"/>
    <main>
      <Landing v-if="((!$store.state.User.ethAddress && !$route.meta.withoutAuth)) &&
      $route.name !== 'disclaimer'"
      />
      <router-view v-else-if="loaded || $route.meta.withoutAuth" />
<!--      <Coins/>-->
    </main>
    <Footer :isLanding="(!$store.state.User.ethAddress && !$route.meta.withoutAuth)" />
    <NotificationsWraper />
    <SimpleModal v-if="!hasMetamask && metamaskDlModal" @destroy="changeSimpleModal">
      <MetaFox width="100px" />
      <h2 style="margin-bottom: 2rem;">To continue using the website, please, connect MetaMask</h2>
      <a href="https://metamask.app.link/dapp/spaceswap.app" target="_blank" class="btn">Connect</a>
    </SimpleModal>
  </v-app>

  <s-layout v-else :light="layout === 'CortanaLayout'">
    <router-view />
  </s-layout>
</template>

<style lang="scss">
#meta-fox {
  margin: auto;

  svg {
    width: inherit;
    height: auto;
  }
}
</style>
