import Vue from 'vue';
import { addNotification, clearAllNotifications } from '@/utils/notification';
import i18n from '@/i18n';

const state = {
  loading: false,
};

const actions = {
  async approve({ rootState }, { contract, spender, amount }) {
    try {
      const spenderAddress = Vue.$contracts.getAddress(spender);
      const Approve = Vue.$contracts.getContract(contract).methods.approve;
      const gasPrice = await this.dispatch('Bridge/getGasPrice');
      return await Approve(spenderAddress, Vue.$BN(amount).toString()).send({
        from: rootState.User.ethAddress,
        gasPrice,
      });
    } catch (e) {
      console.log(e);
      return null;
    }
  },

  async allowance({ rootState }, { contract, spender }) {
    try {
      const spenderAddress = Vue.$contracts.getAddress(spender);
      const Allowance = Vue.$contracts.getContract(contract).methods.allowance;

      const allowance = await Allowance(rootState.User.ethAddress, spenderAddress).call();
      return Number(allowance);
    } catch (e) {
      console.log(e);
      return 0;
    }
  },

  deposit: async (context, {
    pid,
    amount,
    type = '',
    text = '',
    callback,
    provider,
  }) => {
    try {
      let tmpHash = '';
      const contract = Vue.$contracts.getContract(provider);
      context.commit('loading', true);
      const method = provider === 'nft' || provider === 'new_nft' ? 'depositFarmingToken' : 'deposit';
      const amountToSend = provider === 'nft' || provider === 'new_nft'
        ? Vue.$contracts.fromWei(amount).toString(10)
        : amount.toString(10);
      await contract.methods[method](pid, amountToSend)
        .send({ from: context.rootState.User.ethAddress })
        .on('transactionHash', (hash) => {
          tmpHash = hash;
          clearAllNotifications();
          addNotification({
            title: i18n.t('notifications.pending', { type }),
            text,
            data: { hash: tmpHash },
          });
          if (callback) {
            callback();
          }
        });
      clearAllNotifications();
      addNotification({
        type: 'success',
        title: i18n.t('notifications.success', { type }),
        text,
        data: { hash: tmpHash },
      });
      context.dispatch('Pools/update', 'nft', { root: true });
    } catch (error) {
      clearAllNotifications();
      addNotification({
        type: 'warn',
        title: i18n.t('notifications.failed', { type }),
        text,
      });
      console.error(error);
    } finally {
      context.commit('loading', false);
    }
  },
  withdraw: async (context, {
    pid,
    amount,
    text,
    callback,
    provider,
  }) => {
    try {
      let tmpHash = '';
      const contract = Vue.$contracts.getContract(provider);
      context.commit('loading', true);
      const method = provider === 'nft' || provider === 'new_nft' ? 'withdrawFarmingToken' : 'withdraw';
      const amountToSend = provider === 'nft' || provider === 'new_nft'
        ? Vue.$contracts.fromWei(amount).toString(10)
        : amount.toString(10);
      await contract.methods[method](pid, amountToSend)
        .send({ from: context.rootState.User.ethAddress })
        .on('transactionHash', (hash) => {
          tmpHash = hash;
          clearAllNotifications();
          addNotification({
            title: i18n.t('notifications.pending_unstake'),
            text,
            data: { hash: tmpHash },
          });
          if (callback) {
            callback();
          }
        });
      clearAllNotifications();
      addNotification({
        type: 'success',
        title: i18n.t('notifications.success_unstaked'),
        text,
        data: { hash: tmpHash },
      });
      context.dispatch('Pools/update', 'nft', { root: true });
    } catch (error) {
      clearAllNotifications();
      addNotification({
        type: 'warn',
        title: i18n.t('notifications.failed_unstaked'),
        text,
      });
      console.error(error);
    } finally {
      context.commit('loading', false);
    }
  },
};

const mutations = {
  loading: (state, status) => {
    state.loading = !!status;
  },
};

export default {
  state,
  actions,
  mutations,
};
