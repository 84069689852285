import BigNumber from 'bignumber.js';
import { getList } from '@/helpers/api/galaxy.api';
import Vue from 'vue';

const state = {
  loading: true,
  isAddressBurned: false,
  list: [],
  isUserProduct: false,
  quoteVote: 0,
};

const actions = {
  update: async (context) => {
    context.commit('loading', true);
    const contract = Vue.$contracts.getContract('blackHole');

    try {
      const { ethAddress } = context.rootState.User;
      const status = await contract.methods.burnedTokens(ethAddress).call();
      const quote = await contract.methods.getQuoteVote().call()
        .then((amount) => new BigNumber(10).pow(-18).times(new BigNumber(amount)));
      await context.dispatch('getList');
      context.dispatch('getUserList');
      context.commit('setQuoteVote', quote);
      context.commit('setAddressBurned', status);
    } catch (err) {
      console.error(err);
    } finally {
      context.commit('loading', false);
    }
  },
  getList: async (context) => {
    context.commit('loading', true);
    const { data } = await getList();
    context.commit('setList', data);
    context.commit('loading', false);
  },
  getUserList: async (context) => {
    context.commit('loading', true);
    const { ethAddress } = context.rootState.User;
    const userProducts = state.list.filter((item) => item.address === ethAddress);
    const isUserProducts = userProducts.length > 0;
    context.commit('setUserProduct', isUserProducts);
    context.commit('loading', false);
  },
  giveOblivion: async (context, callback) => {
    context.commit('loading', true);
    const { ethAddress } = context.rootState.User;
    Vue.$contracts.getContract('blackHole').methods.giveOblivion().send({ from: ethAddress })
      .on('confirmation', () => {
        context.commit('setAddressBurned', true);
        callback();
      });
    context.commit('loading', false);
  },
};

const mutations = {
  loading: (state, status) => {
    state.loading = status;
  },
  setAddressBurned: (state, status) => {
    state.isAddressBurned = status;
  },
  setQuoteVote: (state, quote) => {
    state.quoteVote = quote;
  },
  setList: (state, list) => {
    state.list = list;
  },
  setUserProduct: (state, status) => {
    state.isUserProduct = status;
  },
};

export default {
  state,
  actions,
  mutations,
};
