<script>
import { mapState } from 'vuex';
import Jazzicon from 'vue-jazzicon';
import MyAccount from './Modals/MyAccount';

export default {
  name: 'Address',
  components: {
    MyAccount,
    Jazzicon,
  },
  computed: {
    ...mapState({
      ethAddress: (state) => state.User.ethAddress,
    }),
  },
  data() {
    return {
      showAddress: false,
    };
  },
  methods: {
    shortedAddress() {
      return `${this.ethAddress.substr(0, 6)}...${this.ethAddress.substr(38, 4)}`;
    },
  },
};
</script>

<template>
  <div>
    <span @click="showAddress = true"
          class="menu-wallet__button"
    >
      {{ shortedAddress() }}&nbsp;
      <Jazzicon  :diameter="35" :address="ethAddress"/>
    </span>
    <MyAccount :show.sync="showAddress"/>
  </div>
</template>

<style lang="scss">
  .menu-wallet__button {
    text-transform: none;
    background: $pink-bg;
    border-radius: 5rem;
    height: 4.5rem;
    display: flex;
    align-items: center;
    padding-left: 2rem;
    font-weight: bold;
    cursor: pointer;
    margin: 0 3px 0 .5rem;
    color: #FFF;
    width: fit-content;

    body.binance & {
      background: $b-wallet-bg;
      color: $b-dark-font-color;
    }

    body.huobi & {
      background: $h-wallet-bg;
      color: #FFF;
    }

    > div {
      border: 2px solid #fff;
      border-radius: 100%;
      margin: 0 2px 0 1.5rem;
      height: 4rem;
      width: 4rem;
      overflow: hidden;
    }
  }
</style>
