<template>
  <div class="core-features main-wrap main-wrap--narrow">
    <Divider/>
    <div class="landing-title">CORE FEATURES</div>

    <v-row class="core-features__container">
      <v-col md="4" sm="4" xs="6" >
        <div class="core-features__card">
          <div class="core-features__image exchange"></div>
          <span class="core-features__card--title">
            Cross-chain bridge
          </span>
        </div>
      </v-col>
      <v-col md="4" sm="4" xs="6" >
        <div class="core-features__card">
          <div class="core-features__image booster"></div>
          <span class="core-features__card--title">
            Booster
          </span>
        </div>

      </v-col>
      <v-col md="4" sm="4" xs="6" >
        <div class="core-features__card">
          <div class="core-features__image blender"></div>
          <span class="core-features__card--title">
            Blender for minting SHAKE tokens
          </span>
        </div>

      </v-col>
      <v-col md="4" sm="4" xs="6" >
        <div class="core-features__card">
          <div class="core-features__image tokens"></div>
          <span class="core-features__card--title">
            Index tokens
          </span>
        </div>

      </v-col>
      <v-col md="4" sm="4" xs="6" >
        <div class="core-features__card">
          <div class="core-features__image starter"></div>
          <span class="core-features__card--title">
            SpaceSwap Starter
          </span>
        </div>

      </v-col>
      <v-col md="4" sm="4" xs="6" >
        <div class="core-features__card">
          <div class="core-features__image solutions"></div>
          <span class="core-features__card--title">
            White label solutions
          </span>
        </div>

      </v-col>
    </v-row>
    <img
      src="@/assets/img/new-year/core-left.svg"
      class="new-year new-year__core-left"
      alt="new-year"
    >
    <img
      src="@/assets/img/new-year/core-right.svg"
      class="new-year new-year__core-right"
      alt="new-year"
    >
  </div>
</template>

<script>
import Divider from './Divider';

export default {
  name: 'CoreFeatures',
  components: { Divider },
};
</script>

<style scoped lang="scss">
  .core-features {
    margin-top: 10rem;
    svg {
      animation: none !important;
      transition: none !important;
    }

    .landing-title {
      margin-top: 3.2rem;
      margin-bottom: 6rem;
    }

    &__container {
      display: flex;
      justify-content: center;
    }
    &__card {
      display: flex;
      flex-direction: column;
      align-items: center;
      &--title {
        filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.11));
        color: #FF5A5A;
        font-weight: bold;
        font-size: 2.4rem;
        text-align: center;
        text-shadow:0px 1px 0 rgb(255,255,255),0px -1px 0 rgb(255,255,255),
        1px 0px 0 rgb(255,255,255),-1px 0px 0 rgb(255,255,255),
        1px 1px 0 rgb(255,255,255),1px -1px 0 rgb(255,255,255),
        -1px 1px 0 rgb(255,255,255),-1px -1px 0 rgb(255,255,255),
        0px 2px 0 rgb(255,255,255),0px -2px 0 rgb(255,255,255),
        2px 0px 0 rgb(255,255,255),-2px 0px 0 rgb(255,255,255),
        2px 2px 0 rgb(255,255,255),2px -2px 0 rgb(255,255,255),
        -2px 2px 0 rgb(255,255,255),-2px -2px 0 rgb(255,255,255);
      }
    }

    &__image {
      width: 13.6rem;
      height: 13.6rem !important;
      background-size: contain;
      background-position: center;
      margin-bottom: 3rem;
      margin-top: 4rem;
      cursor: pointer;

      &.exchange {
        background-image: url("../../assets/img/landing/icons/exchange.svg");
      }

      &.booster {
        background-image: url("../../assets/img/landing/icons/booster.svg");
        width: 16rem;
      }

      &.starter {
        background-image: url("../../assets/img/landing/icons/starter.svg");
      }

      &.tokens {
        background-image: url("../../assets/img/landing/icons/tokens.svg");
      }

      &.solutions {
        background-image: url("../../assets/img/landing/icons/solutions.svg");
      }

      &.blender {
        background-image: url("../../assets/img/landing/icons/blender.svg");
        width: 15rem;
        padding: 0;
      }

      &.spaceland {
        background-image: url("../../assets/img/landing/icons/spaceland.svg");
      }
    }
  }
</style>
