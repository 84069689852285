<script>
export default {
  name: 'HeaderTokenStatistic',
  props: {
    diff: {
      type: Number,
      default: 0,
    },
    price: {
      type: String,
      default: '0',
    },
    priceDiffPercent: {
      type: String,
      default: '0',
    },
    name: {
      type: String,
      default: '',
    },
  },
};
</script>

<template>
  <div
    :class="[
      'header-token-stat',
       (diff > -1) ? 'header-token-stat--green' : 'header-token-stat--red']"
 >
    <span class="header-token-stat__name">{{ name }}</span>
    <span class="header-token-stat__price">${{ price }}</span>
    <span class="header-token-stat__price-diff-direction">
      {{ (diff > -1) ? '&#9650;' : '&#9660;' }}
    </span>
    <span class="header-token-stat__price-diff-percent">{{ priceDiffPercent }}%</span>
  </div>
</template>

<style lang="scss">
.header-token-stat {
  @media screen and (max-width: $breakpoint-sm) {
    width: 50%;

    &:first-child {
      margin-right: 1rem;
    }
  }
  &--green {
    background: $menu-green-bg;
    .header-token-stat__price,
    .header-token-stat__price-diff-direction,
    .header-token-stat__price-diff-percent {
      color: $main-green;

      body.binance & {
        color: $b-main-green;
      }
    }
  }
  &--red {
    background: $menu-pink-bg;
    .header-token-stat__price,
    .header-token-stat__price-diff-direction,
    .header-token-stat__price-diff-percent {
      color: $main-pink;

      body.binance & {
        color: $b-main-pink;
      }
    }
  }
  &__name {
    font-weight: 700;
  }
  &__price-diff-percent {
    font-weight: 500;
  }
  span:not(:first-child) {
    margin-left: .5em;
  }
}
</style>
