import axios from 'axios';
import store from '../../store';

const mainEthAddress = process.env.VUE_APP_MAINNET_SHADOW_ADDRESS;
const rinkebyEthAddress = process.env.VUE_APP_MAINNET_SHADOW_ADDRESS_RINKEBY;
const devBscAddress = process.env.VUE_APP_DEV_BSC_ADDRESS;
const bscAddress = process.env.VUE_APP_BSC_API;
const devAvaxAddress = process.env.VUE_APP_DEV_AVAX_ADDRESS;
const avaxAddress = process.env.VUE_APP_MAINNET_AVAX_ADDRESS;

const axiosInst = axios.create();

const getBaseUrl = () => {
  if (store.state.User.chainId === 1) return mainEthAddress;
  if (store.state.User.chainId === 4) return rinkebyEthAddress;
  if (store.state.User.chainId === 56) return bscAddress;
  if (store.state.User.chainId === 97) return devBscAddress;
  if (store.state.User.chainId === 43113) return devAvaxAddress;
  if (store.state.User.chainId === 43114) return avaxAddress;

  return mainEthAddress;
};

/**
 * get Shadow tokens history
 * @return {Array} history
 */
export async function getPoolsHistory() {
  const resp = await axiosInst.get(`${getBaseUrl()}/history`);
  return resp.data;
}

/**
 * get Shadow tokens pools
 *
 * @returns {Object} info
 * @returns {number} info.totalWeight pools summary weight
 * @returns {Array} info.pools array of current pools
 */
export async function getPoolsInfo() {
  const resp = await axiosInst.get(`${getBaseUrl()}/pools`);
  return resp.data;
}

/**
 * Get sign for request
 * @param {String} address - signer address
 * @return {Object} sign params
 */
export async function getSign(address) {
  const resp = await axiosInst.post(`${getBaseUrl()}/address`, { address });
  return resp.data;
}

/**
 * Get bonuses for request
 * @param {String} address - signer address
 * @return {Object} params
 */
export async function getBonuses(address) {
  const resp = await axiosInst.get(`${getBaseUrl()}/bonuses/${address}`);
  return resp.data;
}

export async function getDrops(address) {
  const resp = await axiosInst.get(`${getBaseUrl()}/drop/address/${address}`);
  return resp.data;
}

export async function forceUpdate(address) {
  const resp = await axiosInst.post(`${getBaseUrl()}/force/${address}`);
  return resp.data;
}

const shadowApi = {
  getPoolsHistory,
  getPoolsInfo,
  getDrops,
  getSign,
  forceUpdate,
  getBonuses,
};

export default shadowApi;
