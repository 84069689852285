import Vue from 'vue';
import Notifications from 'vue-notification';

import web3 from '@/plugins/web3';
import contracts from '@/plugins/contracts';
import vuetify from '@/plugins/vuetify';
import Compound from '@/plugins/compound';
import Erc20 from '@/plugins/erc20';
import Vuelidate from '@/plugins/vuelidate';

import bigNumber from '@/plugins/bigNumber';
import App from './App';
import router from './router';
import store from './store';
import i18n from './i18n';
import { amountFilter, datetimeFilter } from './helpers/filters';

// styles
import 'normalize.css';
import 'vue-js-modal/dist/styles.css';
import './assets/style/index.scss';
import chain from './plugins/chain';
import Firebase from './plugins/firebase';

// plugins
Vue.use(web3);
Vue.use(Firebase);
Vue.use(bigNumber);
Vue.use(contracts, { store });
Vue.use(Compound, { store });
Vue.use(Erc20, { store });
Vue.use(chain);
Vue.use(Vuelidate);

Vue.use(Notifications);

Vue.config.productionTip = false;
Vue.filter('amount', amountFilter);
Vue.filter('datetime', datetimeFilter);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
