<script>
import { mapState } from 'vuex';
import { getTotalFarming } from '@/utils/getPrice';
import Button from '@/components/Button';
import RegisterButton from '@/components/RegisterButton';
import WithdrawButton from '@/components/WithdrawButton';

export default {
  name: 'MyBalance',
  components: {
    Button,
    RegisterButton,
    WithdrawButton,
  },

  data() {
    return {
      totalFarming: '...',
    };
  },

  async mounted() {
    this.totalFarming = await getTotalFarming();
  },

  computed: {
    ...mapState({
      sign: (state) => state.User.sign,
      shakeBalance: (state) => state.Shake.balance || 0,
      milkBalance: (state) => state.Milk.milkBalance || 0,
      milkPrice: (state) => state.Milk.price || 0,
      shakePrice: (state) => state.Shake.price || 0,
      prices: (state) => state.Price.list,
      pendingMilkWei: (state) => state.User.sign?.amount || 0,
    }),
    pendingMilk() {
      return this.$contracts.fromWei(this.pendingMilkWei);
    },
    totalMilk() {
      return this.$BN(this.milkBalance).plus(this.pendingMilk) || 0;
    },
  },
};
</script>

<template>
  <div class="menu-my-balance menu-my-balance__wrapper">
    <v-row class="menu-my-balance__coins-wrapper">
      <v-col class="menu-my-balance__coins">
        <img src="@/assets/img/milk-logo.svg" alt="milk coin logo">
        <div class="menu-my-balance__milk">
          {{ totalMilk | amount(5) }} MILK2
        </div>
      </v-col>
      <v-col class="menu-my-balance__coins">
        <img src="@/assets/img/shake-logo.svg" alt="shake coin logo">
        <div class="menu-my-balance__shake">
          {{ shakeBalance | amount(5) }} SHAKE
        </div>
      </v-col>
    </v-row>
    <hr>
    <div class="menu-my-balance__table-data">
      <span>{{ $t('header.my-balance.on-wallet') }}</span>
      <span>{{ milkBalance | amount(5) }} MILK2</span>
    </div>
    <div class="menu-my-balance__table-data">
      <span>{{ $t('header.my-balance.pending') }}</span>
      <span>{{ pendingMilk | amount(5) }} MILK2</span>
    </div>
    <div class="menu-my-balance__table-data">
      <span>{{ $t('header.my-balance.milk-price') }}</span>
      <span>{{ milkPrice | amount(3) }} ETH/{{ prices.MILK2 | amount(3) }} USD</span>
    </div>
    <div class="menu-my-balance__table-data">
      <span>{{ $t('header.my-balance.shake-price') }}</span>
      <span>{{ shakePrice | amount(3) }} ETH/{{ prices.SHAKE | amount(3) }} USD</span>
    </div>
    <div class="menu-my-balance__table-data">
      <span>{{ $t('header.my-balance.total-farming') }}</span>
      <span>{{totalFarming}} MILK2/BLOCK</span>
    </div>
    <div class="menu-my-balance__table-data">
      <RegisterButton class="margin-top" v-if="!sign" />
      <WithdrawButton class="margin-top" v-else />
      <Button :to="{ name: 'blender' }"
              class="margin-top">
              {{ $t('header.my-balance.shake-blender') }}
      </Button>
    </div>
  </div>
</template>

<style lang="scss">
  .menu-my-balance {
    &__wrapper {
      background: $modal-bg;
      padding: 4rem;
      border-radius: 2rem;
      top: 7.5rem;
      right: 0;
      margin: 0!important;
      display: flex;
      flex-direction: column;
      text-align: center;
      box-shadow: $modal-shadow;
      z-index: 100;
      font-weight: bold;
      color: $main-font-color;
      width: 58rem;
      position: absolute;

      body.binance & {
        color: $b-main-font-color;
        background: $b-modal-bg;
        box-shadow: $b-modal-shadow;
      }

      body.huobi & {
        color: $h-main-font-color;
        background: $h-modal-bg;
        box-shadow: $h-modal-shadow;
      }

      @media(max-width: $breakpoint-sm) {
        padding: 2rem;
        width: 100%;
        left: 0;
        right: 0;
      }

      hr {
        opacity: 0.4;
        margin-bottom: 2rem;
      }
    }

    &__coins-wrapper {
      margin-bottom: 2rem;
    }

    &__coins {
      img {
        height: 6.6rem;
        width: auto;
      }
    }

    &__shake {
      font-size: 2.5rem;
      color: #000E89;

      body.binance & {
        color: $b-main-font-color;
      }
    }

    &__milk {
      font-size: 2.5rem;
      color: #000E89;

      body.binance & {
        color: $b-main-font-color;
      }
    }

    &__table-data {
      display: flex;
      justify-content: space-between;
      color: #000E89;
      font-weight: normal;
      gap: 1.4rem;
      margin-bottom: 7px;
      & span:first-child {
        color: #7478a0
      }

      body.binance & {
        color: $b-main-font-color;

        & span:first-child {
          color: $b-secondatry-font-color;
        }
      }

      .btn {
        padding: 1.35em 3em;
        width: 100%;

        @media (max-width: $breakpoint-sm) {
          padding: 0.7em 1em;
          line-height: 1.3;
        }
      }
    }

    .margin-top {
      margin-top: 2rem;
    }
  }
</style>
