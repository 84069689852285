<script>
import { mapState } from 'vuex';
import ClickOutside from 'vue-click-outside';
import MyBalance from './Modals/MyBalance';
import Address from './Address';
import Button from './Button';

export default {
  name: 'MyWallet',
  data: () => ({
    balanceIsShown: false,
  }),
  components: {
    Address,
    MyBalance,
    Button,
  },
  computed: {
    ...mapState({
      ethAddress: (state) => state.User.ethAddress,
      chainId: (state) => state.User.chainId,
      shakeBalance: (state) => state.Shake.balance || 0,
      milkBalance: (state) => state.Milk.milkBalance || 0,
      pendingMilkWei: (state) => state.User.sign?.amount || 0,
    }),
    pendingMilk() {
      return this.$contracts.fromWei(this.pendingMilkWei);
    },
    totalMilk() {
      return this.$BN(this.milkBalance).plus(this.pendingMilk) || 0;
    },
  },
  methods: {
    toggleBalance() {
      this.balanceIsShown = !this.balanceIsShown;
      this.$parent.closeMenu();
      this.$parent.poolsSelectClose();
    },
    closeBalance() {
      this.balanceIsShown = false;
    },
  },
  watch: {
    $route() {
      this.closeBalance();
    },
  },
  mounted() {
    this.popupItem = this.$el;
  },
  directives: {
    ClickOutside,
  },
};
</script>

<template>
  <div class="menu-wallet__external-wrapper header__menu-item header__menu-item--wallet">
    <div class="menu-wallet__wrapper">
      <span @click="toggleBalance"
            class="menu-wallet__amount">
        {{ totalMilk | amount(4) }} {{ $t('main.milk2') }}
      </span>
      <Button :to="{ name: 'blender' }" class="btn--round">
        <img
          :src="require(`@/assets/img/${$chain.getName()}/icon_arrows.svg`)"
          alt="arrows"
          class="menu-wallet__blender-icon"/>
      </Button>
      <span @click="toggleBalance"
            class="menu-wallet__amount">
        {{ shakeBalance ? shakeBalance : '0' | amount(4, true) }} {{ $t('main.shake') }}
      </span>
      <Address class="menu-wallet__address"/>
    </div>
    <MyBalance v-if="balanceIsShown" v-click-outside="closeBalance"/>
    <img
      :src="require(`@/assets/img/new-year/${$route.name === 'home' ? 'bells' : '_card-1'}.svg`)"
      alt="new year"
      class="new-year"
    >
  </div>
</template>

<style lang="scss">
  .menu-wallet__address {
    @media screen and (max-width: $breakpoint-sm){
      display: none;
    }
  }
  .menu-wallet__external-wrapper {
    position: relative;

    @media screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }
  }

  .menu-wallet__wrapper {
    height: 5rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    overflow: hidden;
    @media screen and (max-width: $breakpoint-sm) {
      justify-content: space-between;
      margin-left: 17%;
      margin-right: 17%;
    }

    .menu-wallet__amount {
      white-space: nowrap;
      cursor: pointer;
      padding-left: 1.5rem;
      padding-right: 1.5rem;

      &:first-child {
        padding-left: 0;
      }
    }

    a {
      line-height: normal;
    }

    @media all and (max-width: 1250px) {
      padding: 0;
      background: transparent;
      box-shadow: none;
    }

    .btn--round {
      padding: 0;
      width: 4rem;
      height: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .menu-wallet__blender-icon {
      height: 1.5rem;
    }
  }
</style>
