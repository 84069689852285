// eslint-disable-next-line import/prefer-default-export
export const spaceCompTokens = {
  1: {},
  4: {
    '0xbccafa48476d20eab72d96a2faed1af5e71fa6a4': {
      address: '0xbccafa48476d20eab72d96a2faed1af5e71fa6a4',
      underlyingAddress: '0x577d296678535e4903d59a4c929b718e1d575e0a',
      symbol: 'cWBTC',
      underlyingSymbol: 'WBTC',
      decimals: 8,
      underlyingDecimals: 18,
    },
    '0x3550b03837ac3e86c5c03baf925d73642cfdb50d': {
      address: '0x3550b03837ac3e86c5c03baf925d73642cfdb50d',
      underlyingAddress: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
      symbol: 'cETH',
      underlyingSymbol: 'ETH',
      decimals: 8,
      underlyingDecimals: 18,
    },
    '0x96ac2109f2eb9606b6308aaee937cb28b7cdc3a1': {
      address: '0x96ac2109f2eb9606b6308aaee937cb28b7cdc3a1',
      underlyingAddress: '0x5592ec0cfb4dbc12d3ab100b257153436a1f0fea',
      symbol: 'cDAI',
      underlyingSymbol: 'DAI',
      decimals: 8,
      underlyingDecimals: 18,
    },
    '0xce71fd4e04a11d43feb7e45da54d01ab30c9ae27': {
      address: '0xce71fd4e04a11d43feb7e45da54d01ab30c9ae27',
      underlyingAddress: '0x20dcd3802e29ecd133813108d9409c6802bce688',
      symbol: 'cUSDT',
      underlyingSymbol: 'USDT',
      decimals: 18,
      underlyingDecimals: 6,
    },
    // '0x7f36628020fb5bce5c13bf2fc6286507593aec1c': {
    //   address: '0x7f36628020fb5bce5c13bf2fc6286507593aec1c',
    //   underlyingAddress: '0x48a0e5d7a21ada2161175887554d92cf145fb998',
    //   symbol: 'cNOAH',
    //   underlyingSymbol: 'NOAH',
    //   decimals: 8,
    //   underlyingDecimals: 18,
    // },
    '0xd2a2eb4ca2639e21f582270659dde74fb51635f9': {
      address: '0xd2a2eb4ca2639e21f582270659dde74fb51635f9',
      underlyingAddress: '0x34a5eec56da76493cb44800f5903953fa3d004e5',
      symbol: 'sMILK',
      underlyingSymbol: 'MILK2',
      decimals: 8,
      underlyingDecimals: 18,
    },
    '0x112b571fafbe5197d17f2eecbbf8b5933d68d2b8': {
      address: '0x112b571fafbe5197d17f2eecbbf8b5933d68d2b8',
      underlyingAddress: '0xf7de53e08d8919463b964ad591f1fc6d4f472829',
      symbol: 'sSHAKE',
      underlyingSymbol: 'SHAKE',
      decimals: 8,
      underlyingDecimals: 18,
    },
  },
  56: {},
  97: {
    '0x727f751f9884c0113d24925acf8dab15de0ee98e': {
      address: '0x727f751f9884c0113d24925acf8dab15de0ee98e',
      underlyingAddress: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
      symbol: 'sBNB',
      underlyingSymbol: 'BNB',
      decimals: 8,
      underlyingDecimals: 18,
    },
    '0x6ac68dc84a9d959b185b9e056274ea953a0a1cb0': {
      address: '0x6ac68dc84a9d959b185b9e056274ea953a0a1cb0',
      underlyingAddress: '0x1feeb9166a5d24100cc4551e71ab990fa47974c2',
      symbol: 'sMILK',
      underlyingSymbol: 'MILK2',
      decimals: 8,
      underlyingDecimals: 18,
    },
    '0x65d929ff057f28cb71d8e05ea1e264acfba33b90': {
      address: '0x65d929ff057f28cb71d8e05ea1e264acfba33b90',
      underlyingAddress: '0xfb18fae3417dccab52e19528798358e7302d77df',
      symbol: 'cUSDT',
      underlyingSymbol: 'USDT',
      decimals: 6,
      underlyingDecimals: 18,
    },
    '0x8f37009dca5ceda1fae508a0a428a68d127e503b': {
      address: '0x8f37009dca5ceda1fae508a0a428a68d127e503b',
      underlyingAddress: '0x5c4b8bdee5b67f711d9196bdbfd8528674f1a3e1',
      symbol: 'sCAKE',
      underlyingSymbol: 'CAKE',
      decimals: 8,
      underlyingDecimals: 18,
    },
    '0x8b834a04e713f46a2fdfee2b6fb9e9a228c95773': {
      address: '0x8b834a04e713f46a2fdfee2b6fb9e9a228c95773',
      underlyingAddress: '0xfd76aedf4e07230f51c071905c4b9e4e39515bd8',
      symbol: 'sBUSD',
      underlyingSymbol: 'BUSD',
      decimals: 8,
      underlyingDecimals: 18,
    },
  },
};
