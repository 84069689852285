const requireModule = require.context('.', false, /\.json$/);

const tokens = {};
requireModule.keys().forEach((filename) => {
  const moduleName = filename
    .replace(/(\.\/|\.json)/g, '');
  tokens[moduleName] = requireModule(filename).default || requireModule(filename);
});

export default tokens;
