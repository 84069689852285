const bnb = {
  // uniswap
  uniFactory: '0xbcfccbde45ce874adcb698cc183debcf17952812',
  uniRouter: '0x05ff2b0db69458a0750badebc4f9e13add608c7f',
  // tokens
  weth: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  eth: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
  zero: '0x0000000000000000000000000000000000000000',
  // spaceSwap tokens
  SSI: '0xb56c1842e51ADf8015781452851FeCD755a4B25c',
  milk: '0x4a5a34212404f30c5ab7eb61b078fa4a55adc5a5',
  interstellar: '0x5795e4339C95dF989998b7202BAF7FF58e1c06a0',
  milk2: '0x4a5a34212404f30c5ab7eb61b078fa4a55adc5a5',
  blackHole: '0x41600f641bc7c71ba87fec1653782766aa213c8e',
  gravity: '0xa0d9c7e43542048Ed4B03C0A99255069Ec73CaC8',
  exchange: '0x58a9CeFfE63c5Ce63b2cf3AF2c11C07037cF8C96',
  galaxy: '0xa0d9c7e43542048Ed4B03C0A99255069Ec73CaC8',
  shake: '0xba8a6ef5f15ed18e7184f44a775060a6bf91d8d0',
  blender: '0x6bdd0abe9f065ae538645009de7aefde34e5738c',
  shadow: '0xc0Dacd836A89a1aBc7DD515CeD1DF08d32503568',
  nft: '0x42c250631296f552b35a86575c8ea86bf7584101',
  nftprovider: '0x5f7b3424e78858bbf4c402d83c356c96e3962c90',
  babyMilk: '0x34a5eec56da76493cb44800f5903953fa3d004e5',
  // spaceSwap LP
  milk2Lp: '0x9e8a0ad0b1e67bbc96c88463b193b77af0c55a7c',
  shakelp: '0xc0885c71442e5a598ad55a0772a8351f5be9751e',
  // contracts
  SSIBasicIssuanceModule: '0x6853545fe0c1372098C0BB0C0b0b15550a66fcB6',
  // index token pairs
  SSIPairs: [
    // eth - index
    ['0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee', '0xb56c1842e51adf8015781452851fecd755a4b25c'],
    // weth - index
    ['0xc778417E063141139Fce010982780140Aa0cD5Ab', '0xb56c1842e51adf8015781452851fecd755a4b25c'],
    // dai - index
    ['0x5592EC0cfb4dbc12D3aB100b257153436a1f0FEa', '0xb56c1842e51adf8015781452851fecd755a4b25c'],
    // usdt - index
    ['0xD9BA894E0097f8cC2BBc9D24D308b98e36dc6D02', '0xb56c1842e51adf8015781452851fecd755a4b25c'],
  ],
  // compound
  comptroller: '0xccf0657c4f0fee437464bfabec32e21a8b024809',
  priceOracle: '0x0504210c132da5d4cdfb115bf04bfa13642497eb',
  air: '0xCD9dB6021749F25f13A6D4fF7D950d33bCb714e2',
  booster: {
    1: '0xa4ac76254409ae9316322d9a17c462a5de01116d',
    2: '0x598d7601f75aafd82aa1644522fa9225aa156ecb',
    3: '0xd2521edd6213581216bdb1c29d5f0a4d02213bb1',
    4: '0x8d3aea74f659d2c618aabc244af4424d377addce',
    5: '0xbbf94242ab1213d0be1a11a4ccbf81878c09158f',
    6: '0xa0c73bd9f49c2adf2604fe5d22a65485e9af3974',
  },
  // lounchpad
  launchpadStaking: '0x4B15FbD16FA7e0df0B720E351f66221D889F3ad2',
  launchpadStakingShake: '0x8E0E04fcDE7C424f94766A6Def265Ab3f2e61642',
  launchpadSale: '0xF904244B634133d4e9C8B906D5D1585131f2b05c',
  launchpadSaleOld: '0x019C219cF5E0f4Ca2be377fCCeEDcA771435b84c',
  launchpadWhiteList: '0x03Dcb818B503bBDfc9699A327263eb19d4A33cDe',
  BUSD: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  sSIDUS: '0x54b4A66624039E0455c6369875Df6bbDA231Ee88',
  sSENATE: '0xea991d5633Ec86756F1c08d23982b26F2F7FFeA7',
  sidusPresale: '0x721C299E6BF7D6a430d9bEA3364Ea197314bcE09',
  CCASH: '0xd36bb7849a8c50b509bae872f44d5c1c7dc0e96c',
  sPGK: '0xA25441A567aFf328090Ada0c2b24337159271065',
  sLGX: '0x6e89f6b56bd48969A7eeaDa99183AF2d29f77A4F',
  shadowBridge: '0x4DF5b1aaEf2EEE9289dCC9f1521280EcAC1fEf3E',
};

export default bnb;
