import axios from 'axios';

export const get = (currencies) => axios(`${process.env.VUE_APP_API_PRICES}/simple/price?ids=${currencies}&vs_currencies=usd&include_24hr_change=true`);
/**
 * Get token history from coingecko
 * @param tokenName {String} token name
 * @param days {String, Number} period of history
 * @returns {AxiosPromise}
 */
export const getTokenHistory = (tokenName, days = '90') => axios({
  method: 'get',
  url: `${process.env.VUE_APP_API_PRICES}/coins/${tokenName}/market_chart`,
  params: {
    vs_currency: 'usd',
    days,
  },
});

export default {
  get,
};
