import BigNumber from 'bignumber.js';
import Axios from 'axios';
import Vue from 'vue';

const prices = {};
/**
 * get price in WETH from uniswap pair
 * @param {string} lp - uniswap pair address
 * @param {string} token - requested token price address
 * @return {BigNumber} - price in WETH
 */
const getPriceInWETH = async (lp, token) => {
  const weth = Vue.$contracts.getAddress('weth');
  if (!prices[lp]) {
    const wethContract = Vue.$contracts.getInstance(weth, 'token');
    const tokenAmount = await wethContract.methods.balanceOf(lp).call();
    const tokenContract = Vue.$contracts.getInstance(token, 'token');
    // const decimals = await tokenContract.methods.decimals().call();
    const totalTokens = await tokenContract.methods.balanceOf(lp).call();

    const price = new BigNumber(tokenAmount).div(new BigNumber(totalTokens));

    if (!new BigNumber(totalTokens).toNumber()) {
      return price;
    }

    prices[lp] = price;
  }

  return prices[lp];
};

const getEthUsdPrice = async () => {
  const wethPriceInUSD = await Axios('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd');
  return wethPriceInUSD.data.ethereum.usd;
};

const getLpTokenPrice = async (lp, token) => {
  const priceName = `${lp}_${token}`;
  if (!prices[priceName]) {
    const lpContract = Vue.$contracts.getInstance(lp, 'token');
    const tokenContract = Vue.$contracts.getInstance(token, 'token');
    const totalLPSupply = await lpContract.methods.totalSupply().call();
    const tokensAmount = await tokenContract.methods.balanceOf(lp).call();
    const priceLP = (tokensAmount * 2) / totalLPSupply;
    const tokenWethPrice = await getPriceInWETH(lp, token);
    const wethPrice = priceLP * tokenWethPrice.toNumber();
    const wethPriceInUSD = await getEthUsdPrice();
    prices[priceName] = wethPrice * wethPriceInUSD;
  }
  return prices[priceName];
};

const getTotalFarming = async () => {
  if (!prices.totalFarming) {
    const nft = Vue.$contracts.getContract('nft');
    const shadow = Vue.$contracts.getContract('shadow');
    const promises = [
      nft.methods.getCurrentBlockReward().call(),
      shadow.methods.getCurrentMultiplier().call(),
    ];
    const [nfrReward, shadowReward] = await Promise.all(promises);
    prices.totalFarming = Vue.$contracts.fromWei(shadowReward).plus(nfrReward / 100).toString();
  }

  return prices.totalFarming;
};

export {
  getLpTokenPrice, getPriceInWETH, getEthUsdPrice, getTotalFarming,
};
