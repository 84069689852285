import types from '@/abi';
import mainnet from '@/address.mainnet';
import rinkeby from '@/address.rinkeby';
import bnb from '@/address.bnb';
import bnbTestnet from '@/address.bnbTestnet';
import heco from '@/address.heco';
import hecoTestnet from '@/address.hecoTestnet';
import deepFindInObject from '@/helpers/deepFindInObject';
import avalanche from '@/address.avalanche';
import avalancheTestnet from '@/address.avalancheTestnet';
import ropsten from '@/address.ropsten';

const contractsMap = {
  milk2: 'token',
  milk: 'token',
  interstellar: 'interstellar',
  shake: 'token',
  blender: 'blender',
  blackHole: 'playground',
  exchange: 'exchange',
  gravity: 'gravity',
  galaxy: 'interstellar',
  shadow: 'shadow',
  nftprovider: 'nftprovider',
  nft: 'nftfarm',
  new_nftprovider: 'newnftprovider',
  new_nft: 'newnftfarm',
  uniFactory: 'uniFactory',
  uniRouter: 'uniRouter',
  SSIBasicIssuanceModule: 'SSIBasicIssuanceModule',
  SSI: 'SSI',
  comptroller: 'comptroller',
  priceOracle: 'priceOracle',

  launchpadStaking: 'launchpadStaking',
  launchpadStakingShake: 'launchpadStakingShake',
  launchpadSale: 'launchpadSale',
  launchpadSaleOld: 'launchpadSaleOld',
  launchpadWhiteList: 'launchpadWhiteList',
  launchpadVesting: 'launchpadVesting',
  air: 'air',
  DAI: 'token',
  LCH: 'token',
  BUSD: 'token',
  sSIDUS: 'token',
  sSENATE: 'token',
  sTAUR: 'token',
  DNT: 'token',
  CCASH: 'token',
  sPGK: 'token',
  sLGX: 'token',
  launching: 'token',
  booster: {
    1: 'booster',
    2: 'booster',
    3: 'booster',
    4: 'booster',
    5: 'booster',
    6: 'booster',
  },
  clickGame: 'clickGame',
  sidusPresale: 'sidusPresale',
  shadowBridge: 'shadowBridge',
};

export default {
  install(Vue, { store }) {
    const getInstance = (address, type = null, abi = {}) => {
      const ABI = type ? types[type] : abi;
      let contract;
      try {
        if (typeof address === 'string') {
          contract = new Vue.$web3.eth.Contract(ABI, address);
        }
      } catch (e) {
        console.error('Cound`t connect to the contract ', address, e);
      }
      return contract;
    };

    const getContractAddress = (id, name) => {
      switch (id) {
        case 4:
          return deepFindInObject(rinkeby, name);
        case 3:
          return deepFindInObject(ropsten, name);
        case 56:
          return deepFindInObject(bnb, name);
        case 97:
          return deepFindInObject(bnbTestnet, name);
        case 128:
          return deepFindInObject(heco, name);
        case 256:
          return deepFindInObject(hecoTestnet, name);
        case 43114:
          return deepFindInObject(avalanche, name);
        case 43113:
          return deepFindInObject(avalancheTestnet, name);
        default:
          return deepFindInObject(mainnet, name);
      }
    };
    /**
     * Get contract address by contract name. Names list represented in rinkeby.js or in mainnet.js
     * they are the same
     * @param {String} name - name of contract
     * @see [rinkeby]{@link rinkeby} - rinkeby list
     * @see [mainnet]{@link mainnet} - mainnet list
     * @returns {String} - address of contract
     */
    const getAddress = (name) => getContractAddress(store.state.User.chainId, name);

    // eslint-disable-next-line max-len
    const getContract = (name) => getInstance(getAddress(name), deepFindInObject(contractsMap, name));
    /**
     * Convert amount to Wei takes into account decimals
     * @param {String, Number} amount - converted amount
     * @param {String, Number} [decimals=18] - converted value decimals
     * @returns {BigNumber}
     */
    const toWei = (amount, decimals = 18) => (
      Vue.$BN(amount).times(Vue.prototype.$BN(10).pow(decimals))
    );
    /**
     * Convert amount from Wei takes into account decimals
     * @param {String, Number} amount - converted amount
     * @param {String, Number} [decimals=18] - converted value decimals
     * @returns {BigNumber}
     */
    const fromWei = (amount, decimals = 18) => (
      Vue.prototype.$BN(amount).div(Vue.prototype.$BN(10).pow(decimals))
    );

    const getTokensPerYear = async (name) => {
      const latest = await Vue.prototype.$web3.eth.getBlockNumber();
      const contract = getContract(name);
      // TODO: rewrite on transition to shadow (getMultiplier -> getCurrentMultiplier)
      if (contract) {
        const multiplier = await contract.methods.getMultiplier(latest - 1, latest).call();
        return Vue.$BN(multiplier).times(2336000);
      }
      return 0;
    };

    Vue.$contracts = {
      contractsMap,
      getInstance,
      getContract,
      toWei,
      fromWei,
      getAddress,
      getTokensPerYear,
      getContractAddress,
    };
    Vue.prototype.$contracts = Vue.$contracts;
  },
};
