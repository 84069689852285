<template>
  <div class="landing-header">
    <img
      src="@/assets/img/new-year/bells.svg"
      class="new-year new-year__landing-top"
      alt="new-year"
    >
    <div class="landing-header--logo">
      <img
        src="@/assets/img/new-year/landing-logo.svg"
        class="new-year"
        alt="new-year"
      >
    </div>
    <div class="landing-header--stars hide-on-mobile"></div>

    <div class="landing-header__info main-wrap main-wrap--narrow">
      <div class="landing-title">WHAT IS SPACE SWAP?</div>
      <span class="landing-header__info--description">
        SpaceSwap is a unique aggregator that aims to unite
        the most popular DeFi protocols in a one-stop station.
        <br/>
        <strong>Safe, fast, cheap.</strong>
      </span>
      <div class="landing-header--button">
        <Button
          v-if="!$store.state.User.ethAddress && !$route.meta.withoutAuth"
          class="nft-link"
          @click="$root.$emit('connect')"
        >
          Connect Wallet
          <img
            src="@/assets/img/new-year/_card-1.svg"
            class="new-year new-year__right"
            alt="new-year"
          >
        </Button>
      </div>
    </div>

    <div class="landing-header--cow">
      <CowMain/>
    </div>
    <div class="landing-header--shake"></div>
    <div class="landing-header--milk"></div>

  </div>
</template>

<script>
import CowMain from './animations/CowMain';
import Button from '../Button';

export default {
  name: 'LandingHeader',
  components: { Button, CowMain },
};
</script>

<style scoped lang="scss">
.landing-title {
  text-align: left;
  margin-bottom: 2rem;
  z-index: 99;
}
.landing-header {
  display: flex;
  flex-direction: column;
  min-height: 120rem;
  align-items: center;
  flex: 1;
  background: #F5F5F7 url("../../assets/img/landing/header-bg.svg") no-repeat;
  background-position: center -10rem;
  background-size: 100vw 100vw;

  &--stars {
    background: url("../../assets/img/landing/header-stars.svg") no-repeat;
    background-size: contain;
    width: 100%;
    position: absolute;
    height: 24rem;
    z-index: 1;
  }

  &--shake {
    background: url("../../assets/img/landing/shake.svg") no-repeat;
    background-size: contain;
    position: absolute;
    width: 22rem;
    height: 32rem;
    right: 62rem;
    z-index: 3;
    top: 60rem;
  }

  &--milk {
    background: url("../../assets/img/landing/milk.svg") no-repeat;
    background-size: contain;
    position: absolute;
    z-index: 2;
    width: 24rem;
    height: 48rem;
    right: 45rem;
    top: 54rem;
  }

  &--logo {
    background: url("../../assets/img/landing/logo.svg") no-repeat;
    background-size: contain;
    height: 4.8rem;
    width: 22.6rem;
    margin-top: 5rem;
    margin-bottom: 8.2rem;
    z-index: 0;
  }

  &--cow {
    width: 70rem;
    height: 90rem;
    position: absolute;
    right: 5rem;
    top: 25rem;
  }

  &--button {
    display: flex;
    justify-content: flex-start;
    margin-top: 8rem;
  }

  &__info {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;

    &--description {
      width: 50%;
      font-size: 2rem;
      color: #000E89;
      font-weight: 600;

      strong {
        color: #FF8B8B;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .landing-header--cow {
    margin-top: 30rem;
    width: 50rem;
    right: 0;
    height: 60rem;
  }

  .landing-header--button {
    margin-top: 3rem;
  }
  .landing-header--shake {
    width: 15rem;
    height: 22rem;
    left: 2rem;
    margin-top: 20rem;
  }

  .landing-header--milk {
    width: 29rem;
    height: 24rem;
    right: 10rem;
    margin-top: 24rem;
  }

  .landing-header {
    background-position: -70rem 30rem;
    background-size: cover;
  }

  .landing-header__info {
    padding: 0 2rem;
  }

  .landing-header__info--description {
    width: 100%;
  }
}
</style>
