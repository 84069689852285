import Vue from 'vue';

const notificationsTypes = ['info', 'success', 'warn'];

const clearNotification = (type) => {
  Vue.notify({
    group: type,
    clean: true,
  });
};

const clearAllNotifications = () => {
  notificationsTypes.forEach((type) => {
    clearNotification(type);
  });
};

const addNotification = ({ type = 'info', config, ...params }) => {
  Vue.notify({
    group: type,
    ...params,
    config: {
      duration: 20000,
      ...config,
    },
  });
};

export {
  clearNotification,
  clearAllNotifications,
  addNotification,
};
