import store from '../store/index';

export const shadowPools = {
  1: {
    interstellar: {
      2: {
        id: 2,
        lpContract: '0x8f30ba1f75ec27a014591789d117a1bb4703bff8',
        tokenContract: '0x6006FC2a849fEdABa8330ce36F5133DE01F96189',
        name: 'Raspberry',
        symbol: 'SHAKE-ETH <br/>UNI-V2 LP',
        fromToken: 'shake',
        toToken: 'eth',
        liquidityLink: '/add/v2/ETH/0x6006FC2a849fEdABa8330ce36F5133DE01F96189',
        tradeLink: '/swap?outputCurrency=0x6006FC2a849fEdABa8330ce36F5133DE01F96189&use=V2',
        tokenSymbol: 'SHAKE',
        icon: 'raspberry-accent',
        hot: true,
      },
      1: {
        id: 1,
        lpContract: '0x9f319e265b8189b5b5c2152cacc791b95b3da180',
        tokenContract: '0x80c8c3dcfb854f9542567c8dac3f44d709ebc1de',
        uniContract: '0xee802b312a957e00dd7bbc08eec8bb93d40e981d',
        name: 'Salt caramel',
        symbol: 'MILK2-SHAKE <br/>UNI-V2 LP',
        fromToken: 'milk',
        toToken: 'shake',
        isMilk: true,
        liquidityLink: '/add/v2/0x6006FC2a849fEdABa8330ce36F5133DE01F96189/0x80c8C3dCfB854f9542567c8Dac3f44D709eBc1de',
        tradeLink: '/swap?inputCurrency=0x6006FC2a849fEdABa8330ce36F5133DE01F96189&outputCurrency=0x80c8c3dcfb854f9542567c8dac3f44d709ebc1de&use=V2',
        tokenSymbol: 'MILK2',
        icon: 'salt-caramel-accent',
        hot: true,
      },
      0: {
        id: 0,
        lpContract: '0xee802b312a957e00dd7bbc08eec8bb93d40e981d',
        tokenContract: '0x80c8c3dcfb854f9542567c8dac3f44d709ebc1de',
        name: 'Strawberry',
        symbol: 'MILK2-ETH <br/>UNI-V2 LP',
        fromToken: 'milk',
        toToken: 'eth',
        liquidityLink: '/add/v2/ETH/0x80c8C3dCfB854f9542567c8Dac3f44D709eBc1de',
        tradeLink: '/swap?outputCurrency=0x80c8c3dcfb854f9542567c8dac3f44d709ebc1de&use=V2',
        tokenSymbol: 'MILK2',
        icon: 'strawberry-accent',
        hot: true,
      },
    },
    galaxy: {
      3: {
        pid: 3,
        lpContract: '0xc6fc2d5101da4628db8d055776ae1a91fa0c8796',
        tokenContract: '0x41b3F18c6384Dc9A39c33AFEcA60d9b8e61eAa9F',
        name: 'Orange',
        symbol: 'NOAH-ETH UNI-V2 LP',
        tokenSymbol: 'NOAH',
        icon: 'orange',
      },
      // 4: {
      //   pid: 4,
      //   lpContract: '0xc133bbb6b184f0f5f4b8c44c0b05cd057af80e7b',
      //   tokenContract: '0x6006FC2a849fEdABa8330ce36F5133DE01F96189',
      //   uniContract: '0x8f30ba1f75ec27a014591789d117a1bb4703bff8',
      //   name: 'Antimatter',
      //   symbol: 'SHAKE-CRV UNI-V2 LP',
      //   tokenSymbol: 'CRV',
      //   icon: 'antimatter',
      // },
      5: {
        pid: 5,
        lpContract: '0xdd8da77de9de50338e9cb2249575a7aa7b78d677',
        tokenContract: '0x80c8c3dcfb854f9542567c8dac3f44d709ebc1de',
        uniContract: '0xee802b312a957e00dd7bbc08eec8bb93d40e981d',
        name: 'Lactose-Free',
        symbol: 'MILK2-CRV UNI-V2 LP',
        tokenSymbol: 'CRV',
        icon: 'lactose-free',
      },
      15: {
        id: 15,
        lpContract: '0xd8c3804eed8f398f92fd1e6ebd1e69d98668a13a',
        tokenContract: '0x80c8c3dcfb854f9542567c8dac3f44d709ebc1de',
        uniContract: '0xee802b312a957e00dd7bbc08eec8bb93d40e981d',
        name: 'Banana',
        symbol: 'MILK2-1INCH UNI-V2 LP',
        tokenSymbol: '1inch',
        icon: 'banana',
      },
      17: {
        id: 17,
        lpContract: '0x6e3118b3ebcab0c7b4467a86e994e285685fff58',
        tokenContract: '0x80c8c3dcfb854f9542567c8dac3f44d709ebc1de',
        uniContract: '0xee802b312a957e00dd7bbc08eec8bb93d40e981d',
        name: 'Chilli',
        symbol: 'MILK2-SNOW UNI-V2 LP',
        tokenSymbol: 'SNOW',
        icon: 'chilli',
      },
      19: {
        id: 19,
        lpContract: '0x48e0747a17c32eacc1567fb7df52a7e70b53d991',
        tokenContract: '0x80c8c3dcfb854f9542567c8dac3f44d709ebc1de',
        uniContract: '0xee802b312a957e00dd7bbc08eec8bb93d40e981d',
        name: 'Avocado',
        symbol: 'MILK2-JUL UNI-V2 LP',
        tokenSymbol: 'JUL',
        icon: 'avocado',
      },
      20: {
        id: 20,
        lpContract: '0xa8bfa9dbca153e160b994f7e910e6323815f08ba',
        tokenContract: '0x80c8c3dcfb854f9542567c8dac3f44d709ebc1de',
        uniContract: '0xee802b312a957e00dd7bbc08eec8bb93d40e981d',
        name: 'Candy floss',
        symbol: 'MILK2-MIC UNI-V2 LP',
        tokenSymbol: 'MIC',
        icon: 'candy-floss',
      },
      21: {
        id: 21,
        lpContract: '0xbe29641543aba6b29896e88541c9c22cd5dcf49c',
        tokenContract: '0x80c8c3dcfb854f9542567c8dac3f44d709ebc1de',
        uniContract: '0xee802b312a957e00dd7bbc08eec8bb93d40e981d',
        name: 'Rainbow',
        symbol: 'MILK2-CREAM UNI-V2 LP',
        tokenSymbol: 'CREAM',
        icon: 'rainbow',
      },
    },
    gravity: {
      8: {
        pid: 8,
        lpContract: '0xb4e16d0168e52d35cacd2c6185b44281ec28c9dc',
        tokenContract: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
        name: 'Boo',
        symbol: 'USDC-ETH UNI-V2 LP',
        tokenSymbol: 'USDC',
        icon: 'boo',
      },
      9: {
        pid: 9,
        lpContract: '0x0d4a11d5eeaac28ec3f61d100daf4d40471f1852',
        tokenContract: '0xdac17f958d2ee523a2206206994597c13d831ec7',
        name: 'Chockie',
        symbol: 'USDT-ETH UNI-V2 LP',
        tokenSymbol: 'USDT',
        icon: 'chockie',
      },
      10: {
        pid: 10,
        lpContract: '0xa478c2975ab1ea89e8196811f51a7b7ade33eb11',
        tokenContract: '0x6b175474e89094c44da98b954eedeac495271d0f',
        name: 'Cookie',
        symbol: 'DAI-ETH UNI-V2 LP',
        tokenSymbol: 'DAI',
        icon: 'cookie',
      },
      11: {
        pid: 11,
        lpContract: '0xf80758ab42c3b07da84053fd88804bcb6baa4b5c',
        tokenContract: '0x57ab1ec28d129707052df4df418d58a2d46d5f51',
        name: 'Susie',
        symbol: 'SUSD-ETH UNI-V2 LP',
        tokenSymbol: 'SUSD',
        icon: 'susie',
      },
      12: {
        pid: 12,
        lpContract: '0x3041cbd36888becc7bbcbc0045e3b1f144466f5f',
        tokenContract: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        uniContract: '0x0d4a11d5EEaaC28EC3F61d100daF4d40471f1852',
        name: 'Jojo',
        symbol: 'USDC-USDT UNI-V2 LP',
        tokenSymbol: 'USDT',
        icon: 'jojo',
      },
      13: {
        pid: 13,
        lpContract: '0xb20bd5d04be54f870d5c0d3ca85d82b34b836405',
        tokenContract: '0x6b175474e89094c44da98b954eedeac495271d0f',
        uniContract: '0xA478c2975Ab1Ea89e8196811F51A7B7Ade33eB11',
        name: 'Markus',
        symbol: 'DAI-USDT UNI-V2 LP',
        tokenSymbol: 'DAI',
        icon: 'markus',
      },
      14: {
        pid: 14,
        lpContract: '0xae461ca67b15dc8dc81ce7615e0320da1a9ab8d5',
        tokenContract: '0x6b175474e89094c44da98b954eedeac495271d0f',
        uniContract: '0xa478c2975ab1ea89e8196811f51a7b7ade33eb11',
        name: 'Pony',
        symbol: 'DAI-USDC UNI-V2 LP',
        tokenSymbol: 'DAI',
        icon: 'pony',
      },
    },
  },
  43113: {
    interstellar: {
      0: {
        id: 0,
        lpContract: '0xa637aa2931f122782da23066e0450fb9932b0153',
        tokenContract: '0xa637aa2931f122782da23066e0450fb9932b0153',
        symbol: 'avax1',
        icon: 'raspberry-accent',
      },
      1: {
        id: 1,
        lpContract: '0xf273b28cc116d1e3595991f2d7f142de2d3a9164',
        tokenContract: '0xf273b28cc116d1e3595991f2d7f142de2d3a9164',
        symbol: 'avax2',
        icon: 'salt-caramel-accent',
      },
    },
  },
  43114: {
    interstellar: {
      0: {
        id: 0,
        lpContract: '0x180cac095abfd232b428094b746379654ec54235',
        tokenContract: '0x721c299e6bf7d6a430d9bea3364ea197314bce09',
        name: 'Cranberry Sorbet',
        symbol: 'MILK2-AVAX <br/> Pangolin LP',
        tradeLink: '/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0x721c299e6bf7d6a430d9bea3364ea197314bce09 ',
        liquidityLink: '/#/add/0x721c299e6bf7d6a430d9bea3364ea197314bce09/0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7 ',
        icon: 'sorbet',
        mul: 2000 * 15 * 4,
      },
      1: {
        id: 1,
        lpContract: '0x4321fe4987537133a16aab0937a867febd066279',
        tokenContract: '0x721c299e6bf7d6a430d9bea3364ea197314bce09',
        uniContract: '0x180cac095abfd232b428094b746379654ec54235',
        symbol: 'MILK2-SHAKE<br/> Pangolin LP',
        tradeLink: '/#/swap?inputCurrency=0x721c299e6bf7d6a430d9bea3364ea197314bce09&outputCurrency=0xc1d02e488a9ce2481bfdcd797d5373dd2e70a9c2 ',
        liquidityLink: '/#/add/0x721c299e6bf7d6a430d9bea3364ea197314bce09/0xc1d02e488a9ce2481bfdcd797d5373dd2e70a9c2',
        name: 'Royal Parfait',
        icon: 'royal',
        mul: 2000 * 15,
        // apr: 329.6,
      },
      2: {
        id: 2,
        // apr: 399.1,
        lpContract: '0xcacc388e7c0d8a020c229782a96668b3c24a2716',
        tokenContract: '0xc1d02e488a9ce2481bfdcd797d5373dd2e70a9c2',
        symbol: 'SHAKE-AVAX<br/> Pangolin LP',
        tradeLink: '/#/swap?inputCurrency=0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7&outputCurrency=0xc1d02e488a9ce2481bfdcd797d5373dd2e70a9c2 ',
        liquidityLink: '/#/add/0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7/0xc1d02e488a9ce2481bfdcd797d5373dd2e70a9c2',
        name: 'Lemon Gelato',
        icon: 'lemon',
        mul: 2000 * 8,
      },
    },
  },
  4: {
    interstellar: {
      0: {
        id: 0,
        lpContract: '0xa9ef761cd17f65e4dce935069e2e568e6e536218',
        tokenContract: '0xa9ef761cd17f65e4dce935069e2e568e6e536218',
        symbol: 'SSP1',
        icon: 'raspberry-accent',
      },
      1: {
        id: 1,
        lpContract: '0x9e35a7362331f588da09ce1debc98f7960387e3b',
        tokenContract: '0x9e35a7362331f588da09ce1debc98f7960387e3b',
        symbol: 'SSP2',
        icon: 'salt-caramel-accent',
      },
    },
  },
  56: {
    interstellar: {
      3: {
        id: 3,
        lpContract: '0xc0885c71442e5a598ad55a0772a8351f5be9751e',
        tokenContract: '0xba8a6ef5f15ed18e7184f44a775060a6bf91d8d0',
        liquidityLink: '/add/BNB/0xbA8A6Ef5f15ED18e7184f44a775060a6bF91d8d0',
        symbol: 'SHAKE-BNB <br/>Pancake-V2 LP',
        fromToken: 'shake',
        toToken: 'bnb',
        tradeLink: 'swap?outputCurrency=0xba8a6ef5f15ed18e7184f44a775060a6bf91d8d0',
        name: 'Butteriginal',
        icon: 'butteriginal',
        hot: true,
      },
      4: {
        id: 4,
        lpContract: '0x223648bb35e87dac16c4887d8506df79ec29a1df',
        tokenContract: '0x4a5a34212404f30c5ab7eb61b078fa4a55adc5a5',
        uniContract: '0x1bf2609946b0583eac8c9a3136a24f7ea06c91bc',
        liquidityLink: '/add/0xbA8A6Ef5f15ED18e7184f44a775060a6bF91d8d0/0x4A5a34212404f30C5aB7eB61b078fA4A55AdC5a5',
        symbol: 'MILK2-SHAKE <br/>Pancake-V2 LP',
        fromToken: 'milk',
        toToken: 'shake',
        name: 'Strawsion',
        icon: 'strawsion',
        hot: true,
      },
      5: {
        id: 5,
        lpContract: '0x9e8a0ad0b1e67bbc96c88463b193b77af0c55a7c',
        tokenContract: '0x4a5a34212404f30c5ab7eb61b078fa4a55adc5a5',
        symbol: 'MILK2-BNB <br/>Pancake-V2 LP',
        fromToken: 'milk',
        toToken: 'bnb',
        liquidityLink: '/add/BNB/0x4A5a34212404f30C5aB7eB61b078fA4A55AdC5a5',
        tradeLink: 'swap?outputCurrency=0x4a5a34212404f30c5ab7eb61b078fa4a55adc5a5',
        name: 'Honeymoon',
        icon: 'honeymoon',
        hot: true,
      },
      6: {
        id: 6,
        lpContract: '0xeaabd63fcb96c707fe29f37bbff6999d96fdaf12',
        tokenContract: '0x4A5a34212404f30C5aB7eB61b078fA4A55AdC5a5',
        uniContract: '0x1bf2609946b0583eac8c9a3136a24f7ea06c91bc',
        symbol: 'NFTS-MILK2 Pancake-V2 LP',
        name: 'Сreamy',
        icon: 'creamy',
        liquidityLink: '/add/0x08037036451C768465369431Da5C671ad9B37dBc/0x4A5a34212404f30C5aB7eB61b078fA4A55AdC5a5',
        tradeLink: 'swap?outputCurrency=0x4A5a34212404f30C5aB7eB61b078fA4A55AdC5a5',
        hot: true,
        mul: 1000 * 4,
      },
      7: {
        id: 7,
        lpContract: '0xdbf75d38f3a8c8c2a39ba1cebddcb18126285ff9',
        tokenContract: '0x08037036451c768465369431da5c671ad9b37dbc',
        symbol: 'NFTS-BNB Pancake-V2 LP',
        name: 'Toffee sauce',
        icon: 'toffee',

        liquidityLink: '/add/BNB/0x08037036451C768465369431Da5C671ad9B37dBc',
        tradeLink: 'swap?outputCurrency=0x08037036451c768465369431da5c671ad9b37dbc',
        hot: true,
        mul: 500 * 5,
      },
    },
  },
  97: {
    // dummy content, just for test purpose
    // begin
    interstellar: {
      0: {
        id: 0,
        lpContract: '',
        tokenContract: '0xd249acd7b2416279ee812536b409964cba57c364',
        symbol: 'Neque porro quisquam est qui',
        icon: 'strawsion',
        hot: true,
      },
    },
    // end
    // replace it when pools bep20 will be ready
  },
};

/**
 * get shadow ids array
 * @return {Array}
 */
export function getIdsByType(type) {
  if (shadowPools[store.state.User.chainId][type]) {
    return Object.keys(shadowPools[store.state.User.chainId][type]);
  }
  return [];
}

/**
 * get shadow
 * @return {Array}
 */
export function getShadowPools(type) {
  if (shadowPools[store.state.User.chainId][type]) {
    return shadowPools[store.state.User.chainId][type];
  }
  return [];
}
