<template>
  <div class="landing-description main-wrap main-wrap--narrow">
    <div class="landing-container">
      <img
        src="@/assets/img/new-year/shadow-staking.svg"
        class="new-year new-year__shadow-staking"
        alt="new-year"
      >
      <div class="staking_icon"></div>

      <h3 class="landing-title">SHADOW STAKING</h3>

      <span>
        Developed by the SpaceSwap team, Shadow staking minimizes the number of transactions
        within the network and makes the process transparent.
      </span>
    </div>
    <div class="landing-container">
      <img
        src="@/assets/img/new-year/_logo.svg"
        class="new-year new-year__nft-farming"
        alt="new-year"
      >
      <div class="farming_icon"></div>

      <h3 class="landing-title">NFT FARMING</h3>
      <span>
        NFT farming is an emerging concept which involves staking NFTs for a reward,
        or staking tokens for an NFT as a reward. It’s the merger of NFT technology and DeFi.
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandingDescription',
};
</script>

<style scoped lang="scss">
.landing-description {
  display: flex;
  justify-content: space-between;
  margin-top: 7.5rem;

  .landing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 3.4rem;
    flex: 1;

    span {
      text-align: center;
      font-size: 1.8rem;
      margin-top: 1.6rem;
    }

    &:last-child {
      margin-left: 2rem;
    }

    &:first-child {
      margin-left: 2rem;
    }
  }

  .shadow_stacking {
    background-size: contain;
    height: 4.8rem;
    width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  .nft_farming {
    background-size: contain;
    height: 4.8rem;
    width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  .staking_icon {
    width: 10.5rem;
    height: 10.5rem;
    background: url("../../assets/img/landing/staking.svg") no-repeat center;
    background-size: contain;
  }

  .farming_icon {
    width: 10.5rem;
    height: 10.5rem;
    background: url("../../assets/img/landing/farming.svg") no-repeat center;
    background-size: contain;
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .landing-description {
    flex-direction: column;
  }

  .landing-container {
    margin-bottom: 3rem;
    margin-left: 0 !important;
  }
}

.landing-title {
  font-size: 3.2rem;
  margin-bottom: 1rem;
  margin-top: 0.4rem;
}
</style>
