import Vue from 'vue';
import { addNotification, clearNotification } from '@/utils/notification';

const state = {
  loading: false,
  shakePrice: null,
  shakeAllowance: null,
  milkAllowance: null,
};

const actions = {
  update: async (context) => {
    context.commit('loading', true);
    const shakePrice = await Vue.$contracts.getContract('blender').methods.currShakePrice().call();
    const shakeAllowance = await Vue.$contracts.getContract('shake').methods.allowance(
      context.rootState.User.ethAddress,
      Vue.$contracts.getContract('blender').options.address,
    ).call();
    const milkAllowance = await Vue.$contracts.getContract('milk2').methods.allowance(
      context.rootState.User.ethAddress,
      Vue.$contracts.getContract('blender').options.address,
    ).call();
    console.log(123);
    context.commit('setShakePrice', Vue.$contracts.fromWei(shakePrice));
    context.commit('setShakeAllowance', Vue.$contracts.fromWei(shakeAllowance));
    context.commit('setMilkAllowance', Vue.$contracts.fromWei(milkAllowance));
    context.commit('loading', false);
  },
  getShake: async (context) => {
    try {
      let tmpHash = '';
      context.commit('loading', true);
      await Vue.$contracts.getContract('blender').methods.getOneShake()
        .send({ from: context.rootState.User.ethAddress })
        .on('transactionHash', (hash) => {
          tmpHash = hash;
          addNotification({
            title: 'Pending swap MILK2 to SHAKE',
            data: { hash: tmpHash },
          });
        });
      clearNotification('info');
      addNotification({
        type: 'success',
        title: 'Successful swap!',
        data: { hash: tmpHash },
      });
      context.dispatch('update');
    } catch (error) {
      clearNotification('info');
      addNotification({
        type: 'warn',
        title: 'Swap failed!',
      });
      console.error(error);
    } finally {
      context.commit('loading', false);
    }
  },
};

const mutations = {
  loading: (state, status) => {
    state.isLoading = !!status;
  },
  setShakePrice: (state, _shakePrice) => {
    state.shakePrice = _shakePrice;
  },
  setShakeAllowance: (state, _shakeAllowance) => {
    state.shakeAllowance = _shakeAllowance;
  },
  setMilkAllowance: (state, _milkAllowance) => {
    state.milkAllowance = _milkAllowance;
  },
};

export default {
  state,
  actions,
  mutations,
};
