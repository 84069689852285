import { getSign } from '@/helpers/api/shadow.api';
import Vue from 'vue';

const state = {
  loading: false,
  ethAddress: null,
  chainId: null,
  sign: null,
  lastBlock: 0,
  chainName: 'ethereum',
};

const getters = {
  chainName: (s) => s.chainName,
  chainId: (s) => s.chainId,
  ethAddress: (s) => s.ethAddress,
};

const actions = {
  async initPools({ commit, dispatch }) {
    commit('SET_LOADING_STATUS', true);
    if (window.ethereum) {
      try {
        const promises = [
          dispatch('getSign'),
          // dispatch('getLastBlock'),
          dispatch('Price/update', {}, { root: true }),
          dispatch('Milk/update', {}, { root: true }),
          dispatch('Shake/update', {}, { root: true }),
        ];
        await Promise.all(promises);
      } catch (error) {
        console.error(error);
      }
    }
    commit('SET_LOADING_STATUS', false);
  },
  async getSign({ commit, state }) {
    let sign;
    try {
      sign = await getSign(state.ethAddress.toLowerCase());
    } catch (e) {
      if (e?.response?.status !== 400) {
        console.error(e);
        sign = null;
      } else {
        sign = 'notregistered';
      }
    }
    commit('SET_SIGN', sign);
  },
  async getLastBlock({ commit }) {
    let lastBlock;
    try {
      const shadowContract = Vue.$contracts.getInstance(
        Vue.$contracts.getAddress('shadow'),
        'shadow',
      );
      lastBlock = await shadowContract.methods.getLastBlock(state.ethAddress);
    } catch (e) {
      console.error(e);
      lastBlock = 0;
    }
    commit('SET_LAST_BLOCK', lastBlock);
  },
};

const mutations = {
  SET_SIGN(state, sign) {
    state.sign = sign;
  },
  SET_CHAIN_ID_AND_NAME(state, chainId) {
    state.chainId = chainId;
    switch (chainId) {
      case 1:
      case 4:
        state.chainName = 'ethereum';
        break;
      case 56:
      case 97:
        state.chainName = 'binance';
        break;
      case 128:
      case 256:
        state.chainName = 'huobi';
        break;
      case 43113:
      case 43114:
        state.chainName = 'avalanche';
        break;
      default:
        state.chainName = 'ethereum';
    }
  },
  SET_ETH_ADDRESS(state, _address) {
    state.ethAddress = _address;
  },
  SET_AUTH_STATUS(state, _auth) {
    state.authenticated = !!_auth;
  },
  SET_LOADING_STATUS(state, status) {
    state.loading = status;
  },
  SET_LAST_BLOCK(state, block) {
    state.lastBlock = block;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
