const bnbTestnet = {
  milk2: '0x1feeb9166a5d24100cc4551e71ab990fa47974c2',
  shake: '0x6c1799e24c5c4c649a32a8d6426722976bca867f',
  // spaceSwap LP this addresses from mainnet
  weth: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
  milk2Lp: '0x828C88f841dBEEB7aE293fD2cD8c1B9AeD0cb16c',
  shakelp: '0x828C88f841dBEEB7aE293fD2cD8c1B9AeD0cb16c',
  //
  // compound
  comptroller: '0xebf2ce2e9b6fe547d22ab887fd2a5a74be9f6047',
  priceOracle: '0x04b277e3f8de4313ffed8c0b0a6911e587465a96',
  // launchpad
  launchpadStaking: '0x2c89c836443b5916DC38a77549bfd9909Aca29AC',
  launchpadStakingShake: '0x57F830F16e41E066847d155333dFF7F41D50d1BF',
  launchpadSaleOld: '0x90b2a752351071E2021487B31770bEd0b79509BB',
  launchpadSale: '0x7164a9A6dE12d06b1a0fe20c4f1C83e4B9d76675',
  launchpadWhiteList: '0x16dB4A4149A8fdC9256bC8e0DFD27A344Bb4bD0f',
  launchpadVesting: '0xead1e3255F6903ABDDFC3A55A1CAA0BdA44C36Cb',
  DAI: '0x828C88f841dBEEB7aE293fD2cD8c1B9AeD0cb16c',
  launching: '0x399E64578466739232886D19c6E90C8B725b37Ed',
  BUSD: '0x214BEd62048F5736b1A05d53d95cCde84145a7d1',
  sSIDUS: '0x580e1B5a1aade156DC4CdC8b8C45fb0A005c3516',
  sSENATE: '0xd91fdB098a2813d2a517Bb92C0Fb484c36edF075',
  sidusPresale: '0x1160bd224AC3D22dd591C85ea54fC71f36747888',
  sLGX: '0x4D5AA804Da024Fb4635af951955648274FeA823A',
  shadowBridge: '0x66Ed4E2e56AF8010CcF4A5C7E464948E8375DB3c',
};

export default bnbTestnet;
