<script>
import ModelViewer from '@metamask/logo';

/* eslint-disable */
export default {
  name: 'MetaFox',
  mounted() {
    // To render with fixed dimensions:
  const viewer = ModelViewer({
    // Dictates whether width & height are px or multiplied
    pxNotRatio: true,
    width: 100,
    height: 100,
    // pxNotRatio: false,
    // width: 0.9,
    // height: 0.9,

    // To make the face follow the mouse.
    followMouse: false,

    // head should slowly drift (overrides lookAt)
    slowDrift: false,
  });

  // add viewer to DOM
  const container = document.getElementById('meta-fox');
  container.appendChild(viewer.container);

  // look at something on the page
  viewer.lookAt({
    x: 100,
    y: 100,
  });

  // enable mouse follow
  viewer.setFollowMouse(true);

  // deallocate nicely
  viewer.stopAnimation();
  }
}
</script>

<template>
  <div id="meta-fox"></div>
</template>
