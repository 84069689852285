<template>
  <header class="solana-header d-flex justify-space-between align-center">
<!--    <router-link to="/cardano" class="cardano-logo">-->
<!--      <img src="@/assets/img/cardano/logo.svg" alt="Marnotaur">-->
<!--    </router-link>-->
    <div class="solana-logo d-flex justify-center align-center">
      LOGO
    </div>

    <ul class="solana-nav">
      <li v-for="(link, i) of menu" :key="i">
        <router-link :to="link.path" :class="{active: route_name === link.name}">
          {{ $t(link.label) }}
        </router-link>
      </li>
    </ul>

    <s-wallet/>
  </header>
</template>

<script>
import SWallet from '@/components/Solana/SWallet';

export default {
  name: 'CardanoHeader',

  components: { SWallet },

  data() {
    return {
      menu: [
        {
          label: 'solana.all_pools',
          name: 'cardano',
          path: '/cardano',
        },
        {
          label: 'solana.all_rewards',
          name: 'all_rewards',
          path: '#',
        },
        {
          label: 'solana.create_pool',
          name: 'create_pool',
          path: '#',
        },
      ],
    };
  },

  computed: {
    route_name() {
      return this.$route.name;
    },
  },
};
</script>

<style scoped lang="scss">
.cardano-logo{
  img{
    height: 3.8rem;
  }
}
</style>
