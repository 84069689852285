/* eslint-disable prefer-const */
import Vue from 'vue';
import { spaceCompTokens } from '@/constants/spaceComp';
import { addNotification, clearNotification } from '@/utils/notification';
import i18n from '@/i18n';
import BN from 'bignumber.js';

const calculateTokenRate = (tokenRateWei) => Vue.prototype.$BN(tokenRateWei)
  .multipliedBy(5760)
  .div(1e18)
  .plus(1)
  .exponentiatedBy(365)
  .minus(1)
  .multipliedBy(100)
  .toFixed(2);

const calculateValue = (params, action) => {
  if (params.symbol === 'cUSDT') {
    return Number(params.value / 1e12).toString().replace(/\./g, '');
  }
  // eslint-disable-next-line no-mixed-operators
  if (params.symbol === 'sBNB' && action === 'supply' || action === 'withdraw') {
    return params.value.toString();
  }
  return params.value.toString();
};

const actionPipeline = ({ commit, dispatch }, fn, action) => fn.once('transactionHash', (hash) => {
  commit('modalHandler');
  commit('setTxModal', {
    show: true,
    hash,
  });
}).once('confirmation', () => {
  addNotification({
    type: 'success',
    title: i18n.t(`notifications.success_${action}`),
    config: { duration: 5000 },
  });
})
  .once('error', () => {
    addNotification({
      type: 'warn',
      title: i18n.t(`notifications.failed_${action}`),
      config: { duration: 5000 },
    });
  })
  .then((receipt) => {
    clearNotification('info');
    commit('setTxModal', {
      show: true,
      hash: '',
      receipt,
    });
  })
  .finally(() => {
    dispatch('init');
    clearNotification('info');
    commit('setLoading', false);
    commit('modalHandler');
  });

/**
 * Pools state
 * @type {{pools: { 'poolType': { 'poolAddress': { ...poolParams } } }, loading: boolean}}
 */
const state = {
  loading: false,
  vaults: {},
  totalSupply: 0,
  totalBorrowed: 0,
  compPrices: {
    sMILK: 0,
    sSHAKE: 0,
  },
  txModal: {
    show: false,
    hash: null,
    receipt: null,
  },
  accountLiquidity: 0,
};

const getters = {
  vaults: (state) => state.vaults,
  sortedVaults: (state) => {
    const sortedVaults = {
      assetIn: {},
      assetOut: {},
      borrowed: {},
      notBorrowed: {},
    };
    Object.entries(state.vaults).forEach(([key, value]) => {
      if (value.currentDeposit.isGreaterThan(0)) {
        sortedVaults.assetIn[key] = value;
      } else {
        sortedVaults.assetOut[key] = value;
      }
      if (Vue.prototype.$BN(value.currentBorrowBalance).eq(0)) {
        sortedVaults.notBorrowed[key] = value;
      } else {
        sortedVaults.borrowed[key] = value;
      }
    });
    return sortedVaults;
  },
  netAPY: (_, rootGetters) => {
    const { borrowed, assetIn: supply } = rootGetters.sortedVaults;
    const totalSupply = Object.values(supply).reduce((sum, item) => sum + ((
      Vue.prototype.$contracts.fromWei(Number(item.currentDepositWei))
      * Vue.prototype.$contracts.fromWei(Number(item.cTokenPriceWei)))), 0);
    const supplyPart = Object.values(supply).reduce((sum, item) => (
      sum + ((Vue.prototype.$contracts.fromWei(Number(item.currentDepositWei))
        * Vue.prototype.$contracts.fromWei(Number(item.cTokenPriceWei)))
        * (Number(item.tokenSupplyRate)))),
    0);

    const borrowedPart = Object.values(borrowed).reduce((sum, item) => (
      sum + (Vue.prototype.$contracts.fromWei(Number(item.currentBorrowBalance))
        * Vue.prototype.$contracts.fromWei(item.cTokenPriceWei))
      * (Number(item.tokenBorrowRate))), 0);

    return Math.abs((((supplyPart - borrowedPart) / totalSupply)));
  },
  loading: (state) => state.loading,
  totalSupply: (_, rootGetters) => {
    const { assetIn: supply } = rootGetters.sortedVaults;
    const totalSupply = Object.values(supply).reduce((sum, item) => {
      let itemPrice = ((
        Vue.prototype.$contracts.fromWei(Number(item.currentDepositWei))
        * Vue.prototype.$contracts.fromWei(Number(item.cTokenPriceWei))));
      if (item.underlyingSymbol === 'USDT') {
        itemPrice = ((
          Vue.prototype.$contracts.fromWei(Number(item.currentDepositWei), item.decimals)
          * Vue.prototype.$contracts.fromWei(Number(item.cTokenPriceWei), 30)));
      }
      return sum + itemPrice;
    }, 0);

    return new BN(totalSupply);
  },
  totalBorrowed: (_, rootGetters) => {
    const { borrowed } = rootGetters.sortedVaults;
    const totalBorrowed = Object.values(borrowed).reduce((sum, item) => {
      if (item.underlyingSymbol === 'USDT') {
        return sum + item.currentBorrowBalance
          * Vue.prototype.$contracts.fromWei(item.cTokenPriceWei, 30);
      }
      return (
        sum
        + item.currentBorrowBalance
        * Vue.prototype.$contracts.fromWei(item.cTokenPriceWei, item.underlyingDecimals)
      );
    }, 0);
    return new BN(totalBorrowed);
  },
  txModal: (state) => state.txModal,
  accountLiquidity: (state) => state.accountLiquidity,
};

const actions = {
  async init({ rootState, dispatch }) {
    const assetsIn = await Vue.prototype.$compound.getAssetsIn();
    Object.entries(spaceCompTokens[rootState.User.chainId]).map(async ([vaultAddress, vault]) => {
      dispatch('getVaultParams', { vaultAddress, vault, assetsIn });
    });
  },
  async getVaultParams({ commit, rootGetters }, { vaultAddress, vault, assetsIn }) {
    const assetIn = assetsIn.includes(Vue.prototype.$web3.utils.toChecksumAddress(vault.address));
    const requests = [
      Vue.prototype.$compound.getLiquidity(),
      Vue.prototype.$erc20.getTokenBalance(vault.underlyingAddress),
      Vue.prototype.$compound.getBorrowBalance(vault.address, vault.symbol),
      Vue.prototype.$compound.getTokenPrice(vault.address),
      Vue.prototype.$compound.getCTokenTotalSupply(vault.address, vault.symbol),
      Vue.prototype.$compound.getTokenBorrowRate(vault.address, vault.symbol, 'borrow'),
      Vue.prototype.$compound.getTokenBorrowRate(vault.address, vault.symbol, 'supply'),
      Vue.prototype.$compound.getCTokenMarket(vault.address),
      Vue.prototype.$compound.getAccountLiquidity(),
      Vue.prototype.$compound.getCTokenBalanceOf(vault.address, vault.symbol),
    ];
    let [
      { 1: liquidity },
      underlyingBalanceWei,
      borrowBalance,
      cTokenPriceWei,
      cTokenTotalSupplyWei,
      cTokenBorrowRateWei,
      cTokenSupplyRateWei,
      { 1: collateralFactorMantissa },
      { 1: accountLiquidity },
      cTokenBalanceOf,
    ] = await Promise.all(requests);
    if (vault.symbol === 'sMILK' || vault.symbol === 'sSHAKE') {
      commit('setCompPrices', { price: cTokenPriceWei, symbol: vault.symbol });
    }
    const contract = Vue.prototype.$compound.createCTokenContract(vault.address, vault.symbol);

    let underlyingBalance = Vue.prototype.$contracts
      .fromWei(underlyingBalanceWei, vault.underlyingDecimals);
    if (vault.underlyingSymbol === 'USDT') {
      underlyingBalance = Vue.prototype.$contracts
        .fromWei(underlyingBalanceWei, vault.decimals);
    }
    const collateral = Vue.prototype.$contracts.fromWei(collateralFactorMantissa);
    const maxWithdrawInUSD = Vue.prototype.$contracts.fromWei(
      Vue.prototype.$BN(liquidity).div(collateral),
    );

    let totalReserves = Vue.prototype.$contracts.fromWei(
      await Vue.prototype.$compound.getCash(contract),
    );

    if (vault.underlyingSymbol === 'USDT') {
      totalReserves = Vue.prototype.$contracts.fromWei(
        await Vue.prototype.$compound.getCash(contract), 6,
      );
    }

    let exchangeRate = await contract.methods.exchangeRateCurrent().call();

    if (vault.underlyingSymbol === 'BNB') {
      exchangeRate = new BN(exchangeRate).div(1e28);
    } else {
      exchangeRate = new BN(exchangeRate).div(1e18);
    }

    const tokenBorrowRate = calculateTokenRate(cTokenBorrowRateWei);
    const tokenSupplyRate = calculateTokenRate(cTokenSupplyRateWei);
    const currentDepositWei = await Vue.prototype.$compound.getBalanceOfUnderlying(contract);

    let currentDeposit = Vue.prototype.$contracts.fromWei(
      currentDepositWei,
    );

    if (vault.underlyingSymbol === 'USDT') {
      currentDeposit = Vue.prototype.$contracts
        .fromWei(currentDepositWei, vault.decimals);
    }

    let currentDepositUSDT = currentDeposit.multipliedBy(
      Vue.$contracts.fromWei(cTokenPriceWei),
    );

    if (vault.underlyingSymbol === 'USDT') {
      currentDepositUSDT = currentDeposit.multipliedBy(
        Vue.$contracts.fromWei(cTokenPriceWei, 30),
      );
    }

    let cTokenBalance = new BN(cTokenBalanceOf);

    if (vault.underlyingSymbol === 'BNB') {
      cTokenBalance.div(1e8);
    } else {
      cTokenBalance.div(1e18);
    }

    accountLiquidity = new BN(accountLiquidity).div(1e18);

    let availableWithdraw = currentDeposit;

    if (availableWithdraw.isGreaterThanOrEqualTo(totalReserves)) {
      availableWithdraw = totalReserves;
    } else if (Object.entries(rootGetters['Comp/sortedVaults'].borrowed).length
      && availableWithdraw.isGreaterThanOrEqualTo(accountLiquidity)) {
      availableWithdraw = accountLiquidity;
    }

    let tokenPrice = new BN(cTokenPriceWei).div(1e18);
    if (vault.underlyingSymbol === 'USDT') {
      tokenPrice = new BN(cTokenPriceWei).div(1e30);
    }

    let availableBorrow = new BN(accountLiquidity).div(tokenPrice);
    // eslint-disable-next-line no-unused-vars
    // eslint-disable-next-line no-shadow
    if (availableBorrow.isGreaterThanOrEqualTo(totalReserves)) {
      availableBorrow = totalReserves;
    }

    let currentBorrowBalance = Vue.prototype.$contracts.fromWei(
      borrowBalance,
    );
    if (vault.underlyingSymbol === 'USDT') {
      currentBorrowBalance = Vue.prototype.$contracts
        .fromWei(borrowBalance, vault.decimals);
    } else if (vault.underlyingSymbol === 'BNB') {
      currentBorrowBalance = Vue.prototype.$contracts
        .fromWei(borrowBalance, vault.underlyingDecimals);
    }

    let avaibleRepay = currentBorrowBalance;

    if (avaibleRepay.isGreaterThanOrEqualTo(underlyingBalance)) {
      avaibleRepay = underlyingBalance;
    }

    const vaultWithParams = {
      ...vault,
      underlyingBalanceWei,
      underlyingBalance,
      assetIn,
      liquidity,
      currentBorrowBalance,
      cTokenPriceWei,
      cTokenTotalSupplyWei,
      tokenBorrowRate,
      tokenSupplyRate,
      availableBorrow,
      APRValue: tokenBorrowRate,
      currentDepositWei,
      currentDeposit,
      maxWithdrawInUSD,
      totalReserves,
      availableWithdraw,
      avaibleRepay,
      accountLiquidity,
      exchangeRate,
      cTokenBalance,
      currentDepositUSDT,
      collateral,
      tokenPrice,
    };
    commit('setTotalSupply', liquidity);
    commit('setAccointLiquidity', accountLiquidity);
    commit('SET_VAULT', { vaultAddress, vaultWithParams });
  },
  async suplly({ rootState, commit, dispatch }, params) {
    commit('setLoading', true);
    clearNotification('info');

    const { underlyingAddress } = spaceCompTokens[rootState.User.chainId][params.address];
    const contract = Vue.prototype.$compound.createCTokenContract(params.address, params.symbol);
    const gasPrice = await Vue.prototype.$web3.eth.getGasPrice();

    let mintPromise;

    const value = calculateValue(params, 'supply');

    if (underlyingAddress !== '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee') {
      const gas = await contract
        .methods
        .mint(value)
        .estimateGas({ from: rootState.User.ethAddress });

      mintPromise = contract
        .methods
        .mint(value)
        .send({
          gas,
          gasPrice,
          from: rootState.User.ethAddress,
        });
    } else {
      const gas = await contract
        .methods
        .mint()
        .estimateGas({ value, from: rootState.User.ethAddress });
      mintPromise = contract
        .methods
        .mint()
        .send({
          gas,
          gasPrice,
          value,
          from: rootState.User.ethAddress,
        });
    }

    return actionPipeline({ commit, dispatch }, mintPromise, 'supply');
  },
  async borrow({ rootState, commit, dispatch }, params) {
    commit('setLoading', true);
    clearNotification('info');

    const value = calculateValue(params);

    const contract = Vue.prototype.$compound.createCTokenContract(params.address, params.symbol);
    const gasPrice = await Vue.prototype.$web3.eth.getGasPrice();
    const gas = await contract
      .methods
      .borrow(value)
      .estimateGas({
        from: rootState.User.ethAddress,
      });

    const borrowPromise = contract
      .methods
      .borrow(value)
      .send({
        from: rootState.User.ethAddress,
        gas,
        gasPrice,
      });

    return actionPipeline({ commit, dispatch }, borrowPromise, 'borrow');
  },
  async repay({ rootState, commit, dispatch }, params) {
    commit('setLoading', true);
    clearNotification('info');

    const value = calculateValue(params);
    const contract = Vue.prototype.$compound.createCTokenContract(params.address, params.symbol);
    const transObj = {};
    const transParams = [];
    if (params.address === '0x3550b03837ac3e86c5c03baf925d73642cfdb50d' || params.address === '0x727f751f9884c0113d24925acf8dab15de0ee98e') {
      transObj.value = params.value.toString();
    } else {
      transParams.push(value);
    }
    transObj.from = rootState.User.ethAddress;
    const gas = await contract
      .methods
      .repayBorrow(...transParams)
      .estimateGas(transObj);
    transObj.gasPrice = await Vue.prototype.$web3.eth.getGasPrice();
    transObj.gas = gas;

    const repayPromise = contract
      .methods
      .repayBorrow(...transParams)
      .send(transObj).once('transactionHash', (hash) => {
        commit('modalHandler');
        commit('setTxModal', {
          show: true,
          hash,
        });
      });

    return actionPipeline({ commit, dispatch }, repayPromise, 'repay');
  },
  async repayFull({ rootState, commit, dispatch }, params) {
    commit('setLoading', true);
    clearNotification('info');

    const value = '115792089237316195423570985008687907853269984665640564039457584007913129639935';

    const contract = Vue.prototype.$compound.createCTokenContract(params.address, params.symbol);
    const transObj = {};

    transObj.repayAmount = value;
    transObj.borrower = rootState.User.ethAddress;

    transObj.from = rootState.User.ethAddress;
    const gas = await contract
      .methods
      .repayBorrowBehalf(rootState.User.ethAddress, value)
      .estimateGas(transObj);
    transObj.gasPrice = await Vue.prototype.$web3.eth.getGasPrice();
    transObj.gas = gas;

    const repayFullPromise = contract
      .methods
      .repayBorrowBehalf(rootState.User.ethAddress, value)
      .send(transObj).once('transactionHash', (hash) => {
        commit('modalHandler');
        commit('setTxModal', {
          show: true,
          hash,
        });
      });

    return actionPipeline({ commit, dispatch }, repayFullPromise, 'repay');
  },
  async withdraw({ rootState, commit, dispatch }, params) {
    commit('setLoading', true);
    clearNotification('info');

    let value;
    if (params.withdrawType === 'redeemUnderlying') {
      value = calculateValue(params, 'withdraw');
    } else {
      value = params.value.toString();
    }

    const contract = Vue.prototype.$compound.createCTokenContract(params.address, params.symbol);
    const gasPrice = await Vue.prototype.$web3.eth.getGasPrice();
    const gas = await contract
      .methods[params.withdrawType](value)
      .estimateGas({
        from: rootState.User.ethAddress,
      });

    const withdrawPromise = contract
      .methods[params.withdrawType](value)
      .send({
        gas,
        gasPrice,
        from: rootState.User.ethAddress,
      });

    return actionPipeline({ commit, dispatch }, withdrawPromise, 'withdraw');
  },
  async enterMarket({ rootState, commit, dispatch }, cTokens) {
    clearNotification('info');
    const contract = Vue.prototype.$compound.getComptrollerContract();
    const gasPrice = await Vue.prototype.$web3.eth.getGasPrice();
    const gas = await contract
      .methods
      .enterMarkets(cTokens)
      .estimateGas({ from: rootState.User.ethAddress });

    const enterMarketPromise = contract
      .methods
      .enterMarkets(cTokens)
      .send({
        gas,
        gasPrice,
        from: rootState.User.ethAddress,
      });

    return actionPipeline({ commit, dispatch }, enterMarketPromise, 'enter');
  },
  async exitMarket({ rootState, commit, dispatch }, cToken) {
    clearNotification('info');
    const contract = Vue.prototype.$compound.getComptrollerContract();
    const gasPrice = await Vue.prototype.$web3.eth.getGasPrice();
    const gas = await contract
      .methods
      .exitMarket(cToken)
      .estimateGas({ from: rootState.User.ethAddress });

    const exitMarketPromise = contract
      .methods
      .exitMarket(cToken)
      .send({
        gas,
        gasPrice,
        from: rootState.User.ethAddress,
      }).once('transactionHash', (hash) => {
        commit('setTxModal', {
          show: true,
          hash,
        });
      });

    return actionPipeline({ commit, dispatch }, exitMarketPromise, 'exit');
  },
};

const mutations = {
  SET_VAULT(state, { vaultAddress, vaultWithParams }) {
    Vue.set(state.vaults, vaultAddress, Object.freeze(vaultWithParams));
  },
  setLoading: (state, loading) => {
    state.loading = loading;
  },
  modalHandler: () => {
  },
  setTotalSupply: (state, totalSupply) => {
    state.totalSupply = (totalSupply / 1e18).toFixed(2);
  },
  setCompPrices: (state, { price, symbol }) => {
    state.compPrices[symbol] = price / 1e18;
  },
  resetVaults: (state) => {
    state.vaults = {};
  },
  setTxModal: (state, payload) => {
    state.txModal = payload;
  },
  resetTxModal: (state) => {
    state.txModal = {
      show: false,
    };
  },
  setAccointLiquidity: (state, liquidity) => {
    state.accountLiquidity = liquidity;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
