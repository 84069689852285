<template>
  <div class="landing">
    <LandingHeader/>
    <LandingVideo/>
    <ActiveChains/>
    <LandingDescription/>
    <CoreFeatures/>
    <MilkContainer/>
    <Roadmap/>
    <!-- <Team/> -->
    <div class="landing__divider"></div>
    <img
      src="@/assets/img/new-year/landing-footer.svg"
      class="new-year new-year__landing-footer"
      alt="new-year"
    >
  </div>
</template>

<script>
// import Team from '@/views/team';
import LandingHeader from '../components/Landing/LandingHeader';
import LandingVideo from '../components/Landing/LandingVideo';
import ActiveChains from '../components/Landing/ActiveChains';
import LandingDescription from '../components/Landing/LandingDescription';
import Roadmap from '../components/Landing/Roadmap';
import MilkContainer from '../components/Landing/MilkContainer';
import CoreFeatures from '../components/Landing/CoreFeatures';

export default {
  name: 'landing',
  components: {
    CoreFeatures,
    MilkContainer,
    Roadmap,
    LandingDescription,
    ActiveChains,
    LandingHeader,
    // Team,
    LandingVideo,
  },
};
</script>

<style lang="scss">
.landing {
  background: url("../assets/img/landing/bg.svg") no-repeat;
  background-position: 0 -20rem;
  background-size: cover;
  background-color: #F5F5F7 !important;
  overflow: hidden;

  &__divider {
    margin-bottom: 30rem;
  }
}

.landing-container {
  padding: 5.8rem 3.7rem;
  background: #FFFFFF !important;
  box-shadow: 5px 5px 10px rgba(255, 255, 255, 0.9),
  -1px -1px 5px rgba(182, 169, 173, 0.3),
  inset -2px -2px 2px rgba(182, 169, 173, 0.2),
  inset 1px 1px 4px rgba(182, 169, 173, 0.2),
  inset -2px -2px 4px rgba(255, 255, 255, 0.9),
  inset 1px 1px 2px rgba(182, 169, 173, 0.2) !important;
  border-radius: 3rem !important;
}

.landing-title {
  font-weight: 900;
  font-size: 4.6rem;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #FF8B8B;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.11));
  text-shadow:0px 1px 0 rgb(255,255,255),0px -1px 0 rgb(255,255,255),1px 0px 0 rgb(255,255,255),
  -1px 0px 0 rgb(255,255,255),1px 1px 0 rgb(255,255,255),1px -1px 0 rgb(255,255,255),
  -1px 1px 0 rgb(255,255,255),-1px -1px 0 rgb(255,255,255),0px 2px 0 rgb(255,255,255),
  0px -2px 0 rgb(255,255,255),2px 0px 0 rgb(255,255,255),-2px 0px 0 rgb(255,255,255),
  2px 2px 0 rgb(255,255,255),2px -2px 0 rgb(255,255,255),-2px 2px 0 rgb(255,255,255),
  -2px -2px 0 rgb(255,255,255),0px 3px 0 rgb(255,255,255),0px -3px 0 rgb(255,255,255),
  3px 0px 0 rgb(255,255,255),-3px 0px 0 rgb(255,255,255),3px 3px 0 rgb(255,255,255),
  3px -3px 0 rgb(255,255,255),-3px 3px 0 rgb(255,255,255),-3px -3px 0 rgb(255,255,255),
  0px 4px 0 rgb(255,255,255),0px -4px 0 rgb(255,255,255),4px 0px 0 rgb(255,255,255),
  -4px 0px 0 rgb(255,255,255),4px 4px 0 rgb(255,255,255),4px -4px 0 rgb(255,255,255),
  -4px 4px 0 rgb(255,255,255),-4px -4px 0 rgb(255,255,255);
}

@media screen and (max-width: $breakpoint-sm) {
  .landing {
    background: url("../assets/img/landing/bg.svg") no-repeat;
    background-position: 0 -60rem;
    background-size: cover;
    background-color: #F5F5F7 !important;
  }

  .hide-on-mobile {
    display: none !important;
  }
}

</style>
