const chains = [
  {
    name: 'Ethereum Mainnet',
    shortName: 'ETH',
    rpc: [],
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    chainId: 1,
    networkId: 1,
    explorer: 'https://etherscan.io',
  },
  {
    name: 'Ethereum Testnet Ropsten',
    shortName: 'Ropsten',
    rpc: [],
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    chainId: 3,
    networkId: 3,
    explorer: 'https://ropsten.etherscan.io',
  },
  {
    name: 'Ethereum Testnet Rinkeby',
    shortName: 'Rinkeby',
    rpc: [],
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    chainId: 4,
    networkId: 4,
    explorer: 'https://rinkeby.etherscan.io',
  },
  {
    name: 'Avalanche Fuji Testnet',
    shortName: 'AVAX Testnet',
    rpc: ['https://api.avax-test.network/ext/bc/C/rpc'],
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18,
    },
    chainId: 43113,
    networkId: 1,
    explorer: 'https://testnet.snowtrace.io',
  },
  {
    name: 'Avalanche Mainnet',
    shortName: 'Avalanche',
    rpc: ['https://api.avax.network/ext/bc/C/rpc'],
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18,
    },
    chainId: 43114,
    networkId: 43114,
    explorer: 'https://snowtrace.io',
  },
  {
    name: 'Binance Smart Chain Mainnet',
    shortName: 'BSC',
    rpc: [
      'https://bsc-dataseed1.binance.org',
      'https://bsc-dataseed2.binance.org',
      'https://bsc-dataseed3.binance.org',
    ],
    nativeCurrency: {
      name: 'Binance Chain Native Token',
      symbol: 'BNB',
      decimals: 18,
    },
    chainId: 56,
    networkId: 56,
    explorer: 'https://bscscan.com',
  },
  {
    name: 'Binance Smart Chain Testnet',
    shortName: 'BSC Testnet',
    rpc: [
      'https://data-seed-prebsc-1-s1.binance.org:8545',
      'https://data-seed-prebsc-2-s1.binance.org:8545',
      'https://data-seed-prebsc-1-s2.binance.org:8545',
    ],
    nativeCurrency: {
      name: 'Binance Chain Native Token',
      symbol: 'BNB',
      decimals: 18,
    },
    chainId: 97,
    networkId: 97,
    explorer: 'https://testnet.bscscan.com',
  },
];

export default chains;
export const getChain = (id) => chains.find((el) => el.chainId === id);
