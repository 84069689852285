import BigNumber from 'bignumber.js';
import Vue from 'vue';

const tokens = {};

export default async (token, address) => {
  if (!tokens[token]) {
    try {
      const tokenContract = Vue.$contracts.getInstance(token, 'token');
      // const price = await getPrice(token, lpToken, weth);
      const totalSupply = await tokenContract.methods.totalSupply().call();
      const balance = await tokenContract.methods.balanceOf(address).call();
      const decimals = await tokenContract.methods.decimals().call();
      tokens[token] = {
        address: token,
        balance: Vue.$contracts.fromWei(balance),
        decimals,
        // price: new BigNumber(price),
        totalSupply: new BigNumber(totalSupply),
      };
    } catch (e) {
      console.error('Error on update token', e);
    }
  }

  return tokens[token];
};
