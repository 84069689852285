import user from '../store/modules/user.store';
import { getChain } from '../constants/chains';

const chain = {
  async install(Vue) {
    /**
     * Get network name
     * @returns {String} — network name
     */
    const getName = () => {
      switch (user.state.chainId) {
        case 1:
        case 3:
        case 4:
          return 'ethereum';
        case 56:
        case 97:
          return 'binance';
        case 128:
        case 256:
          return 'huobi';
        case 43113:
        case 43114:
          return 'avalanche';
        default:
          return 'ethereum';
      }
    };

    /**
     * Return true if selected network name matches with passed name
     * @param name {String} — network name
     * @returns {Boolean} — boolean statement
     */
    const is = (name) => {
      if (getName() === name) {
        return true;
      }
      return false;
    };

    /**
     * Return corresponding link by selected network
     * @param ethereum {String} — link for ethereum network
     * @param binance {String} — link for binance network
     * @param huobi {String} — link for huobi network
     * @returns {String} — return corresponding link
     */
    const getLinkByNetwork = (ethereum, binance, huobi, avalanche) => {
      switch (getName()) {
        case 'ethereum':
          return ethereum;
        case 'binance':
          return binance;
        case 'huobi':
          return huobi;
        case 'avalanche':
          return avalanche;
        default:
          return 'ethereum';
      }
    };

    const testnetWarning = () => {
      switch (user.state.chainId) {
        case 4:
          return {
            isTest: true,
            message: 'rinkeby-notice',
          };
        case 97:
          return {
            isTest: true,
            message: 'bsc-testnet-notice',
          };
        default:
          return '';
      }
    };

    const getExplorerLink = (tx) => {
      switch (user.state.chainId) {
        case 1:
          return `https://etherscan.io/tx/${tx}`;
        case 4:
          return `https://rinkeby.etherscan.io/tx/${tx}`;
        case 56:
          return `https://bscscan.com/tx/${tx}`;
        case 97:
          return `https://testnet.bscscan.com/tx/${tx}`;
        default:
          return `https://etherscan.io/tx/${tx}`;
      }
    };

    const switchChain = async (id) => {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: Vue.$web3.utils.toHex(id) }],
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            const selectedChain = getChain(id);
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [{
                chainId: Vue.$web3.utils.toHex(id),
                rpcUrls: selectedChain.rpc,
                chainName: selectedChain.name,
                nativeCurrency: selectedChain.nativeCurrency,
                blockExplorerUrls: [selectedChain.explorer],
              }],
            });
          } catch (addError) {
            console.log(addError);
          }
        }
      }
    };

    Vue.$chain = {
      testnetWarning,
      getLinkByNetwork,
      getName,
      is,
      getExplorerLink,
      switchChain,
    };
    Vue.prototype.$chain = Vue.$chain;
  },
};

export default chain;
